<h3 class="page-title">{{"cc.processes.process-overview" | translate}}</h3>

<lib-mc-loader [showLoader]="mcProcessList.apiLoadingFlg"></lib-mc-loader>

<div class="col-md-12">
  <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>
</div>

<app-shared-filter-wrapper>
  <div class="filter-container">
    <div class="filter-inputs">

      <!-- Search-->
      <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" (eventEnterClicked)="actLoad()"
                      tooltipClass="mc-tooltip-length" placeholder="Search" placement="bottom-left" name="txbSearchTerm"
                      [mcForm]="mcForm">
      </mcc-fi-textbox>

    </div>
    <div class="filter-actions">
      <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
      <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>
</app-shared-filter-wrapper>

<div class="actions-container">
  <span>{{"cc.processes.process-list" | translate}}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcProcessAddPermission()" (click)="actCreateProcess()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{"cc.processes.create-process" | translate}}
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="mcProcessList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="mcProcessList"
                    [table3Config]="mcProcessTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
  </div>

  <mcc-modal1-wrapper [modalSizeCd]="'small'" *ngIf="showAddEditProcessMcb.value" [showMcb]="showAddEditProcessMcb">
    <mc-process-process-add-edit-part (eventCanceled)="actCloseAddEditProcess()"
                                      (eventSaved)="actAddEditProcessSaved()"></mc-process-process-add-edit-part>
  </mcc-modal1-wrapper>

</div>

<router-outlet></router-outlet>

