import {Component, OnInit, ViewChild} from '@angular/core';
import {
  EbFactoringList,
  EbFactoringMyFactoringTable3ConfigModel,
  EbFactoringFilter,
  McGod,
  McEntity2List,
  McHtmlButton, McBoolean, EbFactoring, McValueLabelList, EbSepaFileTransaction, EbSepaFileTransactionFilter
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';
import {Subject} from "rxjs";
import {MccMultiselectFilterComponent} from '@miticon-ui/mc-components';

const enum DialogAction {
  ACT_CANCEL = "btn-secondary",
  ACT_ACCEPT = "btn-primary"
}

@Component({
  selector: 'mc-my-factoring-contract-list-page',
  templateUrl: './mc-my-factoring-contract-list-page.component.html',
})
export class McMyFactoringContractListPageComponent implements OnInit {

  mcForm = new McForm();
  ebFactoringList!: EbFactoringList;
  ebFactoringTable3Config = new  EbFactoringMyFactoringTable3ConfigModel();
  loggedEntityId!: number;
  ebFactoringFilter = new EbFactoringFilter();
  ebSepaFileTransactionFilter = new EbSepaFileTransactionFilter();
  entitiesWithContracts = new McEntity2List();
  showRejectMcb = new McBoolean();
  showProfileMcb = new McBoolean();
  ebFactoring!: EbFactoring | null;
  factoringToAccept!: EbFactoring;
  ebFactoringStatusCdVll: McValueLabelList = EbFactoring.getStatusCdVll();
  typeCdVll: McValueLabelList = EbSepaFileTransaction.getFactoredServiceTypeCdVll();
  ebFactoringTypeCdsVll!: McValueLabelList;
  entitiesWithContractsVll!: McValueLabelList;
  notifyMessage = {message: '', type: ''};
  statusPendingMcb = new McBoolean();
  statusNotPendingMcb = new McBoolean();
  showAcceptContractDialogMcb = new McBoolean();
  acceptDialogButtonsKvm = new Map<string, string>();
  mcGod = McGod.getInstance();
  statusCds: any[] = [];
  typeCds = [];
  clearedAllMultipleFilters: Subject<boolean> = new Subject<boolean>();


  @ViewChild('validateChildComponent') private mccMultiselectFilter!: MccMultiselectFilterComponent;

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.ebFactoringList = new EbFactoringList();
    this.ebFactoringList.setPager(0, 100);
    // this.ebFactoringList.setSortB('firstName', 'DESC');
    this.loggedEntityId = McGod.getLoggedEntityIdFromToken();
    this.ebFactoringTypeCdsVll = new McValueLabelList();
    this.ebFactoringTypeCdsVll.add('FACTORING', 'Factoring');
    this.ebFactoringTypeCdsVll.add('SERVICE', 'Service');
    this.actLoad();
    this.entitiesWithContracts.loadAllWhereFactoringChildIsLoggedInEntity(() => {
      this.entitiesWithContractsVll = this.entitiesWithContracts.toVll('id', 'name');
      if (this.entitiesWithContractsVll) {
        this.entitiesWithContractsVll.addFirst('', '-- all');
      }
    });

    this.acceptDialogButtonsKvm.set('btn-primary', this.mcGod.t('cc.factoring.accept'));
    this.acceptDialogButtonsKvm.set('btn-secondary', this.mcGod.t('cc.common.edit.cancel'));
  }


  actLoad(): void {
    this.ebFactoringFilter.outFactoringFlg = false;
    const filteredStatusCds = this.statusCds.filter((arr: any) => arr.length);
    this.ebFactoringFilter.contractType = this.mcForm.getValue('selTypeCd');
    this.ebFactoringFilter.statusCds = filteredStatusCds;
    this.ebFactoringFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebFactoringList.loadByFilter(this.ebFactoringFilter);
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selTypeCd')?.get('value')?.setValue('');
    this.actLoad();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === EbFactoring.ACTION_SHOW_REJECT) {
      this.actShowRejectModal(mcButton.item);
    } else if (mcButton.actionCdForEvent === EbFactoring.ACTION_SHOW_PROFILE) {
      this.actShowProfileModal(mcButton.item);
      if (mcButton.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
        this.statusPendingMcb.setTrue();
        this.statusNotPendingMcb.setFalse();
      } else {
        this.statusPendingMcb.setFalse();
        this.statusNotPendingMcb.setTrue();
      }
    } else if (mcButton.actionCdForEvent === EbFactoring.ACTION_SHOW_ACCEPT) {
      this.showAcceptContractDialogMcb.setTrue();
      this.factoringToAccept = mcButton.item;
  }
  }



  actShowRejectModal(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    if (this.ebFactoring.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.showRejectMcb.setTrue();
    } else {
      this.notifyMessage.message = this.mcGod.t('cc.consumer.filter.you-can-only-reject-contract-in-status-pending-acceptance');
      this.notifyMessage.type = 'error';
    }
  }
  actShowProfileModal(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    this.showProfileMcb.setTrue();
  }
  actAcceptContract(ebFactoring: EbFactoring | null) {
    this.ebFactoring = ebFactoring;
    console.log('eb factoring', this.ebFactoring);
    if (this.ebFactoring?.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_ACCEPTED;
      this.ebFactoring.save(() => {
        console.log('eb factoring after save', this.ebFactoring);
        this.actLoad();
        // @ts-ignore
        this.notifyMessage.message = `${this.mcGod.t('cc.contract.contract')} '${this.ebFactoring.factoringContractName}' ${this.mcGod.t('cc.consumer.filter.accepted-successfully')}.`;
        this.notifyMessage.type = 'success';
      }, () => {
        // @ts-ignore
        this.notifyMessage.message =  this.ebFactoring?.apiErrorMessage;
        this.notifyMessage.type = 'error';
      });
    } else {
      this.notifyMessage.message = this.mcGod.t('cc.consumer.filter.you-can-only-accept-contract-in-status-pending-acceptance');
      this.notifyMessage.type = 'error';
    }
  }

  actRejected() {
    this.actLoad();
    this.showRejectMcb.setFalse();
  }

  onRejectModalCanceled() {
    this.ebFactoring = null;
    this.showRejectMcb.setFalse();
  }

  onAcceptContract() {
    this.actAcceptContract(this.ebFactoring);
    this.showProfileMcb.setFalse();
  }

  onRejectContract() {
    this.showRejectMcb.setTrue();
  }

  onCancelContract() {
    this.showProfileMcb.setFalse();
    this.actLoad();
  }

  onMcDialogBoxAcceptContractActionButtonClicked(action: string) {
    if (action === DialogAction.ACT_CANCEL) {
      this.showAcceptContractDialogMcb.setFalse();
    } else {
      this.actAcceptContract(this.factoringToAccept);
      this.showAcceptContractDialogMcb.setFalse();
    }
  }

  onSelectedStatusCds(selectedStatusCds: any[]) {
    this.statusCds = selectedStatusCds;
  }
}
