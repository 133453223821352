<h3>{{'cc.common.pdf-templates' | translate}}</h3>

<lib-mc-loader [showLoader]="mcPdfTemplateList.apiLoadingFlg"></lib-mc-loader>
<div class="mc-filter fancy-label">

    <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                  [mcForm]="mcForm"></mcc-fi-textbox>

  <!--<mcc-fi-select [mcForm]="mcForm" name="selStatusCd" label="{{'cc.common.view.status' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
  <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" label="{{'cc.jobs.edit.type' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>-->

  <button class="mc-link mc-filter-clear-button filter-clear-top-right"
          (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
  <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
</div>

<mcc-message [errorMsg]="mcPdfTemplateList.apiErrorMessage" [successMsg]="successMsg"></mcc-message>

<div class="actions-container">
  <span><i class="title-icon fas fa-file-pdf"></i> {{'cc.common.pdf-templates' | translate}}</span>
  <div class="actions-wrapper">
    <button *ngIf="mcGod.userHasMcProcessPdfTemplateAddPermission()" (click)="actShowAddEditTemplate()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{'cc.common.add-pdf-template' | translate}}
    </button>
  </div>
</div>

<div *ngIf="mcPdfTemplateList.apiSuccessFlg">
  <mcc-fi-table-3 [objectList]="mcPdfTemplateList"
                  [table3Config]="mcPdfTemplateTable3Config"
                  (eventPaginationOrSortChanged)="actLoad()" (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
</div>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="addPdfTemplateMcb.value" [showMcb]="addPdfTemplateMcb" (eventCanceled)="actCancelPdfTemplateAddEdit()">
  <mc-process-pdf-template-add-edit [mcPdfTemplate]="mcPdfTemplate" (eventSaved)="actSavedPdfTemplateAddEdit()" (eventCanceled)="actCancelPdfTemplateAddEdit()"></mc-process-pdf-template-add-edit>
</mcc-modal1-wrapper>
