import {Component, OnInit} from '@angular/core';
import {
  BaseDirective, CsrConsumerService,
  EbFactoringService,
  McBankAccountService,
  McBoolean,
  McConsumerBalanceFilter,
  McConsumerBalanceList,
  McCurrencyService,
  McDashboard,
  McDashboardService,
  McEntity2,
  McGod,
  McStateManagementService
} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent extends  BaseDirective implements OnInit {

  showLoader!: boolean;
  eventsPresent = false;
  dashboardData = new McDashboard();
  entity!: McEntity2;
  isRootEntity!: boolean;
  mcGod = McGod.getInstance();
  showBalanceMcb = new McBoolean();
  mcConsumerBalanceList = new McConsumerBalanceList();
  mcConsumerBalanceFilter = new McConsumerBalanceFilter();
  entityIds = [];
  consumersWithBalance!: number;


  // Constructor
  constructor(private dashboardService: McDashboardService,
              private factoringService: EbFactoringService,
              private mcCurrencyService: McCurrencyService,
              private csrConsumerService: CsrConsumerService,
              private mcBankAccountService: McBankAccountService,
              private stateManagement: McStateManagementService) {
    super();
  }

  /*On init class*/
  ngOnInit() {
    this.showLoader = true;
    this.factoringService.changeEntity(McGod.getLoggedEntityIdFromToken());
    this.mcBankAccountService.changeEntity(McGod.getLoggedEntityIdFromToken());
    this.stateManagement.isSwitchedEntity.pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response) {
        this.getDashboardData();
        this.getEntityById();
        this.factoringService.changeEntity(McGod.getLoggedEntityIdFromToken());
        this.mcBankAccountService.changeEntity(McGod.getLoggedEntityIdFromToken());
        this.mcCurrencyService.getAllCurrencies();
       }
    });
    this.getDashboardData();
    this.getEntityById();
  }

  getEntityById() {
    this.entity = new McEntity2();
    const loggedEntityId = McGod.getLoggedEntityIdFromToken();
    this.entity.loadById(loggedEntityId, () => {
      this.isRootEntity = !this.entity.parentEntityId;
    });
  }

  getDashboardData() {
    this.dashboardService.getDashboardData().subscribe(data => {
      this.dashboardData = data;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });

    // Get number of consumers with balance
    this.mcConsumerBalanceFilter.entityIds = this.entityIds;

    this.mcConsumerBalanceList.loadByFilter(this.mcConsumerBalanceFilter, () => {
      this.consumersWithBalance = this.mcConsumerBalanceList.items.length;
    });
  }


  onBalanceClicked() {
    if (this.consumersWithBalance > 0) {
      this.showBalanceMcb.setTrue();
    }
  }
}
