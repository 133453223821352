import { McProductProductProfilePartComponent } from './components/mc-product-product-profile-part/mc-product-product-profile-part.component';
import { McProductAddEditProductComponent } from './components/mc-product-add-edit-product/mc-product-add-edit-product.component';
import { McProductAddCategoryComponent } from './components/mc-product-add-category/mc-product-add-category.component';
import { McProductProductListPageComponent } from './pages/mc-product-product-list-page/mc-product-product-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McProductRoutingModule } from './mc-product-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {McManagementModule} from '../mc-management/mc-management.module';
import {TranslateModule} from "@ngx-translate/core";
import { ContractsPredefinedListPageComponent } from './pages/contracts-predefined-list-page/contracts-predefined-list-page.component';
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";

@NgModule({
  declarations: [
    McProductProductProfilePartComponent,
    McProductAddEditProductComponent,
    McProductAddCategoryComponent,
    McProductProductListPageComponent,
    ContractsPredefinedListPageComponent,
  ],
  imports: [
    CommonModule,
    McProductRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McManagementModule,
    TranslateModule,
    SafeHtmlModule,
  ],
  exports: [
    McProductProductProfilePartComponent,
    McProductAddEditProductComponent,
    McProductAddCategoryComponent,
    McProductProductListPageComponent,
    McProductRoutingModule,
    FormsModule,
  ]
})
export class McProductModule { }
