<div id="mcc-fi-{{name}}"
     class="mcc-fi mt-3 mcc-fi-radio-buttons mcc-fi-radio-buttons-{{name}} {{extraCssClass}} {{labelPositionClass}}">
  <form [formGroup]="getRadioForm()" [ngClass]="{'validation-error' : this.getRadioForm().get('errorFlg')?.value}">
    <label class="label" *ngIf="label" [ngClass]="{'mc-mandatory-star' : validRequiredFlg}">{{label}}</label>
    <ul [ngClass]="directionHorizontal ? 'direction-row' : 'direction-column'">
      <li *ngFor="let option of optionsVll.items; index as i" [ngClass]="disabledOptionsVll && areButtonsDisabled(option) ? 'mc-disable' : ''">
        <input
          type="radio"
          id="fi-{{name}}-{{option.value}}"
          [value]="option.value"
          (change)="onRadioChanged($event, option.value)"
          formControlName="value">
        <label for="fi-{{name}}-{{option.value}}"
               [ngClass]="{'radio-button-error' : this.getRadioForm().get('errorFlg')?.value}">
          {{option.label}}
        </label>
        <div *ngIf="additionalTextForRadioVll && additionalTextForRadioVll.items.length > 0">
          <div class="radio-item-wrap">
            <p>
              <span class="important-item">
          <mcc-row-title-description title="{{additionalUpperTextForRadio}}:"
                                     [description]="additionalTextForRadioVll.items[i].value"></mcc-row-title-description>
              </span>
              <mcc-row-title-description title="{{additionalBottomTextForRadio}}:"
                                         [description]="additionalTextForRadioVll.items[i].label"></mcc-row-title-description>
            </p>
          </div>
        </div>


      </li>
    </ul>
  </form>
  <div class="error-msg">{{this.getRadioForm().get('errorMsg')?.value}}</div>
</div>






