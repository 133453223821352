<app-shared-go-back icon="fas fa-arrow-left" [title]="'cc.common.go-back-to-consumers-overview' | translate"></app-shared-go-back>
<h3 class="page-title">{{'cc.eb-factoring.transactions.transactions-for' | translate}} {{csrConsumer.fldNameIndividualOrCompany()}}</h3>

<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div *ngIf="csrConsumer.apiErrorFlg">
  ERROR
  {{csrConsumer.apiErrorMessage}}
</div>

<div *ngIf="csrConsumer.apiSuccessFlg">
  <!--THIS CONTAINER IS MANDATORY-->
    <div class="row info-data">
      <div class="col-md-6">
        <div class="info-container-secondary">
          <mcc-row-title-description title="{{'cc.my-factoring.consumer' | translate}}:"
                                     [description]="csrConsumer.fldFullName()"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.common.view.entity' | translate}}:"
                                     [description]="csrConsumer.entityName"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.consumers.consumer-internal-id' | translate}}:"
                                     [description]="csrConsumer.id"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.common.contract-number' | translate}}:"
                                     [description]="csrConsumer.contractAttributes.contractNumber"></mcc-row-title-description>
          <mcc-row-title-description title="{{'cc.consumers.edit.iban' | translate}}:"
                                     [description]="csrBankAccount.iban"></mcc-row-title-description>
          <!--<mcc-row-title-description title="Mandate id:"
                                     [description]="mandateId"></mcc-row-title-description>
          <mcc-row-title-description title="Mandate date:"
                                     [description]="mandateDate | date"></mcc-row-title-description>-->
        </div>
      </div>
      <div class="col-md-6">
        <div class="colored-header">
        <span><mcc-row-title-description [widerTitleFlg]="true" class="desc-right" title="Current status"
                                         [description]="csrConsumer.getNow()"></mcc-row-title-description>

        </span>


        </div>
        <div class="info-container">
          <div class="mat-card-title">
            <div class="title"><span>{{'cc.factoring.imported-sepa.total-amount-due' | translate}}:</span></div>
            <div class="description"><span [ngClass]="pmTransaction.amountDue > 0 ? 'text-danger' : 'text-primary'">{{format(totalAmountDue)}}</span></div>
          </div>
      </div>
        <div class="info-container">
          <div class="mat-card-title">
            <div class="title"><span>{{'cc.dashboard.balance' | translate}}:</span></div>
            <div class="description"><span [ngClass]="consumerBalanceAmount > 0 ? 'text-success' : 'text-primary'">{{format(consumerBalanceAmount)}}</span></div>
          </div>
        </div>
    </div>
  </div>


  <lib-mc-loader [showLoader]="pmTransactionList.apiLoadingFlg"></lib-mc-loader>

  <div *ngIf="pmTransactionList.apiErrorFlg">
    Error: {{pmTransactionList.apiErrorMessage}}
  </div>

  <app-shared-filter-wrapper>
    <div class="filter-container">
      <div class="filter-inputs">

        <!-- Search-->
        <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                        (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"
                        tooltipClass="mc-tooltip-length"
                        ngbTooltip="Search by: Description"
                        placeholder="Search"
                        placement="bottom-left">
        </mcc-fi-textbox>

        <!-- Status-->
        <mcc-multiselect-filter [optionVll]="ebSepaFileTransactionCdVll" [labelHasTransactionIcon]="true"
                                label="{{'cc.common.view.status' | translate}}" name="selStatus"
                                (eventSelectedItems)="onSelectedStatus($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Type-->
        <mcc-multiselect-filter [optionVll]="typeCdVll" label="{{'cc.jobs.edit.type' | translate}}" name="selType"
                                (eventSelectedItems)="onSelectedTypeCds($event)" [clearedAllFilters]="clearedAllMultipleFilters">
        </mcc-multiselect-filter>

        <!-- Paused-->
        <mcc-fi-select [mcForm]="mcForm" name="selPausedUnpaused" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Paused"
                       [optionsVll]="pausedUnpausedCdVll" [isSelectInFilter]="true">
        </mcc-fi-select>

        <!-- In Dunning-->
        <mcc-fi-select [mcForm]="mcForm" name="selInDunning" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="In Dunning"
                       [optionsVll]="inDunningCdVll" [isSelectInFilter]="true">
        </mcc-fi-select>

        <!-- Completed Dunning-->
        <mcc-fi-select [mcForm]="mcForm" name="selCompletedDunning" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Completed dunning"
                       [optionsVll]="completedDunningCdVll" [isSelectInFilter]="true">
        </mcc-fi-select>

        <!-- Date-->
        <mcc-fi-date-picker
          datePickerViewType="RANGE_SELECTION"
          [mcForm]="mcForm"
          name="ctrlPaymentDueDate"
          label="Payment due date range"
          (eventFromDate)="onPaymentDueDateFrom($event)"
          (eventToDate)="onPaymentDueDateTo($event)"
          [allowManualEntryFlg]="false"
          monthsDisplayed="2"
          [clearDateRange]="clearDateRangeSubject">
        </mcc-fi-date-picker>
      </div>

      <div class="filter-actions">
        <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
        <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
      </div>
    </div>
  </app-shared-filter-wrapper>

  <div class="actions-container">
    <span><i class="fas fa-clipboard-list"></i> {{'cc.eb-factoring-transactions.transactions-overview' | translate}}</span>
    <div class="actions-wrapper">
<!--      <button *ngIf="mcGod.userHasEbFactoringMyTransactionAddPermission()" (click)="actShowAddTransactionMcb()" class="system-action-btn">-->
<!--        <i class="fas fa-plus"></i> {{'cc.common.view.add-transaction' | translate}}-->
<!--      </button>-->
    </div>
  </div>

  <mcc-message [successMsg]="notifyMessage.successMessage" [errorMsg]="notifyMessage.errorMessage" class="text-center"></mcc-message>

  <div class="mc-transactions-table">
    <div *ngIf="pmTransactionList.apiSuccessFlg">
      <mcc-fi-table-3 [objectList]="pmTransactionList"
                      [table3Config]="pmTransactionTable3Config"
                      (eventPaginationOrSortChanged)="actLoad()"
                      (eventSelectionChanged)="receiveStateOnSelect($event)"
                      (eventItemAction)="actOnTransactionTableItemAction($event)"></mcc-fi-table-3>
    </div>
  </div>

  <!--     <mcc-modal1-wrapper *ngIf="showTransactionAddPaymentMcb.value" [showMcb]="showTransactionAddPaymentMcb"
                           modalSizeCd="full">
         <app-shared-transaction-adjustment-add-edit-part type="payment"
                                                          ebFactoringLevel="CHILD"
                                                          (eventSaved)="actOnTransactionAddPaymentClose()"
                                                          (eventCanceled)="showTransactionAddPaymentMcb.setFalse()"
                                                          ebTransactionAdjustmentId="0"
                                                          [ebSepaFileTransaction]="ebSepaFileTransaction"
                                                          [ebSepaFileTransactionId]="actionEbSepaFileTransactionId"></app-shared-transaction-adjustment-add-edit-part>
       </mcc-modal1-wrapper>
 -->
  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddPaymentForMultipleTransactions.value" [showMcb]="showAddPaymentForMultipleTransactions">
    <app-shared-transaction-payment-add-edit-part [pmTransactions]="selectedTransactionList"
                                                  (eventCanceled)="actCancelAddEditMultiplePayments()"
                                                  (eventApiError)="paymentApiErrorEvent($event)"
                                                  (eventSaved)="actPaymentMultipleTransactionsSaved()"
                                                  [isParentEntity]="false">
    </app-shared-transaction-payment-add-edit-part>
  </mcc-modal1-wrapper>

  <mcc-slide-action-set [actionSet]="actionSet" [selectedNumber]="numberOfSelectedItems"
                        (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>


  <!--  <mcc-modal1-wrapper [showMcb]="showChangeCollectionDateMcb" *ngIf="showChangeCollectionDateMcb.value"
                        modalSizeCd="small">
      <app-shared-change-collection-date-part [selectedTransactionList]="selectedTransactionList"
                                              [selectedTransactionsNumber]="selectedTransactionsNumber"
                                              [entityId]="entityId"
                                              [ebFactoring]="ebFactoring"
                                              [ebSepaFileTransaction]="ebSepaFileTransaction"
                                              (eventCollectionDateCanceled)="actCancelChangeCollectionModal()"
                                              (eventCollectionDateChanged)="actChangeCollectionDate()"></app-shared-change-collection-date-part>
    </mcc-modal1-wrapper>-->
  <mcc-modal1-wrapper [showMcb]="showPauseTransactionMcb" *ngIf="showPauseTransactionMcb.value" (eventCanceled)="onPauseTransactionCanceled()"
  >
    <app-shared-pause-transactions (eventPausedTransaction)="onTransactionPaused()"
                                   (eventUnpausedTransaction)="onTransactionUnpaused()"
                                   (eventPauseTransactionCanceled)="onPauseTransactionCanceled()"
                                   [pmTransactionList]="selectedTransactionList"
    ></app-shared-pause-transactions>
  </mcc-modal1-wrapper>

  <mcc-slide-action-set [actionSet]="actionSet"
                        [selectedNumber]="selectedNumberOfTransactions"
                        (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>
<!--  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddTransactionsManuallyMcb.value" [showMcb]="showAddTransactionsManuallyMcb">-->
<!--    <app-shared-transaction-add-manually-part [mcConsumer]="mcConsumer" (eventCanceled)="actAddTransactionManuallyCanceled()" (eventSaved)="actAddTransactionManuallySaved()"></app-shared-transaction-add-manually-part>-->
<!--  </mcc-modal1-wrapper>-->

  <mcc-modal1-wrapper *ngIf="showConsumerProfileMcb.value" [showMcb]="showConsumerProfileMcb" modalSizeCd="full">
    <mc-consumer-consumer-profile-part  (eventClosed)="showConsumerProfileMcb.setFalse()" [csrConsumerId]="csrConsumerId"></mc-consumer-consumer-profile-part>
  </mcc-modal1-wrapper>

  <!--<mcc-slide-action-set [actionSet]="actionSet"
                        [selectedNumber]="selectedTransactionsNumber"
                        (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>-->



</div>



