<ng-container *ngIf="errorMessage">
  <mcc-message class="col-md-12" [errorMsg]="errorMessage"></mcc-message>
</ng-container>

<div class='card'>
  <h2 class="title-medium-dark mb-large">{{ 'cc.consumer.products-and-services'| translate }}</h2>
  <mcc-fi-select  name="selProduct" placeholderOption="{{'cc.consumer.products-and-services' | translate}}"
                  [validRequiredFlg]="selectedProductList.getCount() < 0"
                  [value]="''"
                  [optionsVll]="productsVll"
                  (eventValueChanged)="onProductClick($event)"
                  [mcForm]="mcForm"></mcc-fi-select>

  <div class="mc-table-wrapper products-table mt-2" *ngIf="selectedProduct.exists()">
    <div class="mc-table">
      <table class="w-100">
        <thead class="mc-table-head">
        <tr>
          <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
          <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
          <th class="text-left">{{'cc.common.view.description' | translate}}</th>
          <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
          <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-left">{{selectedProduct.id}}</td>
          <td class="text-left">{{selectedProduct.name}}</td>
          <td class="text-left">{{selectedProduct.description}}</td>
          <td class="text-right">{{selectedProduct.fldUnitPriceLoc()}}</td>
          <td class="text-right">{{selectedProduct.vatRate.toFixed(2).replace('.', ',')}}%</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="selectedProduct.exists()">
      <button class="primary-btn ml-small" (click)="actAddProduct(selectedProduct)">
        {{ 'cc.common.table.add' | translate }}
      </button>
    </div>
  </div>

  <div class="mt-4" *ngIf="selectedProductList.getCount() > 0">
    <h2 class="title-medium-dark mb-large">{{ 'cc.consumer.selected-products-and-services' | translate }}</h2>

    <div class="mc-table-wrapper products-table mt-2" *ngIf="selectedProduct.exists()">
      <div class="mc-table">
        <table class="w-100">
          <thead class="mc-table-head">
          <tr>
            <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
            <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
            <th class="text-left">{{'cc.common.view.description' | translate}}</th>
            <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
            <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let product of selectedProductList.items">
            <td class="text-left">{{product.id}}</td>
            <td class="text-left">{{product.name}}</td>
            <td class="text-left">{{product.description}}</td>
            <td class="text-right">{{product.fldUnitPriceLoc()}}</td>
            <td class="text-right">{{product.vatRate.toFixed(2).replace('.', ',')}}%</td>
            <td>
              <div class='quantity-div'>
                <button type='button' class='removeProduct' (click)='onProductRemove(product)'><span [innerHTML]="iconRemove | safeHtml"></span></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<lib-mc-loader [showLoader]="mcVat.apiLoadingFlg || productList.apiLoadingFlg"></lib-mc-loader>
