/*BbmTestObj*/
import {CsrContractPreDefinedGENERATED} from '../_generated/csr-contract-pre-defined-generated.model';
import {ICON_STATUS_ACTIVE} from "../svg/icon-status-active";
import {ICON_STATUS_INACTIVE} from "../svg/icon-status-inactive";
import _moment from "moment/moment";

export class CsrContractPreDefined extends CsrContractPreDefinedGENERATED {
  populatedProducts: [] = [];
  availableOptions: [] = [];
  public static ACTION_VIEW = "View";
  public static ACTION_EDIT = "Edit";

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractPreDefined();
  }

  // ---------------------------------------------------------------------

  getName() {
    return this.name
  }

  getServices() {
    return this.populatedProducts.length
  }

  getDateFrom() {
    return _moment(this.startDate).format("DD.MM.YYYY");
  }

  getDateTo() {
    return this.endDate ? _moment(this.endDate).format("DD.MM.YYYY") : '-';
  }

  getStatus() {
    if (this.statusCd === CsrContractPreDefined.STATUS_CD_ACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_ACTIVE} <span>Active</span></div>`
    }
    if (this.statusCd === CsrContractPreDefined.STATUS_CD_INACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_INACTIVE} <span>Inactive</div>`
    }
    return '';
  }

  getMonthPrice() {
    return this.pricePerMonth + "€";
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.populatedProducts = aSourceJson.products;
    this.availableOptions = aSourceJson.reccuringOptions;
  }
}
