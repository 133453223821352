<lib-mc-loader [showLoader]="mcProcess.apiLoadingFlg"></lib-mc-loader>
<!--<div class="mc-go-back-to-all" (click)="goBack()">-->
<!--  < {{('cc.common.back-to-all' | translate)}}-->
<!--</div>-->

<span class="back-option" (click)="actGoToProcesses()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.process.back-to-processes" | translate }}
</span>

<div class="content-wrapper wrapper-padding">
  <!--  <lib-mc-loader [showLoader]="mcConsumer.apiLoadingFlg"></lib-mc-loader>-->

  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-body">

    <mcc-menu [mcMenu]="mcMenu"></mcc-menu>

    <div class="col-md-12">
      <mcc-message [successMsg]="successMsg" [errorMsg]="flowObjectList.apiErrorMessage"></mcc-message>
      <mcc-message [errorMsg]="mcProcess.apiErrorMessage"></mcc-message>
    </div>

    <div class="section">
      <div class="section_header">
        <span class="section_header_title">{{mcProcess.title}} {{ "cc.processes.process" | translate }}</span>
        <button *ngIf="mcGod.userHasMcProcessEditPermission()" (click)="actShowEditProcessProfile()" mat-button
                class="primary-btn">{{"cc.common.edit" | translate}}
        </button>
      </div>
      <div class="section_content">
        <div class="section_content_item">
          <span class="section_content_text">{{'cc.common.view.status' | translate}}:</span>
          <div>
            <span [innerHTML]="(mcProcess.statusCd === 'ACTIVE' ? iconActive : iconInactive) | safeHtml"></span>
            <span class="section_content_text font-weight-bold">{{ mcProcess.statusCd | titlecase }}</span>
          </div>
        </div>
        <div class="section_content_item">
          <span class="section_content_text">{{'cc.processes.process-type' | translate}}:</span>
          <span class="section_content_text font-weight-bold">{{ mcProcess.getTypeCdLabel() }}</span>
        </div>
        <div class="section_content_item">
          <span class="section_content_text">{{'cc.common.name' | translate}}:</span>
          <span class="section_content_text font-weight-bold">{{ mcProcess.title }}</span>
        </div>
        <div class="section_content_item">
          <span class="section_content_text">{{'cc.common.view.description' | translate}}:</span>
          <span class="section_content_text font-weight-bold">{{ mcProcess.description }}</span>
        </div>
        <div class="section_content_item">
          <span class="section_content_text">{{'cc.common.edit.inheritable' | translate}}:</span>
          <span class="section_content_text font-weight-bold">{{ mcProcess.formatInheritableFlg() | titlecase }}</span>
        </div>
      </div>
    </div>

    <section *ngIf="mcProcess && mcProcess.typeCd !== 'NOTIFICATIONS'">
      <div class="section">
        <div class="section_header">
          <span class="section_header_title">{{"cc.processes.flows" | translate}}</span>
          <button *ngIf="mcGod.userHasMcProcessFlowAddPermission()" (click)="actCreateFlow()" class="system-action-btn"><i class="fas fa-plus-circle"></i> {{"cc.processes.create-flow" | translate}}</button>
        </div>
      </div>

      <div *ngIf="flowObjectList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="flowObjectList"
                        [table3Config]="tableConfig"
                        (eventPaginationOrSortChanged)="actLoad()"
                        (eventItemAction)="onTableItemAction($event)">
        </mcc-fi-table-3>
      </div>

      <mcc-modal1-wrapper *ngIf="showAddEditProcessFlowMcb.value" [showMcb]="showAddEditProcessFlowMcb"
                          (eventCanceled)="actCloseAddEditProcessFlowMcb()">
        <mc-process-process-flow-add-edit [mcProcess]="mcProcess" [mcProcessId]="mcProcess.id"
                                          (eventCanceled)="actCloseAddEditProcessFlowMcb()"
                                          (eventSaved)="actAddEditProcessFlowSaved()"></mc-process-process-flow-add-edit>
      </mcc-modal1-wrapper>
    </section>

    <section *ngIf="mcProcess && mcProcess.typeCd === 'NOTIFICATIONS'">
      <div class="section">
        <div class="section_header">
          <span class="section_header_title">{{ "cc.process.configuration" | translate }}</span>
          <button mat-button (click)="addEditConfigurationDialog()" class="primary-btn">
            {{(processNotifications && processNotifications.length > 0 ? "cc.common.edit" : "cc.common.create") | translate}}
          </button>
        </div>
        <section *ngFor="let notification of processNotifications">
          <div class="section_content" *ngIf="isNotificationPayment(notification.eventTypeCd)">
            <div class="section_content_item">
              <span class="section_content_text">{{ "cc.process.notifications-type" | translate }}:</span>
              <span class="section_content_text font-weight-bold">
              {{ getEventTypeCdLabel(notification.eventTypeCd) }}
            </span>
            </div>
            <div class="section_content_item">
              <span class="section_content_text">{{ "cc.process.payment-notifications" | translate }}:</span>
              <span class="section_content_text font-weight-bold">
              {{ getEventFilterCdLabel(notification.eventFilterCd) }}
            </span>
            </div>
          </div>
          <div class="section_content" *ngIf="isNotificationBilling(notification.eventTypeCd)">
            <div class="section_content_item">
              <span class="section_content_text">{{ "cc.process.notifications-type" | translate }}:</span>
              <span class="section_content_text font-weight-bold">
                {{ getEventTypeCdLabel(notification.eventTypeCd) }}
            </span>
            </div>
          </div>
        </section>
      </div>
    </section>

    <mcc-modal1-wrapper [modalSizeCd]="'small'" *ngIf="showEditProcessProfile.value" [showMcb]="showEditProcessProfile"
                        (eventCanceled)="actCancelEditProcessMcb()">
      <mc-process-process-add-edit-part [mcProcess]="mcProcess" (eventCanceled)="actCancelEditProcessMcb()"
                                        (eventSaved)="actSavedEditProcessMcb()"></mc-process-process-add-edit-part>
    </mcc-modal1-wrapper>
  </div>
</div>

<router-outlet></router-outlet>
