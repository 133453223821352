import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ICsrContractPreDefined} from '../_generated/csr-contract-pre-defined.interface';
import {CsrContractPreDefined} from '../models/csr-contract-pre-defined.model';
import {CsrContractPreDefinedList} from '../models/csr-contract-pre-defined-list.model';
import {CsrContractPreDefinedServiceGENERATED} from '../_generated/csr-contract-pre-defined-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CsrContractPreDefinedService extends CsrContractPreDefinedServiceGENERATED {

}
