import {Component, OnInit} from '@angular/core';
import {
  McBillingService2PriceListList,
  McBoolean, McEntity2List,
  McForm, McGod,
  McHtmlButton,
  McPriceList, McPriceListFilter,
  McPriceListList,
  McPriceListTable3Config, McValueLabel, McValueLabelList
} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";

@Component({
  selector: 'mc-pricelist-pricelists-list-page',
  templateUrl: './mc-pricelist-pricelists-list-page.component.html',
  styleUrls: ['./mc-pricelists-pricelists-list-page.scss']
})
export class McPricelistPricelistsListPageComponent implements OnInit {

  mcPriceListList = new McPriceListList();
  mcTemplates = new McPriceListList();
  mcPriceListTable3Config = new  McPriceListTable3Config();
  mcForm = new McForm();
  mcFormForCreatePricelist = new McForm();
  entityId!: number;
  showAddPricelistStep1 = new McBoolean();
  showAddPricelistStep2PricesAndEntities = new McBoolean();
  showAddPricelistStep3ReviewAndConfirm = new McBoolean();
  chosenTemplatePriceList = new McPriceList();
  defaultChosenTemplatePriceList = new McPriceList();
  mcTemplateFilter = new McPriceListFilter();
  pricelistEntitiesValue!: McValueLabelList;
  mcGod = McGod.getInstance();
  selectedEntities!: McEntity2List;
  createPricelistCurrencySelected!: string;
  currencyCode!: string;

  // ---------------------------------------------------------------------
  constructor(private router: Router) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.entityId = McGod.getLoggedEntityIdFromToken();
    this.mcPriceListList = new McPriceListList();
    this.mcPriceListList.setPager(0, 100);
    this.actLoad();
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();
  }

  // ---------------------------------------------------------------------


  public actLoad(): void {
    this.mcTemplateFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.mcTemplateFilter.idEntityOwner = McGod.getLoggedEntityIdFromToken();
    this.mcTemplateFilter.isTemplate = false;
    this.mcPriceListList.setSortB('id', 'DESC');
    this.mcPriceListList.loadByFilter(this.mcTemplateFilter);
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }

  onPriclistTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === McPriceList.ACTION_VIEW_DETAILS) {
      this.router.navigate([`/entity/${this.entityId}/pricelists/overview/${htmlButton.item.id}`]);
    }
    if (htmlButton.actionCdForEvent === McPriceList.ACTION_EDIT) {

    }
  }

  actShowNewPricelistMcb() {
    this.showAddPricelistStep1.setTrue();
  }

  actCloseAddPricelistStepper() {
    this.showAddPricelistStep1.setFalse();
    this.showAddPricelistStep2PricesAndEntities.setFalse();
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
    this.mcFormForCreatePricelist = new McForm();
    this.pricelistEntitiesValue = new McValueLabelList();
    this.actLoad();
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();
  }

  onAddPricelistStep1NextEmit(currencyCode: string) {
    this.currencyCode = currencyCode;

    // @ts-ignore
    this.chosenTemplatePriceList = this.mcTemplates.getItemById(this.mcFormForCreatePricelist.getValue('ctrlTemplate'));
    this.defaultChosenTemplatePriceList.mcBillingService2PriceListItemList = new McBillingService2PriceListList();
    this.chosenTemplatePriceList.mcBillingService2PriceListItemList.items.forEach(item => {
      // @ts-ignore
      this.defaultChosenTemplatePriceList.mcBillingService2PriceListItemList.addItem({...item});
    });

    this.showAddPricelistStep2PricesAndEntities.setTrue();
    this.showAddPricelistStep1.setFalse();

  }


  onAddPricelistStep2PrevEmit() {
    this.showAddPricelistStep1.setTrue();
    this.showAddPricelistStep2PricesAndEntities.setFalse();
  }

  onAddPricelistStep2NextEmit() {
    this.showAddPricelistStep2PricesAndEntities.setFalse();
    this.showAddPricelistStep3ReviewAndConfirm.setTrue();
    this.pricelistEntitiesValue = new McValueLabelList();
    const entitiesValue: [] = this.mcFormForCreatePricelist.getValue('ctrlEntitiesPricelist');
    entitiesValue.forEach(item => {
      const entity = new McValueLabel();
      entity.label = item;
      entity.value = item;
      this.pricelistEntitiesValue.addItem(entity);
    });
  }

  onAddPricelistStep3PrevEmit() {
    this.pricelistEntitiesValue = new McValueLabelList();
    const entitiesValue: [] = this.mcFormForCreatePricelist.getValue('ctrlEntitiesPricelist');
    entitiesValue.forEach(item => {
      const entity = new McValueLabel();
      entity.label = item;
      entity.value = item;
      this.pricelistEntitiesValue.addItem(entity);
    });
    this.showAddPricelistStep2PricesAndEntities.setTrue();
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
  }

  onAddPricelistSaveEmit() {
    this.showAddPricelistStep3ReviewAndConfirm.setFalse();
    this.mcFormForCreatePricelist = new McForm();
    this.actLoad();
    this.mcTemplates.loadAllPossibleTemplatesForNewPriceList();
  }

  onSelectedEntities(value: McEntity2List) {
    this.selectedEntities = value;
  }

  onCurrencySymbolEmitted(value: string) {
    this.createPricelistCurrencySelected = value;
  }
}
