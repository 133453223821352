<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1" style="text-transform: capitalize">{{'cc.factoring.contracts' | translate}}</h3>
</div>

<div>
  <lib-mc-loader [showLoader]="ebFactoringList.apiLoadingFlg"></lib-mc-loader>

  <div *ngIf="ebFactoringList.apiErrorFlg">
    Error: {{ebFactoringList.apiErrorMessage}}
  </div>

  <div class="mc-filter fancy-label">


      <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.my-factoring.search-by-contract-name' | translate}}"
         placement="bottom-left"></i>
      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm"
                      (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Contract type"
                   [optionsVll]="ebFactoringTypeCdsVll" [isSelectInFilter]="true"></mcc-fi-select>
    <mcc-multiselect-filter [optionVll]="ebFactoringStatusCdVll" [clearedAllFilters]="clearedAllMultipleFilters"
                            label="{{'cc.common.view.status' | translate}}" name="selStatus" (eventSelectedItems)="onSelectedStatusCds($event)">
    </mcc-multiselect-filter>
      <mcc-multiselect-filter [optionVll]="entitiesWithContractsVll" [clearedAllFilters]="clearedAllMultipleFilters"
                              label="{{'cc.common.view.entity' | translate}}" name="selEntity" (eventSelectedItems)="onSelectedEntity($event)">
      </mcc-multiselect-filter>



    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


  </div>

  <div class="col-md-12 mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]="notifyMessage.message" [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>

  <div class="mc-float-right">
    <button class="system-action-btn mt-2" (click)="onCreateNewContract()"><i
      class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.out-factoring.create-new-contract' | translate}}
    </button>
  </div>

  <mcc-modal1-wrapper [showMcb]="showSelectEntityPart" modalSizeCd="small" *ngIf="showSelectEntityPart.value">
    <!--<app-shared-factoring-select-entity-part (eventShowAddEditPart)="onNext()"
                                             (selectedEntityId)="onSelectedEntityId($event)">
    </app-shared-factoring-select-entity-part>-->
    <lib-app-shared-factoring-select-contract-type-and-entity (nextEmit)="onSelectEntityAndContractTypeNext($event)"
                                                              (selectedEntityId)="onSelectedEntityId($event)"
                                                              [isContractTypeOfService]="isContractTypeService"
                                                              [isContractCloned]="isContractBeingCloned"
                                                              (eventEntityForCloning)="onEntityClone($event)"
                                                              [ebFactoring]="ebFactoring"
    ></lib-app-shared-factoring-select-contract-type-and-entity>
  </mcc-modal1-wrapper>


  <mcc-modal1-wrapper [showMcb]="showAddEditPart" *ngIf="showAddEditPart.value">
    <app-shared-factoring-add-edit-part (eventSaved)="onFactoringContractSaved($event)"
                                        (eventCanceled)="onFactoringContractCanceled()"
                                        (eventPrevious)="onPrevious()"
                                        [contractType]="contractType"
                                        [isClonedContract]="isContractBeingCloned"
                                        [ebFactoringId]="ebFactoringId"
                                        [entityClone]="entityForCloning"
                                        [idEntityForCloning]="entityId"
                                        [idChildEntity]="selectedEntityId"></app-shared-factoring-add-edit-part>
  </mcc-modal1-wrapper>

  <!--  <a class="col-md-1 offset-md-11" routerLink="edit/0">Add New</a>-->

  <mcc-dialog-box *ngIf="showRevokeContractDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.out-factoring.revoke-contract' | translate}}" name="revoke-contract"
                  description="{{'cc.out-factoring.are-you-sure-you-want-to-revoke-this-contract' | translate}}?"
                  [buttonsKvm]="revokeDialogButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxRevokeContractActionButtonClicked($event)"></mcc-dialog-box>



  <div *ngIf="ebFactoringList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="ebFactoringList"
                    [table3Config]="ebFactoringTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="actFactoringTableItemAction($event)"></mcc-fi-table-3>
  </div>

  <mcc-modal1-wrapper *ngIf="ebFactoring" [showMcb]="showProfileMcb" (eventCanceled)="actCancelProfileModal()">
    <app-shared-factoring-profile-part [ebFactoring]="ebFactoring"
                                       [contractType]="contractType"
                                       [statusOutPendingMcb]="statusPendingMcb"
                                       [statusNotOutPendingMcb]="statusNotPendingMcb"
                                       (eventCanceled)="actCancelProfileModal()"
                                       (eventRevoked)="actRevoke(ebFactoring)"
                                       [ebFactoringId]="ebFactoring.id"></app-shared-factoring-profile-part>
  </mcc-modal1-wrapper>

</div>

