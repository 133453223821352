<ng-container *ngIf="csrConsumer">
  <div class="content-wrapper">
    <lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>
    <div *ngIf="csrConsumer.apiErrorFlg">
      ERROR
      {{csrConsumer.apiErrorMessage}}
    </div>
    <div *ngIf="csrConsumer.apiLoadingFlg">
      <img src="/assets/images/loading-whitebg.gif"/>
    </div>
    <div *ngIf="csrConsumer.apiSuccessFlg">
      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-header">
        <span class="content-header-title" *ngIf="csrConsumer.exists()">Consumer profile: {{ csrConsumer.individual ? csrConsumer.fldFullName() : csrConsumer.companyName}}</span>
      </div>

      <!--THIS CONTAINER IS MANDATORY-->
      <div class="content-body">
        <h2>{{'cc.consumer.overview.status-information' | translate}}</h2>
        <div class='dialog-row-wrapper'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.jobs.edit.type' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.typeCd ? csrConsumer.typeCd : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.action.type.dunning' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.flgDunningEnabled ? 'yes' : 'no' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.external-id' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.extConsumerId ? csrConsumer.extConsumerId : '-' }}</span>
          </div>
        </div>

        <h2>{{ 'cc.common.basic-information' | translate }}</h2>
        <div class='dialog-row-wrapper'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.company-name' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.companyName ? csrConsumer.companyName : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.first-name' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.firstName ? csrConsumer.firstName : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.last-name' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.lastName ? csrConsumer.lastName : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.birthday' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.birthday ? csrConsumer.getFormattedDate(csrConsumer.birthday) : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.gender' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.gender ? csrConsumer.gender : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.title' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.title ? csrConsumer.title : '-' }}</span>
          </div>
        </div>

        <h2>{{ "cc.common.edit.address" | translate}}</h2>
        <div class='dialog-row-wrapper'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.street' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.street ? csrConsumer.street : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.address-supplement' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.addressSupplement ? csrConsumer.addressSupplement : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.postal-code' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.postCode ? csrConsumer.postCode : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.city' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.city ? csrConsumer.city : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.users.edit.country' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.countryName ? csrConsumer.countryName : '-' }}</span>
          </div>
        </div>

        <h2>{{ "cc.consumer.overview.contact" | translate}}</h2>
        <div class='dialog-row-wrapper'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.email' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.email ? csrConsumer.email : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.telephone' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.telephone ? csrConsumer.telephone : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.cellphone' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.cellphone ? csrConsumer.cellphone : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.edit.fax' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.fax ? csrConsumer.fax : '-' }}</span>
          </div>
        </div>

        <h2>{{'cc.users.edit.language' | translate}}</h2>
        <div class='dialog-row-wrapper'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-country' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.isoCountry ? csrConsumer.isoCountry : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.iso-language' | translate}}:</span>
            <span class='dialog-col-value'>{{ csrConsumer.isoLanguage ? csrConsumer.isoLanguage : '-' }}</span>
          </div>
        </div>

        <h2>{{'cc.consumer.overview.additional-information' | translate}}</h2>
        <div class="row">
          <h2>{{'cc.common.bank-information' | translate}}</h2>
          <div class='dialog-row-wrapper'>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.consumers.edit.iban' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.iban ? csrBankAccount.iban : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.bic ? csrBankAccount.bic : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.consumers.edit.account-owner' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.owner ? csrBankAccount.owner : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.bankName ? csrBankAccount.bankName : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>Sepa Mandate ID:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.sepaMandateId ? csrBankAccount.sepaMandateId : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>Sepa mandate date of signature:</span>
              <span class='dialog-col-value'>{{ csrBankAccount && csrBankAccount.sepaMandateDate ? csrConsumer.getFormattedDate(csrBankAccount.sepaMandateDate) : '-' }}</span>
            </div>
          </div>

          <h2>{{'cc.consumers.edit.contract-information' | translate}}</h2>
          <div class='dialog-row-wrapper'>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-number' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractNumber ? csrConsumer.contractAttributes.contractNumber : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-model' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractModel ?  csrConsumer.contractAttributes.contractModel : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-type' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractType ?  csrConsumer.contractAttributes.contractType : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-status' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractStatus ? csrConsumer.contractAttributes.contractStatus : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-signing-date' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractSigningDate ? csrConsumer.getFormattedDate(csrConsumer.contractAttributes.contractSigningDate) : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-start-date' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractStartDate ? csrConsumer.getFormattedDate(csrConsumer.contractAttributes.contractStartDate) : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-end-date' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractEndDate ? csrConsumer.getFormattedDate(csrConsumer.contractAttributes.contractEndDate) : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-base-amount' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractBaseAmount ? format(csrConsumer.contractAttributes.contractBaseAmount) : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-date' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractCancellationDate ? csrConsumer.getFormattedDate(csrConsumer.contractAttributes.contractCancellationDate) : '-' }}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.cancellation-active-on-date' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractCancellationActiveOnDate ? csrConsumer.getFormattedDate(csrConsumer.contractAttributes.contractCancellationActiveOnDate) : '-'}}</span>
            </div>
            <div class='dialog-col'>
              <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-reason' | translate}}:</span>
              <span class='dialog-col-value'>{{ csrConsumer.contractAttributes.contractCancellationReason ? csrConsumer.contractAttributes.contractCancellationReason : '-'}}</span>
            </div>
          </div>

          <h2 *ngIf="csrConsumer.additionalAttributes">{{'cc.consumer.custom-info' | translate}}</h2>
          <div *ngIf="csrConsumer.additionalAttributes" class='dialog-row-wrapper'>
            <ng-container *ngFor="let item of csrConsumer.additionalAttributes | keyvalue">
              <div class='dialog-col'>
                <span class='dialog-col-label'>{{ item.key }}:</span>
                <span class='dialog-col-value'>{{ item.value }}</span>
              </div>
            </ng-container>
          </div>
        </div>
        </div>
      </div>

    <!--THIS CONTAINER IS MANDATORY-->
    <div class="content-footer">
      <button mat-button class="secondary-btn move-right" (click)="actClose()">{{ "cc.common.close" | translate }}</button>
    </div>
  </div>
</ng-container>
<router-outlet></router-outlet>
