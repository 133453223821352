import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CsrContract, McGod} from '@miticon-ui/mc-core';
import {MatMenuTrigger} from "@angular/material/menu";
import {element} from "protractor";

export interface MkMatMenuItem {
  disabled?: boolean;
  matIcon: string;
  title: string;
  actionCd: string;
  permission: string,
}

@Component({
  selector: 'mk-mat-table-actions',
  templateUrl: './mk-mat-table-actions.component.html',
  styleUrls: ['./mk-mat-table-actions.component.scss']
})
export class MkMatTableActionsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() matMenuItems: MkMatMenuItem[];
  @Input() element: any;
  @Output() menuItemAction = new EventEmitter<MkMatMenuItem>();
  mcGod = McGod.getInstance();


  constructor() {
  }


  ngOnInit() {
  }

  isDisabled(item: MkMatMenuItem) {
    if(this.element instanceof CsrContract && this.element.statusCd === CsrContract.STATUS_CD_CANCELED && item.title === 'Cancel') {
      return true;
    } else return item.disabled;
  }

  mouseEnter() {
    this.trigger.openMenu();
  }

  mouseLeave() {
    this.trigger.closeMenu();
  }



  matMenuItemClicked(item: MkMatMenuItem): void {
    this.menuItemAction.emit(item);
  }

  addMenuItem(item: MkMatMenuItem) {
    this.matMenuItems.push(item);
  }
}
