import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  BaseDirective,
  McBoolean,
  McConsumerFilter,
  McForm, McGod, McHtmlButton,
  McProduct,
  McProductList,
  McProductTable3Config,
  McProductFilter
} from "@miticon-ui/mc-core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'mc-product-product-list-page',
  templateUrl: './mc-product-product-list-page.component.html',
  encapsulation: ViewEncapsulation.None
})

export class McProductProductListPageComponent extends BaseDirective implements OnInit {

  mcProductList = new McProductList();
  mcProduct!: McProduct;
  mcProductTable3Config = new  McProductTable3Config();
  mcForm!: McForm;
  mcProductFilter = new McProductFilter();
  showCreateCategoryMcb = new McBoolean();
  showCreateProductMcb = new McBoolean();
  showProductProfileMcb = new McBoolean();
  showDeleteProduct = new McBoolean();
  buttonsDeleteProductDialog = new Map<string, string>();
  selectedItems = new McProductList();
  selectedNumber!: number;
  productToDelete = new McProduct();
  successMsg!: string;
  errorMsg!: string;

  constructor() {
    super();
  }


  // ---------------------------------------------------------------------
  ngOnInit() {

    this.buttonsDeleteProductDialog.set('b btn-primary', McGod.t('cc.common.edit.yes'));
    this.buttonsDeleteProductDialog.set('a btn-secondary', McGod.t('cc.common.edit.no'));

    this.mcForm = new McForm();
    this.mcProductList = new McProductList();
    this.mcProductList.setPager(0, 100);
    // this.mcProductList.setSortB('name', 'DESC');
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (this.mcForm.getControl('txbSearchTerm')) {
      this.mcProductFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    } else {
      this.mcProductFilter.searchTerm = '';
    }
    this.mcProductList.loadByFilter(this.mcProductFilter);
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }

  receiveSelectedItems(selectedList: McProductList) {
    this.selectedNumber = selectedList.getCount();
  }

  onCreateCategoryCanceled() {
    this.showCreateCategoryMcb.setFalse();
  }

  onCreateCategorySaved() {
    this.showCreateCategoryMcb.setFalse();
    this.successMsg = 'Category is successfully saved.'
  }

  onCreateProductCanceled() {
    this.showCreateProductMcb.setFalse();
  }

  onCreateProductSaved(message: string) {
    this.showCreateProductMcb.setFalse();
    this.successMsg = message;
    this.actLoad();
  }

  onTableItemAction(value: McHtmlButton) {
    switch (value.actionCdForEvent) {
      case McProduct.ACTION_SHOW_PROFILE: {
        this.showProductProfileMcb.setTrue();
        this.mcProduct = value.item;
      }
                                          break;
      case McProduct.ACTION_SHOW_EDIT: {
        this.showCreateProductMcb.setTrue();
        this.mcProduct = value.item;
      }
                                       break;
      case McProduct.ACTION_SHOW_DELETE: {
        this.showDeleteProduct.setTrue();
        this.productToDelete = value.item;
      }
    }
  }

  onCreateProduct() {
    this.showCreateProductMcb.setTrue();
    this.mcProduct = new McProduct();
  }

  onDeleteProductDialogButtonsClicked(value: string) {
    if (value === 'b btn-primary') {
      this.productToDelete.apiService.archive(this.productToDelete.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          if (response) {
            this.showDeleteProduct.setFalse();
            this.successMsg = McGod.t('cc.product.the-product-has-been-successfully-deleted');
            this.actLoad();
          }
        }, () => {
          this.showDeleteProduct.setFalse();
          this.errorMsg = McGod.t('cc.product.error-occurred-the-product-has-not-been-deleted-please-try-again');
        });
    } else {
      this.showDeleteProduct.setFalse();
    }
  }
}
