<div class="row mc-dashboard-wrapper">
    <div class="col-md-12 d-flex flex-column">
      <h1>{{'cc.common.dashboard' | translate}}</h1>
    </div>

    <!--<div class="mc-dashboard-welcome-box d-flex flex-row">
      &lt;!&ndash;<div class="mc-dashboard-info-icon">
        <img [src]="eventsPresent ? 'assets/images/info-red.svg' : 'assets/images/circle-checkmark-icon.svg'">
      </div>&ndash;&gt;
      <div class="mc-dashboard-welcome-message">
        <h3>{{'cc.dashboard.welcome' | translate}}</h3>
        &lt;!&ndash;<p *ngIf="eventsPresent">{{'cc.dashboard.there-are-some-events-that-need-your-attention' | translate}}.</p>
        <p *ngIf="!eventsPresent">{{'cc.dashboard.there-are-no-events-that-need-your-attention' | translate}}.</p>&ndash;&gt;
      </div>
    </div>-->


  <ng-container *ngIf="!dashboardData.isChildOnly"> <!-- PARENT STATISTICS -->

    <div class="mc-dashboard-card-col-wrapper d-flex flex-column pb-5 pt-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/university.svg" class="pr-2"> {{'cc.dashboard.bank-transactions' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.dashboard.overdue' | translate}}" description="{{dashboardData.overdueTransactions ? dashboardData.overdueTransactions : 0}}" [descColor]="dashboardData.overdueTransactions > 0 ? 'red' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.to-be-sent' | translate}}" description="{{dashboardData.toBeSentTransactions ? dashboardData.toBeSentTransactions : 0}}" [descColor]="dashboardData.toBeSentTransactions > 0 ? 'yellow' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.common.view.sent' | translate}}" description="{{dashboardData.sentTransactions}}" [descColor]="dashboardData.sentTransactions > 0 ? 'green' : 'gray'"></mcc-info-label-card>
      </div>
    </div>

    <div class="mc-dashboard-card-col-wrapper col-md-12 d-flex flex-column pb-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/target.svg" class="pr-2"> {{'cc.dashboard.matching-transactions' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.dashboard.manual-matching-needed' | translate}}" description="{{dashboardData.manualMatchingNeededTransactions}}" [descColor]="dashboardData.manualMatchingNeededTransactions > 0 ? 'yellow' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.manually-matched' | translate}}" description="{{dashboardData.manuallyMatchedTransactions}}" [descColor]="dashboardData.manuallyMatchedTransactions > 0 ? 'green' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.automatically-matched' | translate}}" description="{{dashboardData.automaticallyMatchedTransactions}}" [descColor]="dashboardData.automaticallyMatchedTransactions > 0 ? 'green' : 'gray'"></mcc-info-label-card>
      </div>

    </div>

    <div class="mc-dashboard-card-col-wrapper col-md-12 d-flex flex-column pb-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/bell.svg" class="pr-2"> {{'cc.dashboard.dunning-overview' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.dashboard.pdfs-created' | translate}}" description="{{dashboardData.pdfGenerated}}" [descColor]="dashboardData.pdfGenerated > 0 ? 'green' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.emails-sent' | translate}}" description="{{dashboardData.emailsSentParent}}" [descColor]="dashboardData.emailsSentParent > 0 ? 'green' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.balance' | translate}}" description="{{consumersWithBalance ? consumersWithBalance : 0}}"
                             [descColor]="consumersWithBalance > 0 ? 'green' : 'gray'"
                             [isDescriptionLink]="false"
        ></mcc-info-label-card>
      </div>

    </div>

  </ng-container>
  <div class="mc-dashboard-separator-wrapper" *ngIf="!dashboardData.isChildOnly">
    <div class="mc-dashboard-separator"></div>
  </div>


  <ng-container *ngIf="!isRootEntity"> <!-- CHILD STATISTICS -->

    <div class="mc-dashboard-card-col-wrapper col-md-12 d-flex flex-column pb-5 pt-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/status.svg" class="pr-2">{{'cc.common.view.status' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.common.view.active' | translate}}" [icon]="'assets/images/common-icons/active.svg'" description="{{dashboardData.activeConsumers}}" [descColor]="dashboardData.activeConsumers > 0 ? 'green' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.common.view.inactive' | translate}}" [icon]="'assets/images/common-icons/inactive.svg'" description="{{dashboardData.inactiveConsumers}}" [descColor]="'gray'"></mcc-info-label-card>
        <div class="mc-dashboard-card-empty">
        </div>
      </div>

    </div>

    <div class="mc-dashboard-card-col-wrapper col-md-12 d-flex flex-column pb-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/arrows-back-forth.svg" class="pr-2">{{'cc.common.transactions-info' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.factoring.transactions.paid' | translate}}" description="{{dashboardData.paidTransactions ? dashboardData.paidTransactions : 0}}" [descColor]="'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.eb-factoring.rejected' | translate}}" description="{{dashboardData.rejectedTransactions ? dashboardData.rejectedTransactions : 0}}" [descColor]="dashboardData.rejectedTransactions > 0 ? 'red' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.transactions.sent-to-inkasso' | translate}}" description="{{dashboardData.sentToInkassoTransactions ? dashboardData.sentToInkassoTransactions : 0}}" [descColor]="dashboardData.sentToInkassoTransactions > 0 ? 'green' : 'gray'"></mcc-info-label-card>
      </div>

    </div>

    <div class="mc-dashboard-card-col-wrapper col-md-12 d-flex flex-column pb-5">

      <span class="mc-dashboard-card-heading"><img src="assets/images/bell.svg" class="pr-2"> {{'cc.dashboard.dunning-overview' | translate}}</span>
      <div class="mc-dashboard-card-row d-flex">
        <mcc-info-label-card label="{{'cc.dashboard.emails' | translate}}" description="{{dashboardData.emailsSentChild}}" [descColor]="dashboardData.emailsSentChild > 0 ? 'green' : 'gray'"></mcc-info-label-card>
        <mcc-info-label-card label="{{'cc.dashboard.balance' | translate}}" description="{{consumersWithBalance ? consumersWithBalance : 0}}"
                             [descColor]="consumersWithBalance > 0 ? 'green' : 'gray'"
                             [isDescriptionLink]="consumersWithBalance > 0 ? true : false"
                             (eventDescriptionClicked)="onBalanceClicked()"
        ></mcc-info-label-card>
        <div class="mc-dashboard-card-empty">
        </div>
      </div>

    </div>

  </ng-container>

</div>

<mcc-modal1-wrapper [showMcb]="showBalanceMcb" *ngIf="showBalanceMcb.value">
  <app-shared-consumer-balance-part (eventCanceled)="showBalanceMcb.setFalse()"></app-shared-consumer-balance-part>
</mcc-modal1-wrapper>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>


