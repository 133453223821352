
import {Table3Config} from '../extra/table-3-config';

// import {CsrContractPreDefinedGENERATED} from '../_generated/csr-contract-pre-defined-generated.model';

export class CsrContractPreDefinedTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colDurationInMonths', 'How long limited contract will last in months', 'durationInMonths', 'durationInMonths');
    this.addColumn('colEndDate', 'End date of contract not active anymore', 'endDate', 'endDate');
    this.addColumn('colIdCsrDiscount', 'Discount ID', 'idCsrDiscount', 'idCsrDiscount');
    this.addColumn('colIdMcEntity', 'McEntity that created contract', 'idMcEntity', 'idMcEntity');
    this.addColumn('colName', 'name', 'name', 'name');
    this.addColumn('colOriginalAmount', 'Original amount', 'originalAmount', 'originalAmount');
    this.addColumn('colPricePerMonth', 'Price per month', 'pricePerMonth', 'pricePerMonth');
    this.addColumn('colProductsJsonb', 'List of PpProductWithQuantity objects', 'productsJsonb', 'productsJsonb');
    this.addColumn('colReccuringOptionsJsonb', 'List of Reccuring options objects', 'reccuringOptionsJsonb', 'reccuringOptionsJsonb');
    this.addColumn('colStartDate', 'Start date of contract been active', 'startDate', 'startDate');
    this.addColumn('colStatusCd', 'Statuses', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTotalAmount', 'Total amount', 'totalAmount', 'totalAmount');
    this.addColumn('colTotalNetto', 'Total netto', 'totalNetto', 'totalNetto');
    this.addColumn('colVisibilityCd', 'Should it be shown internal or external', 'visibilityCd', 'visibilityCd');

*/
  }
}
