/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrConsumerHistory} from '../models/csr-consumer-history.model';
import {CsrConsumerHistoryService} from '../services/csr-consumer-history.service';
import {ICsrConsumerHistory} from '../_generated/csr-consumer-history.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class CsrConsumerHistoryGENERATED extends BaseObject {


    public static readonly ACTION_TYPE_CD_ADD: string = 'ADD';
    public static readonly ACTION_TYPE_CD_EDIT: string = 'EDIT';
    public static readonly ACTION_TYPE_CD_DELETE: string = 'X';
  public static readonly ACTION_TYPE_CD_LIST__ALL = [
    CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_ADD,
    CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_EDIT,
    CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_DELETE
];

    public static readonly FIELD_SRC_CD_REGULAR: string = 'R';
    public static readonly FIELD_SRC_CD_CUSTOM: string = 'C';
  public static readonly FIELD_SRC_CD_LIST__ALL = [
    CsrConsumerHistoryGENERATED.FIELD_SRC_CD_REGULAR,
    CsrConsumerHistoryGENERATED.FIELD_SRC_CD_CUSTOM
];

    public static readonly FIELD_TYPE_CD_VARCHAR: string = 'VARCHAR';
    public static readonly FIELD_TYPE_CD_BIGINT: string = 'BIGINT';
    public static readonly FIELD_TYPE_CD_TIMESTAMP: string = 'TIMESTAMP';
    public static readonly FIELD_TYPE_CD_TEXT: string = 'TEXT';
    public static readonly FIELD_TYPE_CD_BOOLEAN: string = 'BOOLEAN';
  public static readonly FIELD_TYPE_CD_LIST__ALL = [
    CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_VARCHAR,
    CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BIGINT,
    CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TIMESTAMP,
    CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TEXT,
    CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BOOLEAN
];

  public apiService: CsrConsumerHistoryService;
  public _rawJson: ICsrConsumerHistory;
    id: number = 0;
  actionTypeCd: string;
  fieldLabel = '';
  fieldName = '';
  fieldSrcCd: string;
  fieldTypeCd: string;
  idCsrConsumer: number;
  newBigintValue: number;
  newBooleanValue: boolean;
  newDatetimeValue: number;
  newTextValue = '';
  newVarcharValue = '';
  oldBigintValue: number;
  oldBooleanValue: boolean;
  oldDatetimeValue: number;
  oldTextValue = '';
  oldVarcharValue = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  version: number;
  createdByUser: string;

  public properties: string[] = ['id', 'actionTypeCd', 'content', 'fieldLabel', 'fieldName', 'fieldSrcCd', 'fieldTypeCd', 'idCsrConsumer', 'newBigintValue', 'newBooleanValue', 'newDatetimeValue', 'newTextValue', 'newVarcharValue', 'oldBigintValue', 'oldBooleanValue', 'oldDatetimeValue', 'oldTextValue', 'oldVarcharValue', 'sysCreatedDatetime', 'sysCreatedUserId', 'version', 'createdByUser'];
  public propertiesRegular: string[] = ['id', 'actionTypeCd', 'content', 'fieldLabel', 'fieldName', 'fieldSrcCd', 'fieldTypeCd', 'idCsrConsumer', 'newBigintValue', 'newBooleanValue', 'newDatetimeValue', 'newTextValue', 'newVarcharValue', 'oldBigintValue', 'oldBooleanValue', 'oldDatetimeValue', 'oldTextValue', 'oldVarcharValue', 'sysCreatedDatetime', 'sysCreatedUserId', 'version', 'createdByUser'];
  public propertiesSpecial: string[] = [];



  public static getActionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_ADD, McGod.t('Add'));
    list.add(CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_EDIT, McGod.t('Edit'));
    list.add(CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_DELETE, McGod.t('Delete'));


    return list;
  }

  public static getActionTypeCdLabel(aCd: string): string {
    const list = CsrConsumerHistoryGENERATED.getActionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getFieldSrcCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerHistoryGENERATED.FIELD_SRC_CD_REGULAR, McGod.t('Regular Field'));
    list.add(CsrConsumerHistoryGENERATED.FIELD_SRC_CD_CUSTOM, McGod.t('Custom Field'));


    return list;
  }

  public static getFieldSrcCdLabel(aCd: string): string {
    const list = CsrConsumerHistoryGENERATED.getFieldSrcCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getFieldTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_VARCHAR, McGod.t('Varchar'));
    list.add(CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BIGINT, McGod.t('Integer'));
    list.add(CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TIMESTAMP, McGod.t('DateTime'));
    list.add(CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TEXT, McGod.t('Text'));
    list.add(CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BOOLEAN, McGod.t('Boolean'));


    return list;
  }

  public static getFieldTypeCdLabel(aCd: string): string {
    const list = CsrConsumerHistoryGENERATED.getFieldTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrConsumerHistory {
    console.error('MTCN-ERROR: Not Implemented: CsrConsumerHistory::createNewInstance(). Add this method to final class and return new CsrConsumerHistory();');
    throw new Error('Cannot CsrConsumerHistory::createNewInstance(). Add this method to final class and return new CsrConsumerHistory();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrConsumerHistory): CsrConsumerHistory {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrConsumerHistoryService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrConsumerHistory AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getActionTypeCdLabel(): string {
    return CsrConsumerHistoryGENERATED.getActionTypeCdLabel(this.actionTypeCd);
  }


 public  isActionTypeCdADD(): boolean {
    const result = (this.actionTypeCd === CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_ADD);

    return result;
  }

 public  isActionTypeCdEDIT(): boolean {
    const result = (this.actionTypeCd === CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_EDIT);

    return result;
  }

 public  isActionTypeCdDELETE(): boolean {
    const result = (this.actionTypeCd === CsrConsumerHistoryGENERATED.ACTION_TYPE_CD_DELETE);

    return result;
  }


  public getFieldSrcCdLabel(): string {
    return CsrConsumerHistoryGENERATED.getFieldSrcCdLabel(this.fieldSrcCd);
  }


 public  isFieldSrcCdREGULAR(): boolean {
    const result = (this.fieldSrcCd === CsrConsumerHistoryGENERATED.FIELD_SRC_CD_REGULAR);

    return result;
  }

 public  isFieldSrcCdCUSTOM(): boolean {
    const result = (this.fieldSrcCd === CsrConsumerHistoryGENERATED.FIELD_SRC_CD_CUSTOM);

    return result;
  }


  public getFieldTypeCdLabel(): string {
    return CsrConsumerHistoryGENERATED.getFieldTypeCdLabel(this.fieldTypeCd);
  }


 public  isFieldTypeCdVARCHAR(): boolean {
    const result = (this.fieldTypeCd === CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_VARCHAR);

    return result;
  }

 public  isFieldTypeCdBIGINT(): boolean {
    const result = (this.fieldTypeCd === CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BIGINT);

    return result;
  }

 public  isFieldTypeCdTIMESTAMP(): boolean {
    const result = (this.fieldTypeCd === CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TIMESTAMP);

    return result;
  }

 public  isFieldTypeCdTEXT(): boolean {
    const result = (this.fieldTypeCd === CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_TEXT);

    return result;
  }

 public  isFieldTypeCdBOOLEAN(): boolean {
    const result = (this.fieldTypeCd === CsrConsumerHistoryGENERATED.FIELD_TYPE_CD_BOOLEAN);

    return result;
  }


  
}
