import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CsrConsumer, McDateUtils, McEntityConsumerAttribute} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-360-profile-part',
  templateUrl: './mc-consumer-consumer-360-profile-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-profile-part.scss']
})
export class McConsumerConsumer360ProfilePartComponent implements OnInit {

  csrConsumer = new CsrConsumer();
  consumerBirthday!: string;
  sepaMandateDate!: string;
  consumerAttributes: any[] = [];

  constructor(
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.actLoad();
  }

  actLoad() {
    this.route.paramMap.subscribe((res) => {
      this.csrConsumer.apiLoadingFlg = true
        // @ts-ignore
      this.csrConsumer.loadById(+res.get('id'), () => {
          this.csrConsumer.apiLoadingFlg = false;
          this.consumerBirthday = this.csrConsumer.birthday ? McDateUtils.newFormatDateString(this.csrConsumer.birthday) : '';
          this.sepaMandateDate = this.csrConsumer.sepaMandanteDate ? McDateUtils.newFormatDateString(this.csrConsumer.sepaMandanteDate) : '';
          this.consumerAttributes = this.csrConsumer.entityConsumerAttributes.items.filter((item: any) => {
            if (item.value) {
              return item;
            }
          });
        });
    });
  }

  showCustomAttribute(customAttribute: McEntityConsumerAttribute) {
    let returnValue;
    if (customAttribute.typeCd === McEntityConsumerAttribute.TYPE_CD_DATE && customAttribute.value !== '') {
      returnValue = McDateUtils.formatStringToString(customAttribute.value, 'DD.MM.YYYY');
    } else if (customAttribute.typeCd === McEntityConsumerAttribute.TYPE_CD_BOOLEAN && customAttribute.value !== '') {
      returnValue = customAttribute.value === 'false' || customAttribute.value === '0' ? 'No' : 'Yes';
    } else {
      returnValue = customAttribute.value;
    }

    return returnValue;
  }


}


