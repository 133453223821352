<h3>Payout files</h3>

<div class="row">
  <lib-mc-loader [showLoader]="mcBillingPayoutFileFeCtdList.apiLoadingFlg || payoutFile.apiLoadingFlg"></lib-mc-loader>

  <div class="col-md-12">
    <div class="mc-filter fancy-label">
        <mcc-fi-textbox [isClearEnabled]="true" name="txbSearchTerm" (eventEnterClicked)="actLoad()" placeholder="{{'cc.payout-file.search-by-id-number' | translate}}"
                        [mcForm]="mcForm"></mcc-fi-textbox>
        <div class="wide-date">
          <mcc-fi-date-picker
            datePickerViewType="RANGE_SELECTION"
            [mcForm]="mcForm"
            name="ctrlPayoutDateFromTo"
            label="Payout date from-to"
            (eventFromDate)="onPayoutDateFrom($event)"
            (eventToDate)="onPayoutDateTo($event)"
            [allowManualEntryFlg]="false"
            monthsDisplayed="2"
            [clearDateRange]="clearDateRangeSubject"
          >
          </mcc-fi-date-picker>
        </div>

        <div class="wide-date">
          <mcc-fi-date-picker
            datePickerViewType="RANGE_SELECTION"
            [mcForm]="mcForm"
            name="ctrlCreateDateFromTo"
            label="Created date from-to"
            (eventFromDate)="onCreatedDateFrom($event)"
            (eventToDate)="onCreatedDateTo($event)"
            [allowManualEntryFlg]="false"
            monthsDisplayed="2"
            [clearDateRange]="clearDateRangeSubject"
          >
          </mcc-fi-date-picker>
        </div>

        <mcc-multiselect-filter [optionVll]="createdByVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                label="Created by" name="selCreatedBy" (eventSelectedItems)="onSelectCreatedBy($event)">
        </mcc-multiselect-filter>

        <mcc-multiselect-filter [optionVll]="payoutType" [clearedAllFilters]="clearedAllMultipleFilters"
                                label="{{'cc.payout.payout-type' | translate}}" name="selTypeCd"
                                (eventSelectedItems)="onPayoutType($event)">
        </mcc-multiselect-filter>

      <!--Bank filter-->
      <ng-container *ngIf="bankVll.items.length">
        <mcc-multiselect-filter [optionVll]="bankVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                [label]="'cc.ebics.bank-name' | translate" name="selBankCd" (eventSelectedItems)="onSelectedBank($event)">
        </mcc-multiselect-filter>
      </ng-container>

      <!--<div class="multi-select-field">
        <mcc-multiselect-filter [optionVll]="statusVll" [clearedAllFilters]="clearedAllMultipleFilters"
                                label="Status" name="selStatus" (eventSelectedItems)="onSelectStatus($event)">
        </mcc-multiselect-filter>
      </div>-->

      <button class="mc-link mc-filter-clear-button filter-clear-top-right"
              (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" [ngClass]="isFilterButtonDisabled ? 'mc-disable-button' : ''" (click)="actLoad()" [disabled]="isFilterButtonDisabled">
        <i class="fas fa-filter mr-2"></i>{{'cc.common.view.filter' | translate}}</button>

    </div>
  </div>
  <div class="col-md-12">
    <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>
  </div>

  <div class="col-md-12 pt-3 d-flex">

    <!--<div ngbDropdown class="d-inline-block ml-auto ">
      <button class="btn-outline-new" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngIf="mcGod.userHasMcBillingOutStatementAutoImportBillingItemsPermission()" ngbDropdownItem
                (click)="onActionsDropdownClick('CREATE')"><i class="fas fa-plus"></i>Create
        </button>
      </div>
    </div>-->

    <button class="system-action-btn mt-2 ml-auto" (click)="onCreatePayoutFile()" *ngIf="mcGod.userHasMcBillingOutPayoutFilesCreatePermission()">
      <i class="fas fa-plus-circle"></i>{{"cc.payout.create-payout-file" | translate}}
    </button>

  </div>

  <div class="col-md-12">
    <mcc-message [successMsg]="" [errorMsg]="mcBillingPayoutFileFeCtdList.apiErrorMessage"></mcc-message>
  </div>

  <div class="col-md-12 mb-3">
    <mcc-fi-table-3 [objectList]="mcBillingPayoutFileFeCtdList"
                    [table3Config]="mcBillingPayoutFileFeCtdTable3Config"
                    (eventItemAction)="onTableItemAction($event)"
                    (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
  </div>

  <mcc-modal1-wrapper modalSizeCd="small" [heightAutoFlg]="true" [showMcb]="showDeleteMcb" *ngIf="showDeleteMcb.value" (eventCanceled)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">
    <div class="content-wrapper">
      <div class="content-header">
        <h3>Delete payout file</h3>
      </div>
      <div class="content-body">
        <div class="d-flex align-items-center">
          <h1 class="text-orange-color m-0 pr-3"><i class="far fa-question-circle"></i></h1>
          <span [innerHTML]="dialogDescription"></span>
        </div>
      </div>
      <div class="content-footer d-flex">
        <button class="btn btn-secondary ml-auto" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Cancel)">Cancel</button>
        <button class="btn btn-primary" (click)="onDeletePayoutFileActionButtonClicked(globalActions.Delete)"><i class="far fa-trash-alt"></i> Delete</button>
      </div>
    </div>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddEditPayoutFileMcb" *ngIf="showAddEditPayoutFileMcb.value" (eventCanceled)="onAddEditPayoutFileCancelEmit()">
    <mc-out-billing-payout-file-add-edit-part (eventCanceled)="onAddEditPayoutFileCancelEmit()" (eventSaved)="onCreatePayoutFileSaveEmit()"></mc-out-billing-payout-file-add-edit-part>
  </mcc-modal1-wrapper>

</div>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showUploadFileMcb" *ngIf="showUploadFileMcb.value">
  <lib-upload-file (eventUploaded)="onUploadFile($event)"></lib-upload-file>
</mcc-modal1-wrapper>

