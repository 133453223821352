<ng-container *ngIf="errorMessage">
  <mcc-message class="col-md-12" [errorMsg]="errorMessage"></mcc-message>
</ng-container>

<div class="card">
  <h2 class="title-medium-dark mb-large">{{'cc.my-factoring.payment-method' | translate}}</h2>
  <div class="flex" style="gap: 10px">
    <mcc-fi-select [optionsVll]="paymentMethodOptionsVll"
                   [value]="paymentMethodDefaultValue"
                   name="ctrlPaymentMethod"
                   [mcForm]="mcForm"
                   label="{{'cc.my-factoring.payment-method' | translate}}*"
                   (eventValueChanged)="onPaymentMethodValueChanged($event)">
    </mcc-fi-select>
    <mcc-fi-select *ngIf="paymentType === 'TRANSFER'"
                   [optionsVll]="transferMoneyOptionsVll"
                   label="{{'cc.consumer.transfer-money-to' | translate}}"
                   name="ctrlMoneyTransfer"
                   [mcForm]="mcForm"
                   (eventValueChanged)="onTransferMoneyValueChanged($event)"
                   [validRequiredFlg]="selectedTab > 2">
    </mcc-fi-select>
    <mcc-fi-select *ngIf="paymentType === 'TRANSFER' && mcBankAccountsVll.items.length > 0"
                   [optionsVll]="mcBankAccountsVll"
                   label="{{'cc.common.edit.bank-account' | translate}}"
                   name="ctrlMoneyTransferBank"
                   [mcForm]="mcForm"
                   (eventValueChanged)="onBankAccountValueChanged($event)"
                   [validRequiredFlg]="selectedTab > 2">
    </mcc-fi-select>
  </div>

  <ng-container *ngIf="paymentType === 'TRANSFER'">
    <ng-container *ngIf="showBankInfoForTransferMoney && mcBankAccount">
      <div class="card dialog-header-card">
        <div class='dialog-row-wrapper dialog-header'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
            <span class='dialog-col-value'>{{ mcBankAccount.bankName ? mcBankAccount.bankName : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
            <span class='dialog-col-value'>{{ mcBankAccount.accountOwner ? mcBankAccount.accountOwner : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
            <span class='dialog-col-value'>{{ mcBankAccount.iban ? mcBankAccount.iban : '-' }}</span>
          </div>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
            <span class='dialog-col-value'>{{ mcBankAccount.bic ? mcBankAccount.bic : '-' }}</span>
          </div>
        </div>
      </div>
      </ng-container>
  </ng-container>

  <ng-container *ngIf="paymentType === 'DIRECT_DEBIT'">
    <div class="card hasBank">
      <span [innerHtml]="(primaryBankAccount ? iconSucces : iconFailed) | safeHtml"></span>
      <span class="hasBank_text">{{ (primaryBankAccount ? "cc.consumer360.has-bank-account" : "cc.consumer360.has-not-bank-account") | translate }}</span>
    </div>
    <div class="mt-large mb-large ml-small" style="height: 20px;" *ngIf="primaryBankAccount">
      <mcc-fi-checkbox [mcForm]="mcForm" label="{{'cc.consumer.use-different-bank-account' | translate}}" name="ctrlUseDiffBankAccount" (eventValueChanged)="onDiffBankAccountChanged($event)"></mcc-fi-checkbox>
    </div>
    <div *ngIf="!primaryBankAccount || (primaryBankAccount && bankInfoForm)">
      <h2 class="title-medium-dark mb-large mt-large">{{'cc.common.bank-information' | translate}}</h2>
      <div class="flex bank">
        <mcc-fi-textbox label="{{'cc.common.account-owner' | translate}}" name="ctrlAccountOwner" [validRequiredFlg]="true" [mcForm]="mcForm" [newStyleInput]="true"></mcc-fi-textbox>
        <mcc-fi-textbox label="{{'cc.common.bank-name' | translate}}" name="ctrlBankName" [validRequiredFlg]="true" [mcForm]="mcForm" [newStyleInput]="true"></mcc-fi-textbox>
        <mcc-fi-textbox label="{{'cc.common.iban' | translate}}" name="ctrlIban" [validRequiredFlg]="true" [mcForm]="mcForm" [newStyleInput]="true"></mcc-fi-textbox>
        <mcc-fi-textbox label="{{'cc.consumers.edit.bic' | translate}}" name="ctrlBic" [validRequiredFlg]="true" [mcForm]="mcForm" [newStyleInput]="true"></mcc-fi-textbox>
      </div>
      <h2 class="title-medium-dark mb-large">{{'cc.consumer360.sepa-mandate' | translate}}</h2>
      <div class="card hasBank">
        <span [innerHtml]="iconGenerate | safeHtml"></span>
        <span class="hasBank_text">{{ "cc.consumer360.sepa-mandate-info" | translate }}</span>
      </div>
      <div>
        <button class="primary-btn mt-small mb-small generate-button" (click)="generateSepaMandate()">
          {{ 'cc.consumer360.generate-sepa-mandate' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
