/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {PpOrder} from '../models/pp-order.model';
import {PpOrderList} from '../models/pp-order-list.model';
import {PpOrderFilter} from '../models/pp-order-filter.model';
import {PpOrderService} from '../services/pp-order.service';
import {IPpOrder} from '../_generated/pp-order.interface';
import {McGod} from "../extra/mc-god.service";

export class PpOrderListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'dueDate', 'idReceiverMcEntity', 'idRefReceiver', 'idSenderMcEntity', 'orderNumber', 'reason', 'refTypeCd', 'sendingDate', 'servicePeriodFrom', 'servicePeriodTo', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'typeCd', 'version', 'currency', 'discount', 'entityName', 'excludeDiscountedItems', 'orderItems', 'recipient', 'statusCodes'];
  public items: PpOrder[] = [];
  public apiService: PpOrderService;
 // protected tempObjList: PpOrderList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PpOrderList {
    console.error('MTCN-ERROR: Not Implemented: PpOrderList::createNewInstance(). Add this method to final class and return new PpOrderList();');
    throw new Error('Cannot PpOrderList::createNewInstance(). Add this method to final class and return new PpOrderList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IPpOrder[]): PpOrderList {
    const  listObj: PpOrderList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: PpOrder = PpOrder.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): PpOrderList {
    const  listObj: PpOrderList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpOrderService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IPpOrder[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: PpOrder = PpOrder.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aPpOrderFilter: PpOrderFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aPpOrderFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): PpOrder {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new PpOrder();
    }
  }

  public replaceItem(newItem: PpOrder) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

}

