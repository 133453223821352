<h3>{{'cc.common.import-mappings' | translate}}</h3>

<div>
  <lib-mc-loader [showLoader]="csrMappingList.apiLoadingFlg"></lib-mc-loader>

  <div *ngIf="csrMappingList.apiErrorFlg">
    Error: {{csrMappingList.apiErrorMessage}}
  </div>

  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin"
       ngbTooltip="{{'cc.consumers.search-by-mapping-name' | translate}}" placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm"
                    (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selUser"
                   placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.users.view.user' | translate}}"
                   [optionsVll]="mcUsersVll"></mcc-fi-select>

    <button class="mc-link mc-filter-clear-button filter-clear-top-right"
            (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

  </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>


  <div *ngIf="csrMappingList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="csrMappingList"
                    [table3Config]="csrMappingTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
  </div>

  <mcc-dialog-box *ngIf="showUnShareMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.unshare" | translate}} "{{csrMapping.mappingName}}"' name="unshare-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-unshare" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxUnshareMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-dialog-box *ngIf="showShareMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.share" | translate}} "{{csrMapping.mappingName}}"' name="share-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-share" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxShareMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-dialog-box *ngIf="showCopyMappingMcb.value" [enableCloseButtonFlg]="false"
                  title='"{{"cc.import-mappings.copy" | translate}} "{{csrMapping.mappingName}}"' name="copy-mapping"
                  description='{{"cc.import-mappings.are-you-sure-you-want-to-copy" | translate}} "{{csrMapping.mappingName}}" {{"cc.consumers.mapping" | translate}}?'
                  [buttonsKvm]="sharingUnsharingCopyButtonKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxCopyMappingActionButtonClicked($event)"></mcc-dialog-box>

  <mcc-modal1-wrapper [showMcb]="showMappingProfileMcb" *ngIf="showMappingProfileMcb.value">
    <mc-consumer-consumer-mapping-profile-part [csrMappingId]="csrMappingId"
    (eventEditMapping)="onEditMapping()" (eventClose)="showMappingProfileMcb.setFalse()" (eventShare)="shareMapping()" (eventUnshare)="unShareMapping()" (eventCopy)="actCopyMapping($event)"></mc-consumer-consumer-mapping-profile-part>
  </mcc-modal1-wrapper>
  <mcc-modal1-wrapper [showMcb]="showMappingEditMcb" *ngIf="showMappingEditMcb.value">
    <mc-consumer-consumer-mapping-edit-part [csrMappingId]="csrMappingId" [csrMapping]="csrMapping"
    (eventSaved)="onUpdatedMapping()" (eventClose)="onCloseMapping()"></mc-consumer-consumer-mapping-edit-part>
  </mcc-modal1-wrapper>

</div>

<router-outlet></router-outlet>

