import {Component, OnInit, Input} from '@angular/core';
import {BaseDirective, CsrConsumer, CsrConsumerHistoryService, McConsumersService, SortCriteriaList} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'lib-mc-consumers-details-history',
  templateUrl: './mc-consumers-details-history.component.html'
})
export class McConsumersDetailsHistoryComponent extends BaseDirective implements OnInit {

  isContentLoaded!: boolean;
  tableContent: any;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  showLoader!: boolean;
  public csrConsumer = new CsrConsumer();


  @Input() csrConsumerId!: number;
  @Input() consumerName!: any;

  constructor(
    private consumersService: McConsumersService, private csrConsumerHistoryService: CsrConsumerHistoryService
  ) {
    super();
  }

  ngOnInit() {
    if (this.csrConsumerId > 0) {
      this.showLoader = true;
      this.responseMessage = false;
      this.csrConsumerHistoryService.getByConsumerId(this.csrConsumerId, 0, 100, new SortCriteriaList())
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.isContentLoaded = true;
          this.showLoader = false;
          if (response?['content'].length > 0 : '') {
            response['content'].forEach((item: any) => {
              item.attributeName = item.fieldName.replace(/_/g, ' ')
              switch (item.fieldTypeCd) {
                case "BIGINT":
                  item.oldValue = item.oldBigintValue
                  item.newValue = item.newBigintValue
                  break;
                case "VARCHAR":
                  item.oldValue = item.oldVarcharValue
                  item.newValue = item.newVarcharValue
                  break;
                case "DATETIME":
                  item.oldValue = item.oldDatetimeValue
                  item.newValue = item.newDatetimeValue
                  break;
                case "BOOLEAN":
                  item.oldValue = item.oldBooleanValue
                  item.newValue = item.newBooleanValue
                  break;
                case "TEXT":
                  item.oldValue = item.oldTextValue
                  item.newValue = item.newTextValue
                  break;
                default:
                  console.warn("Field item.fieldTypeCd is empty or has unsupported value")
                  break;
              }

              item.modificationDate = item.sysCreatedDatetime
              item.systemUser = item.sysCreatedUserId
            })
          }

          this.tableContent = Object.assign({}, {
            ...response,
            _name: 'consumers_history',
            isLoaded: true
          });
          if (response?['content'].length === 0 : '') {
            this.message = '';
            this.type = '';
            this.responseMessage = true;
            this.setNotificationMessage(this.message, this.type);
          }
        });
    }
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

}
