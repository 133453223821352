import {PmTransaction} from './pm-transaction.model';
import {PmTransactionListGENERATED} from '../_generated/pm-transaction-list-generated.model';
import {WebFile} from '../models/web-file.model';
import * as fileSaver from "file-saver";
import {PmTransactionFilter} from "../models/pm-transaction-filter.model";

export class PmTransactionList extends PmTransactionListGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new PmTransactionList();
  }

  actExportToCsv(filter: PmTransactionFilter, webfile: WebFile){
    this.apiService.exportToFileFormat('csv', filter).subscribe(
      (res) => {
        webfile.getFileByIdAndType(res, WebFile.TYPE_CD_PM_TRANSACTION_CSV_EXPORT, () => {
          webfile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, `${webfile.originalFilename}`);
          });
        });
      }
    );
  }

  public getPotentialMatches(
    aFilterObj: any,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
  ): void {
    this.setApiLoading();
    this.apiService
      .getPotentialMatches(
        aFilterObj,
        this.pageNum,
        this.pageItemsPerPageCount,
        this.sortCriteria
      )
      .subscribe(
        (responseJson: any) => {
          this.totalItemsCount = responseJson.totalElements;
          this.setApiSuccess();
          this.populateFromJsonPageable(responseJson);
          this.processPostLoad();
          this.processApiSuccess(responseJson, onSuccessCallback);
        },
        (errorResponse: any) => {
          this.setApiError();
          this.processApiError(errorResponse, onErrorCallback);
        }
      );
  }

  // ---------------------------------------------------------------------
}
