import {Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CsrRecurringDetails, McForm, McValueLabelList} from '@miticon-ui/mc-core';
export enum PaymentType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY'
}
@Component({
  selector: 'mc-consumer-consumer-360-create-contract-payments-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-payments-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-payments-part.component.scss']
})

export class McConsumerConsumer360CreateContractPaymentsPartComponent implements OnInit, DoCheck {

  paymentsVll!: McValueLabelList;
  selectedPaymentCd!: string;
  daysVll!: McValueLabelList;
  monthDaysVll = new McValueLabelList();
  quarterlyVll = new McValueLabelList();
  halfYearlyVll = new McValueLabelList();
  yearlyVll = new McValueLabelList();
  monthYearVll!: McValueLabelList;
  @Input() mcForm!: McForm;
  recurringDetails = new CsrRecurringDetails();
  paymentType = PaymentType;
  @Output() emitValidPaymentTab = new EventEmitter();


  ngOnInit() {
    this.paymentsVll = CsrRecurringDetails.getTypeCdVll();
    this.daysVll = new McValueLabelList();
    this.monthYearVll = new McValueLabelList();
    this.daysVll = this.recurringDetails.getWeekDaysVll()
    this.monthDaysVll = this.recurringDetails.getMonthDaysVll();
    this.quarterlyVll = this.recurringDetails.getQuarterlyVll();
    this.halfYearlyVll = this.recurringDetails.getHalfYearlyVll();
    this.yearlyVll = this.recurringDetails.getYearlyVll();
  }

  onPaymentChangeEmit(paymentCd: string) {
    this.selectedPaymentCd = paymentCd;
    this.setPaymentsDayMonthDefaultOptions();
  }

  setPaymentsDayMonthDefaultOptions() {
    if (this.mcForm.getControl('ctrlDays')) {
      this.mcForm.getControl('ctrlDays')?.get('value')?.setValue('');
    }
    if (this.mcForm.getControl('ctrlMonthYear')) {
      this.mcForm.getControl('ctrlMonthYear')?.get('value')?.setValue('');
    }
  }

  ngDoCheck(): void {
    const paymentValue = this.mcForm.getControl('ctrlPayment')?.value.value;
    const daysValue = this.mcForm.getControl('ctrlDays')?.value.value;
    const monthYearValue = this.mcForm.getControl('ctrlMonthYear')?.value.value;

    const isValid = paymentValue
      ? (paymentValue === PaymentType.WEEKLY || paymentValue === PaymentType.MONTHLY)
        ? !!daysValue && daysValue !== ''
        : !!daysValue && daysValue !== '' && !!monthYearValue && monthYearValue !== ''
      : false;
    this.emitValidPaymentTab.emit(isValid);
  }
}
