import { AppSharedConsumerBalancePartComponent } from './components/app-shared-consumer-balance-part/app-shared-consumer-balance-part.component';
import { AppSharedTransactionPayoutItemsComponent } from './pages/app-shared-transaction-payout-items/app-shared-transaction-payout-items.component';
import { AppSharedTransactionBillingItemsComponent } from './pages/app-shared-transaction-billing-items/app-shared-transaction-billing-items.component';
import { AppSharedRolesCreateRoleFromTemplateComponent } from './components/app-shared-roles-create-role-from-template/app-shared-roles-create-role-from-template.component';
import { AppSharedTransactionsPauseDaysPartComponent } from './components/app-shared-transactions-pause-days-part/app-shared-transactions-pause-days-part.component';
import { AppSharedPauseTransactionsComponent } from './components/app-shared-pause-transactions/app-shared-pause-transactions.component';
import { AppSharedFactoringExportPartComponent } from './components/app-shared-factoring-export-part/app-shared-factoring-export-part.component';
import { AppSharedProcessesActionResendPartComponent } from './components/app-shared-processes-action-resend-part/app-shared-processes-action-resend-part.component';
import { AppSharedTransactionAddManuallyPartComponent } from './components/app-shared-transaction-add-manually-part/app-shared-transaction-add-manually-part.component';
import { AppSharedProcessesActionDetailPartComponent } from './components/app-shared-processes-action-detail-part/app-shared-processes-action-detail-part.component';
import { AppSharedChangeCollectionDatePartComponent } from './components/app-shared-change-collection-date-part/app-shared-change-collection-date-part.component';
import { AppSharedFactoringSelectContractTypePartComponent } from './components/app-shared-factoring-select-contract-type-part/app-shared-factoring-select-contract-type-part.component';
import { AppSharedTransactionPaymentAddEditPartComponent } from './components/app-shared-transaction-payment-add-edit-part/app-shared-transaction-payment-add-edit-part.component';
import { AppSharedCustomAttributeAddEditPartComponent } from './components/app-shared-custom-attribute-add-edit-part/app-shared-custom-attribute-add-edit-part.component';
import { AppSharedTransactionAddEditPartComponent } from './components/app-shared-transaction-add-edit-part/app-shared-transaction-add-edit-part.component';
import { AppSharedFactoringEnableDisablePartComponent } from './components/app-shared-factoring-enable-disable-part/app-shared-factoring-enable-disable-part.component';
import { AppSharedFactoringSelectEntityPartComponent } from './components/app-shared-factoring-select-entity-part/app-shared-factoring-select-entity-part.component';
import { AppSharedTransactionProfilePartComponent } from './components/app-shared-transaction-profile-part/app-shared-transaction-profile-part.component';
import { AppSharedTransactionAdjustmentAddEditPartComponent } from './components/app-shared-transaction-adjustment-add-edit-part/app-shared-transaction-adjustment-add-edit-part.component';
import { AppSharedFactoringRequestTypeAddPartComponent } from './components/app-shared-factoring-request-type-add-part/app-shared-factoring-request-type-add-part.component';
import { AppSharedBlacklistRemovePartComponent } from './components/app-shared-blacklist-remove-part/app-shared-blacklist-remove-part.component';
import { AppSharedFactoringRejectContractPartComponent } from './components/app-shared-factoring-reject-contract-part/app-shared-factoring-reject-contract-part.component';
import { AppSharedFactoringRequestTypeAddEditModalPartComponent } from './components/app-shared-factoring-request-type-add-edit-modal-part/app-shared-factoring-request-type-add-edit-modal-part.component';
import { AppSharedFactoringRequestTypeAddEditPartComponent } from './components/app-shared-factoring-request-type-add-edit-part/app-shared-factoring-request-type-add-edit-part.component';
import { AppSharedFactoringAddEditModalPartComponent } from './components/app-shared-factoring-add-edit-modal-part/app-shared-factoring-add-edit-modal-part.component';
import { AppSharedFactoringAddEditPartComponent } from './components/app-shared-factoring-add-edit-part/app-shared-factoring-add-edit-part.component';
import { AppSharedFactoringProfileModalPartComponent } from './components/app-shared-factoring-profile-modal-part/app-shared-factoring-profile-modal-part.component';
import { AppSharedFactoringProfilePartComponent } from './components/app-shared-factoring-profile-part/app-shared-factoring-profile-part.component';
import { AppSharedConsumerBlacklistRemovePartComponent } from './components/app-shared-consumer-blacklist-remove-part/app-shared-consumer-blacklist-remove-part.component';
import { AppSharedConsumerBlacklistAddPartComponent } from './components/app-shared-consumer-blacklist-add-part/app-shared-consumer-blacklist-add-part.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedRoutingModule } from './app-shared-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from '@ngx-translate/core';
import { AppSharedFactoringSelectContractTypeAndEntityComponent } from './components/app-shared-factoring-select-contract-type-and-entity/app-shared-factoring-select-contract-type-and-entity.component';
import { AppSharedGoBackComponent } from './components/app-shared-go-back/app-shared-go-back.component';
import { AppSharedFilterWrapperComponent } from './components/app-shared-filter-wrapper/app-shared-filter-wrapper.component';
import { McCardComponent } from './components/app-shared-card/app-shared-card.component';
import { AppSharedMatBottomSheetComponent } from './components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatIconModule} from "@angular/material/icon";
import {InfoBarMessageComponent} from "./components/info-bar-message/info-bar-message.component";
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";

@NgModule({
  declarations: [
AppSharedConsumerBalancePartComponent,
AppSharedTransactionPayoutItemsComponent,
AppSharedTransactionBillingItemsComponent,
AppSharedRolesCreateRoleFromTemplateComponent,
AppSharedTransactionsPauseDaysPartComponent,
AppSharedPauseTransactionsComponent,
AppSharedFactoringExportPartComponent,
AppSharedProcessesActionResendPartComponent,
AppSharedProcessesActionDetailPartComponent,
AppSharedTransactionAddManuallyPartComponent,
    AppSharedFactoringSelectContractTypePartComponent,
AppSharedChangeCollectionDatePartComponent,
AppSharedTransactionPaymentAddEditPartComponent,
AppSharedFactoringSelectContractTypePartComponent,
AppSharedCustomAttributeAddEditPartComponent,
AppSharedTransactionAddEditPartComponent,
AppSharedFactoringEnableDisablePartComponent,
AppSharedFactoringSelectEntityPartComponent,
AppSharedTransactionProfilePartComponent,
AppSharedTransactionAdjustmentAddEditPartComponent,
AppSharedFactoringRequestTypeAddPartComponent,
AppSharedBlacklistRemovePartComponent,
AppSharedFactoringRejectContractPartComponent,
AppSharedFactoringRequestTypeAddEditModalPartComponent,
AppSharedFactoringRequestTypeAddEditPartComponent,
AppSharedFactoringAddEditModalPartComponent,
AppSharedFactoringAddEditPartComponent,
AppSharedFactoringProfileModalPartComponent,
AppSharedFactoringProfilePartComponent,
    AppSharedConsumerBlacklistRemovePartComponent,
    AppSharedConsumerBlacklistAddPartComponent,
    AppSharedFactoringSelectContractTypeAndEntityComponent,
    AppSharedGoBackComponent,
    AppSharedFilterWrapperComponent,
    McCardComponent,
    AppSharedMatBottomSheetComponent,
    InfoBarMessageComponent
  ],
  imports: [
    CommonModule,
    AppSharedRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    TranslateModule,
    MatBottomSheetModule,
    MatIconModule,
    SafeHtmlModule
  ],
    exports: [
        AppSharedConsumerBalancePartComponent,
        AppSharedTransactionPayoutItemsComponent,
        AppSharedTransactionBillingItemsComponent,
        AppSharedRolesCreateRoleFromTemplateComponent,
        AppSharedFactoringExportPartComponent,
        AppSharedTransactionsPauseDaysPartComponent,
        AppSharedPauseTransactionsComponent,
        AppSharedProcessesActionResendPartComponent,
        AppSharedTransactionAddManuallyPartComponent,
        AppSharedProcessesActionDetailPartComponent,
        AppSharedChangeCollectionDatePartComponent,
        AppSharedFactoringSelectContractTypePartComponent,
        AppSharedTransactionPaymentAddEditPartComponent,
        AppSharedCustomAttributeAddEditPartComponent,
        AppSharedTransactionAddEditPartComponent,
        AppSharedFactoringEnableDisablePartComponent,
        AppSharedFactoringSelectEntityPartComponent,
        AppSharedTransactionProfilePartComponent,
        AppSharedTransactionAdjustmentAddEditPartComponent,
        AppSharedBlacklistRemovePartComponent,
        AppSharedFactoringRequestTypeAddPartComponent,
        AppSharedFactoringRejectContractPartComponent,
        AppSharedFactoringRequestTypeAddEditModalPartComponent,
        AppSharedFactoringRequestTypeAddEditPartComponent,
        AppSharedFactoringAddEditModalPartComponent,
        AppSharedFactoringAddEditPartComponent,
        AppSharedFactoringProfileModalPartComponent,
        AppSharedFactoringProfilePartComponent,
        AppSharedConsumerBlacklistRemovePartComponent,
        AppSharedConsumerBlacklistAddPartComponent,
        AppSharedRoutingModule,
        FormsModule,
        AppSharedFactoringSelectContractTypeAndEntityComponent,
        AppSharedGoBackComponent,
        AppSharedFilterWrapperComponent,
        McCardComponent,
        InfoBarMessageComponent
    ]
})
export class AppSharedModule { }
