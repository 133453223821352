import {McValueLabel} from './mc-value-label';


export class McValueLabelList {

  items: McValueLabel[];

  constructor() {
    this.items = [];
  }

  addItem(mcValueLabelItem: McValueLabel) {
    this.items.push(mcValueLabelItem);
  }

  add(value: string | number | boolean | number[] | (string | null)[], label: string) {
    const mcValueLabel = new McValueLabel();
    mcValueLabel.value = value;
    mcValueLabel.label = label;
    this.items.push(mcValueLabel);
  }

  addFirst(value: string, label: string) {
    const mcValueLabel = new McValueLabel();
    mcValueLabel.value = value;
    mcValueLabel.label = label;
    this.items.unshift(mcValueLabel);
  }

  hasValue(aValue: string) {
    let result = false;
    this.items.forEach((item: McValueLabel) => {
      if (item.value === aValue) {
        result = true;
      }
    });
    return result;
  }
  getLabel(aValue: string | number, aLabelIfNotFound: string) {

    let vlItem = new McValueLabel();

    const filteredItems = this.items.filter((item: McValueLabel) => item.value === aValue);

    if (filteredItems.length > 0) {
      vlItem = filteredItems[0];
    } else {
      vlItem.label = aLabelIfNotFound;
    }
    return vlItem.label;
  }

  public toVlm() {
    const vlm = new Map<string, string>();
    this.items.forEach((item: McValueLabel) => {
      vlm.set(item.value, item.label);
    });
    return vlm;
  }

  public removeItemByValue(value: any) {
    const index = this.items.findIndex(item => item.value === value);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }
}
