import {Component, Inject, OnInit} from '@angular/core';
import {
  McProcess,
  McProcessFlowTable3Config,
  McProcessFlowList,
  McBoolean,
  McHtmlButton,
  McGod,
  McMenu, McProcessNotificationService, McProcessNotification
} from "@miticon-ui/mc-core";
import { Router, ActivatedRoute } from '@angular/router';
import {ICON_ACTIVE} from "../../../../assets/media/svg_icons/icon-active";
import {ICON_INACTIVE} from "../../../../assets/media/svg_icons/icon-inactive";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back"
import {
  McProcessConfigurationEditDialogComponent
} from '../mc-process-configuration-edit-dialog/mc-process-configuration-edit-dialog.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'mc-process-process-profile-page',
  templateUrl: './mc-process-process-profile-page.component.html',
})
export class McProcessProcessProfilePageComponent implements OnInit {

  mcProcessId!: number;
  mcProcess!: McProcess;
  tableConfig = new McProcessFlowTable3Config();
  flowObjectList = new McProcessFlowList();
  showAddEditProcessFlowMcb = new McBoolean();
  showEditProcessProfile = new McBoolean();
  mcGod = McGod.getInstance();
  mcMenu = new McMenu();
  successMsg!: string;
  mcProcessStatusActive = McProcess.STATUS_CD_ACTIVE;
  iconActive = ICON_ACTIVE;
  iconInactive = ICON_INACTIVE;
  iconBack = ICON_BACK;
  processNotifications: McProcessNotification[] = [];

  // ---------------------------------------------------------------------

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    @Inject('designConfig') design: any,
    private mcProcessNotificationService: McProcessNotificationService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcProcessId = +params.get('id');
      this.loadProcessNotification(this.mcProcessId);
      this.mcProcess = new McProcess();
      this.actLoad();
    });

    this.mcMenu.addMenuItem('configuration', this.mcGod.t('cc.common.configuration'), '', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcessId}`, true);
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {

    this.mcProcess.loadById(this.mcProcessId, () => {
      this.flowObjectList.loadByMcProcessId(this.mcProcess.id);
      this.mcMenu = new McMenu();
      this.mcMenu.addMenuItem('configuration', this.mcGod.t('cc.common.configuration'), '', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcessId}`, true);
      if(this.mcProcess.typeCd === "NOTIFICATIONS") {
        this.mcMenu.addMenuItem('email', this.mcGod.t('cc.common.edit.email'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-email/${this.mcProcessId}`);
      } else {
        this.mcMenu.addMenuItem('items', this.mcGod.t('cc.common.items'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${this.mcProcessId}`);
        // this.mcMenu.addMenuItem('statistics', 'Statistics', '', [], ``);
        this.mcMenu.addMenuItem('runs', this.mcGod.t('cc.processes.runs'), '', [McGod.PERM_MC_PROCESS_RUN_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-runs/${this.mcProcessId}`);
      }
    });
  }

  loadProcessNotification(mcProcessId: number) {
    this.processNotifications = [];
    this.mcProcessNotificationService.getByProcessId(mcProcessId).subscribe((notifications: McProcessNotification[]) => {
      if(notifications.length > 0) {
        notifications.forEach((notification) => {
          if(notification.statusCd === McProcessNotification.STATUS_CD_ACTIVE) {
            this.processNotifications.push(notification);
          }
        })
      }
    })
  }

  actCreateFlow() {
    this.showAddEditProcessFlowMcb.setTrue();
  }

  actCloseAddEditProcessFlowMcb() {
    this.showAddEditProcessFlowMcb.setFalse();
  }

  actAddEditProcessFlowSaved() {
    this.showAddEditProcessFlowMcb.setFalse();
    this.successMsg = this.mcGod.t('cc.processes.process-flow-successfully-created');
    this.actLoad();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McProcess.ACTION_SHOW_PROFILE) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/flow-profile/${mcButton.item.id}`);
    }
  }

  actShowEditProcessProfile() {
    this.showEditProcessProfile.setTrue();
  }

  actCancelEditProcessMcb() {
    this.showEditProcessProfile.setFalse();
    this.actLoad();
  }

  actSavedEditProcessMcb() {
    this.showEditProcessProfile.setFalse();
    this.successMsg = `${this.mcProcess.title} ${this.mcGod.t('cc.processes.process-successfully-edited')}`;
    this.actLoad();
  }

  goBack() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes`]);
  }

  /*mcMenuItemClicked(menuItem: McMenuItem) {+
    if (menuItem.route !== '') {
      this.router.navigateByUrl(menuItem.route);
    }
  }*/

  addEditConfigurationDialog() {
    this.dialog.open(McProcessConfigurationEditDialogComponent, {
      height: '700px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false,
      data: {
        processId: this.mcProcess.id,
        processNotifications: this.processNotifications
      }
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.loadProcessNotification(this.mcProcessId);
      }
    });
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }

  getEventTypeCdLabel(eventTypeCd: string) {
    return McProcessNotification.getEventTypeCdLabel(eventTypeCd);
  }

  getEventFilterCdLabel(eventFilterCd: string) {
    return McProcessNotification.getEventFilterCdLabel(eventFilterCd);
  }

  isNotificationPayment(typeCd: string) {
    return typeCd == McProcessNotification.EVENT_TYPE_CD_PAYMENT;
  }

  isNotificationBilling(typeCd: string) {
    return typeCd == McProcessNotification.EVENT_TYPE_CD_BILLING;
  }
}
