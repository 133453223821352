import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BaseDirective,
  CurrencyUtil,
  EbInkassoExport,
  EbInkassoExportService,
  EbSepaExport,
  EbSepaExportService,
  EbSepaFileTransactionService,
  McBankAccountList,
  McBoolean,
  McForm,
  McGod,
  McValueLabelList,
  PmTransaction,
  PmTransactionFilter,
  PmTransactionList,
  PmTransactionService,
  SortCriteriaList,
  WebFile,
} from '@miticon-ui/mc-core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shared-factoring-export-part',
  templateUrl: './app-shared-factoring-export-part.component.html',
})
export class AppSharedFactoringExportPartComponent extends BaseDirective implements OnInit {
  saveButtonDisabledMcb = new McBoolean();
  mcForm = new McForm();
  exportTypeVll = new McValueLabelList();
  dataSeparatorVll = new McValueLabelList();
  exportType!: string;
  largestTransactionDate!: number;
  largestTransactionDateString!: string;
  bankAccounts = new McBankAccountList();
  bankAccountVll = new McValueLabelList();
  sepaExport = new EbSepaExport();
  inkassoExport = new EbInkassoExport();
  webFile = new WebFile();
  exportedFlg = false;
  apiErrorFlg = false;
  errorMsg!: string;
  successMsg!: string;
  loadingFlg = false;
  badInputsFlg = false;
  badInputsErrorMsg = '';
  disableWeekendDays!: boolean;
  mcGod = McGod.getInstance();
  exportSepaTypeVll = new McValueLabelList();
  numberOfAllExportTransactions!: number;
  totalFilteredAmount!: string;
  currencyCode!: string;

  @Input() pmTransactionFilter!: PmTransactionFilter;
  @Input() pmTransactionList!: PmTransactionList;
  @Input() isExportAll = false;
  @Output() eventSaved = new EventEmitter<string>();
  @Output() eventExported = new EventEmitter();
  @Output() eventApiError = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();

  constructor(
    private calendar: NgbCalendar,
    private transactionService: EbSepaFileTransactionService,
    private ebSepaExportService: EbSepaExportService,
    private pmTransactionService: PmTransactionService,
    private toastr: ToastrService,
    private ebInkassoExportService: EbInkassoExportService
  ) {
    super();
  }

  ngOnInit() {
    if (this.isExportAll) {
      const sortCriteriaList = new SortCriteriaList();
      this.pmTransactionService
        .getByFilter(this.pmTransactionFilter, 0, 0, sortCriteriaList)
        .pipe(take(1))
        .subscribe((res) => {
          this.totalFilteredAmount = this.format(res.totalAmountDue);
        });
    } else {
      let totalSelectedAmount = 0;
      this.pmTransactionList.items.forEach((item) => {
        totalSelectedAmount += item.amountDue;
        this.totalFilteredAmount = this.format(totalSelectedAmount);
      });
    }
    this.currencyCode = this.pmTransactionList.items[0].mcFactoring.mcCurrency.code;
    this.numberOfAllExportTransactions = this.isExportAll ? this.pmTransactionList.pagination.totalElements : this.pmTransactionList.getCount();
    this.loadingFlg = true;
    this.exportSepaTypeVll.add(EbSepaExport.TYPE_CD_STAR_MONEY, 'Star money');
    this.exportSepaTypeVll.add(EbSepaExport.TYPE_CD_EBICS, 'EBICS');
    this.bankAccounts.loadByEntityId(McGod.getLoggedEntityIdFromToken(), () => {
      this.bankAccountVll = this.bankAccounts.toVll('id', 'fldTitleIbanBicDefault()', `-- ${this.mcGod.t('cc.common.please-select-bank-account')}`);
      this.loadingFlg = false;
    });
    if (this.mcGod.userHasEbFactoringOutSepaExportPermission()) {
      this.exportTypeVll.add('EXPORT_SEPA', `${this.mcGod.t('cc.factoring.export-sepa')}`);
    }
    if (this.mcGod.userHasEbFactoringOutInkassoExportPermission()) {
      this.exportTypeVll.add('EXPORT_FOR_INKASSO', `${this.mcGod.t('cc.transactions.export-for-inkasso')}`);
    }

    this.exportTypeVll.add('EXPORT_TO_CSV', `${this.mcGod.t('cc.factoring.export-to-csv')}`);

    this.dataSeparatorVll.add('', `${this.mcGod.t('cc.common.edit.please-choose')}`);
    this.dataSeparatorVll.add(',', ',');
    this.dataSeparatorVll.add(';', ';');

    this.checkLargestRequestCollectionDate();

    this.disableWeekendDays = this.disableWeekendDaysFunction();
  }

  disableWeekendDaysFunction(): boolean {
    const date = new NgbDate(0, 0, 0);
    return this.calendar.getWeekday(date) >= 6;
  }

  actExport() {
    if (!this.exportedFlg) {
      if (this.mcForm.isValid()) {
        this.saveButtonDisabledMcb.setTrue();

        if (this.exportType === 'EXPORT_SEPA') {
          // ----------------- SEPA EXPORT -----------------------
          this.exportSepa();
        } else if (this.exportType === 'EXPORT_FOR_INKASSO') {
          // -------------  EXPORT FOR INKASSO ----------------------
          this.exportInkasso();
        } else if (this.exportType === 'EXPORT_TO_CSV') {
          this.exportToCsv();
        }
      } else {
        this.saveButtonDisabledMcb.setFalse();
      }
    } else {
      this.actDownload();
    }
  }

  actCancel() {
    if (this.exportedFlg) {
      this.eventSaved.emit();
    } else {
      this.eventCanceled.emit();
    }
  }

  onExportTypeChanged(exportType: string) {
   if (exportType === 'EXPORT_SEPA') {
      if (this.bankAccounts.getCount() === 0) {
        this.badInputsFlg = true;
        this.badInputsErrorMsg = this.mcGod.t('cc.out-factoring.entity-must-have-at-least-one-bank-account-added-please-navigate-to-entity-profile-and-set-it');
      } else {
        const badStatusItem = this.pmTransactionList.items.find((element) => element.statusCd !== PmTransaction.STATUS_CD_ACCEPTED && element.statusCd !== PmTransaction.STATUS_CD_RETURNED);
        const pausedItem = this.pmTransactionList.items.find(
          (item) => (item.statusCd === PmTransaction.STATUS_CD_ACCEPTED && item.flgPaused) || (item.statusCd === PmTransaction.STATUS_CD_RETURNED && item.flgPaused)
        );
        if (badStatusItem || pausedItem) {
          this.badInputsFlg = true;
          this.badInputsErrorMsg = 'Only non-paused transactions with statuses "Accepted" and "Returned" can be exported into a SEPA file.';
        } else {
          this.badInputsFlg = false;
        }
      }
    }
    this.exportType = exportType;
  }

  checkLargestRequestCollectionDate() {
    // let largestDate = 0;
    // this.pmTransactionList.items.forEach( item => {
    //   if (item.details) {
    //     largestDate = McDateUtils.compareAndReturnLargerDate(largestDate, item.requestedCollectionDate);
    //   } else {
    //     largestDate = McDateUtils.compareAndReturnLargerDate(largestDate, item.batchCollectionDate);
    //   }
    // });
    // this.largestTransactionDate = largestDate;
    // this.largestTransactionDateString = McDateUtils.formatDateToString(largestDate);
  }

  actDownload() {
    this.webFile.downloadFile().subscribe((next) => {
      fileSaver.saveAs(next, `${this.webFile.originalFilename}`);
    });
  }

  exportInkasso() {
    const dataSeparator = this.mcForm.getValue('ctrlDataSeparator');
    const changeTransactionStatusFlg = this.mcForm.getValue('ctrlChangeTransactionStatus');
    const transactionIds = this.pmTransactionList.getIds();

    const inkassoExportToSave = new EbInkassoExport();

    inkassoExportToSave.changeTransactionStatusFlg = changeTransactionStatusFlg;
    inkassoExportToSave.statusCd = EbInkassoExport.STATUS_CD_EXPORTED;
    inkassoExportToSave.id = 0;
    inkassoExportToSave.idWebFile = 0;
    inkassoExportToSave.idMcEntity = McGod.getLoggedEntityIdFromToken();
    inkassoExportToSave.sysCreatedUserId = McGod.getLoggedUserIdFromToken();

    this.saveButtonDisabledMcb.setFalse();
    this.loadingFlg = true;
    if (!this.isExportAll) {
      this.inkassoExport.exportForInkassoAndGet(
        inkassoExportToSave,
        transactionIds,
        dataSeparator,
        () => {
          this.webFile.getFileByIdAndType(
            this.inkassoExport.idWebFile, WebFile.TYPE_CD_IN_INKASSO_EXPORT,
            () => {
              this.loadingFlg = false;
              this.exportedFlg = true;
              this.saveButtonDisabledMcb.setFalse();
              this.successMsg = 'Transaction successfully exported  for inkasso';
              this.eventExported.emit();
            },
            () => {
              this.loadingFlg = false;
              this.apiErrorFlg = true;
              this.errorMsg = this.webFile.apiErrorMessage;
            }
          );
        },
        () => {
          this.apiErrorFlg = true;
          this.loadingFlg = false;
          this.errorMsg = this.inkassoExport.apiErrorMessage;
        }
      );
    } else {
      this.ebInkassoExportService
        .exportAllForInkasso(inkassoExportToSave, this.pmTransactionFilter, dataSeparator)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            this.webFile.getFileByIdAndType(
              res.idWebFile, WebFile.TYPE_CD_IN_INKASSO_EXPORT,
              () => {
                this.loadingFlg = false;
                this.exportedFlg = true;
                this.saveButtonDisabledMcb.setFalse();
                this.successMsg = 'Transaction successfully exported  for inkasso';
                this.eventExported.emit();
              },
              () => {
                this.apiErrorFlg = true;
                this.loadingFlg = false;
                this.errorMsg = this.inkassoExport.apiErrorMessage;
              }
            );
          },
          () => {
            this.apiErrorFlg = true;
            this.loadingFlg = false;
            this.errorMsg = this.inkassoExport.apiErrorMessage;
          }
        );
    }
  }

  exportSepa() {
    const bankAccountId: number = this.mcForm.getValue('selBankAccountId');
    const collectionDate: number = this.mcForm.getValue('dpCollectionDate');

    const newSepaExport = new EbSepaExport();
    newSepaExport.typeCd = this.mcForm.getValue('ctrlExportSepaType');
    newSepaExport.populateForExport(bankAccountId, collectionDate);
    newSepaExport.creationTypeCd = EbSepaExport.CREATION_TYPE_CD_MANUAL;
    this.loadingFlg = true;
    if (!this.isExportAll) {
      this.sepaExport.exportSepaAndGet(
        newSepaExport,
        this.pmTransactionList.getIds(),
        () => {
          if (this.sepaExport == null || this.sepaExport.statusCd === EbSepaExport.STATUS_CD_FAILED){
            this.loadingFlg = false;
            this.toastr.info(`${McGod.t('Something went wrong with sepa export')}`, '', {timeOut: 4000, extendedTimeOut: 4000});
          } else {
            this.webFile.getFileByIdAndType(
              this.sepaExport.idWebfile, WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT,
              () => {
                this.loadingFlg = false;
                this.exportedFlg = true;
                this.errorMsg = '';
                this.saveButtonDisabledMcb.setFalse();
                this.toastr.success(`${McGod.t('cc.transactions.transaction-successfully-exported')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});

                this.eventExported.emit();
              },
              () => {
                this.loadingFlg = false;
                this.apiErrorFlg = true;
                this.toastr.error(this.webFile.apiErrorMessage, '', {timeOut: 4000, extendedTimeOut: 4000});
              }
            );
          }
        },
        () => {
          this.loadingFlg = false;
          this.apiErrorFlg = true;
          this.errorMsg = this.sepaExport.apiErrorMessage;
          this.saveButtonDisabledMcb.setFalse();
        }
      );
    } else {
      this.ebSepaExportService
        .exportAllFilteredTransactions(newSepaExport, this.pmTransactionFilter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          () => {
            this.loadingFlg = false;
            this.successMsg = 'Transaction successfully exported';
            this.eventExported.emit();
          },
          () => {
            this.loadingFlg = false;
            this.apiErrorFlg = true;
            this.errorMsg = 'Server error';
            this.saveButtonDisabledMcb.setFalse();
          }
        );
    }
  }

  exportToCsv(){
    this.pmTransactionList.actExportToCsv(this.pmTransactionFilter, this.webFile);
  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.currencyCode);
    }
    return CurrencyUtil.format(0, this.currencyCode);
  }
}
