<h1>{{'cc.countries.countries' | translate}}</h1>

<div class="row">
  <lib-mc-loader [showLoader]="mcCountryList.apiLoadingFlg"></lib-mc-loader>

  <div class="col-md-12">
    <div class="mc-filter fancy-label">
        <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"></mcc-fi-textbox>

      <!--<mcc-fi-select [mcForm]="mcForm" name="selStatusCd" label="{{'cc.common.view.status' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
      <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" label="{{'cc.jobs.edit.type' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>-->

      <button class="mc-link mc-filter-clear-button filter-clear-top-right"
              (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>

  <div class="col-md-12">
    <mcc-message [successMsg]="successMsg" [errorMsg]="mcCountryList.apiErrorMessage"></mcc-message>
  </div>

<!-- * Add country removed because adding country will be done directly in base-->
<!--  <div class="col-md-12 d-flex">-->
<!--    <button *ngIf="mcGod.userHasMcCountryAddPermission()" class="system-action-btn ml-auto" (click)="actShowAddEditCountryMcb()"><i class="fas fa-plus-circle"></i> {{'cc.countries.add-country' | translate}}</button>-->
<!--  </div>-->

  <div class="col-md-12" *ngIf="mcCountryList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="mcCountryList"
                    [table3Config]="mcCountryTable3Config"
                    (eventItemAction)="onCountryTableItemAction($event)"
                    (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
  </div>

  <mcc-modal1-wrapper modalSizeCd="small" *ngIf="mcShowAddEditCountryMcb.value" [showMcb]="mcShowAddEditCountryMcb" (eventCanceled)="onAddEditCountryCancelEmit()">
    <mc-country-country-add-edit-part [mcCountry]="mcCountry" (eventCanceled)="onAddEditCountryCancelEmit()" (eventSaved)="onAddEditCountrySaveEmit($event)"></mc-country-country-add-edit-part>
  </mcc-modal1-wrapper>

</div>

