import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CurrencyUtil,
  McConsumerContractProductTable3ConfigModel,
  McConsumerFilter, McCurrency, McForm, McHtmlButton,
  McProduct,
  McProductList, McValueLabelList,
  McVat
} from '@miticon-ui/mc-core';
import { McProductFilter } from '@miticon-ui/mc-core';
import { ICON_REMOVE } from "../../../../assets/media/svg_icons/icon-remove";

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-products-and-services-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-products-and-services-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-products-and-services-part.component.scss']
})
export class McConsumerConsumer360CreateContractProductsAndServicesPartComponent implements OnInit {

  /*Variables*/
  productList = new McProductList();
  productFilter = new McProductFilter();
  selectedProduct = new McProduct();
  mcVat = new McVat();
  productsTable3Config = new McConsumerContractProductTable3ConfigModel();
  @Input() totalNetto = 0;
  @Input() totalVat = 0;
  totalVatFixed!: string;
  productsVll = new McValueLabelList();
  totalNettoStr!: string;
  totalVatStr!: string;
  @Input() selectedProductList = new McProductList();
  @Input() errorMessage!: string;
  @Input() currency!: McCurrency;
  @Input() entityCurrency!: string;
  @Input() activeFactoringId!: number;
  @Input() mcForm!: McForm;
  @Output() totalNettoAndVatValueEmit = new EventEmitter<{totalNetto: number, totalVat: number}>();
  iconRemove = ICON_REMOVE;

  ngOnInit() {
    this.productFilter.searchTerm = '';
    this.productList.loadByFilter(this.productFilter, () => {
      this.productList.items.map(item => this.productsVll.add(item.id, item.name));

    });
    this.totalVatFixed = this.totalVat.toFixed(2);
  }

  onProductClick(productId: number) {
    const product = new McProduct();
    product.loadById(productId, () => {
      if (!product.vatRate) {
        this.mcVat.loadById(product.idVat, () => {
          this.selectedProduct = product;
          this.selectedProduct.vatRate = this.mcVat.vatRate;
          this.selectedProduct.currencySymbol = this.entityCurrency;
        });
      } else {
        this.selectedProduct = product;
      }
    });
  }

  actAddProduct(selectedProduct: McProduct) {
    const selProductList = this.selectedProductList;
    selProductList.items.slice(0);
    if (selectedProduct.exists()) {
      const itemInList = this.selectedProductList.getItemById(selectedProduct.id);
      if (!itemInList) {
        selProductList.items.push(selectedProduct);
        this.selectedProductList = selProductList;
        // this.selectedProductList.addItem(this.selectedProduct);
        this.calculateProductPrice(selectedProduct, true);
      } else {
      }
    }
  }

  onProductQuantityChange(product: McProduct, quantity: string) {
    switch (quantity) {
      case 'add':
        product.noOfUnitsSelected++;
        this.calculateProductPrice(product, true);
        break;
      case 'subtract':
        if (product.noOfUnitsSelected > 1) {
          product.noOfUnitsSelected--;
          this.calculateProductPrice(product, false);
        }
        break;
    }
  }

  onProductRemove(product: McProduct) {
    this.calculateProductPrice(product, false, product.noOfUnitsSelected)
    product.noOfUnitsSelected = 1;
    this.selectedProductList.removeItemById(product.id);
  }

  calculateProductPrice(product: McProduct, sub: boolean, times?: number) {
    const unitPrice = times ? product.unitPrice * times : product.unitPrice;
    if (sub) {
      this.totalNetto += unitPrice;
      this.totalVat += (product.vatRate / 100) * unitPrice;
      this.totalVatFixed = this.totalVat.toFixed(2);

      this.totalNettoStr = CurrencyUtil.format(this.totalNetto, this.currency.code);
      this.totalVatStr = CurrencyUtil.format(this.totalVat, this.currency.code);
    } else {
      this.totalNetto -= unitPrice;
      this.totalVat -= (product.vatRate / 100) * unitPrice;
      this.totalVatFixed = this.totalVat.toFixed(2);

      this.totalNettoStr = CurrencyUtil.format(this.totalNetto, this.currency.code);
      this.totalVatStr = CurrencyUtil.format(this.totalVat, this.currency.code);
    }
    this.totalVatFixed = this.totalVatFixed === '-0.00' ? '0.00' : this.totalVatFixed;
    this.totalNettoAndVatValueEmit.emit({totalNetto: this.totalNetto, totalVat: this.totalVat});
  }
}
