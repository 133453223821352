<h1>{{'cc.pricelist.template-pricelists' | translate}}</h1>

<div class="row">
    <lib-mc-loader [showLoader]="mcPriceListList.apiLoadingFlg"></lib-mc-loader>

  <div class="col-md-12">
    <div class="mc-filter fancy-label">
        <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"></mcc-fi-textbox>

      <!--<mcc-fi-select [mcForm]="mcForm" name="selStatusCd" label="{{'cc.common.view.status' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>
      <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" label="{{'cc.jobs.edit.type' | translate}}" placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>-->

      <button class="mc-link mc-filter-clear-button filter-clear-top-right"
              (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>

  <div class="col-md-12">
    <mcc-message [successMsg]="successMsg" [errorMsg]="mcPriceListList.apiErrorMessage"></mcc-message>
  </div>

  <div class="col-md-12 d-flex">
    <button *ngIf="mcGod.userHasMcBillingTemplatePricelistAddPermission()" class="system-action-btn ml-auto" (click)="actShowAddPricelistTemplateMcb()"><i class="fas fa-plus-circle"></i> {{'cc.pricelist.new-pricelist-template' | translate}}</button>
  </div>

    <div class="col-md-12 mb-5" *ngIf="mcPriceListList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcPriceListList"
                  [table3Config]="mcTemplateTable3Config"
                        (eventItemAction)="onPricelistTemplatesItemAction($event)"
                 (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
      <div class="mb-5"></div>
    </div>

  <mcc-modal1-wrapper *ngIf="showAddEditMcPricelistTemplateMcb.value" [showMcb]="showAddEditMcPricelistTemplateMcb">
    <mc-pricelist-template-add-edit-part (eventSaved)="onAddTemplatePricelistSaveEmit()" (eventCanceled)="showAddEditMcPricelistTemplateMcb.setFalse()"></mc-pricelist-template-add-edit-part>
  </mcc-modal1-wrapper>

</div>

<router-outlet></router-outlet>

