import {Component, Inject, OnInit} from '@angular/core';
import {
  McBoolean,
  McForm,
  McGod,
  McHtmlButton,
  McPriceList, McPriceListFilter,
  McPriceListList, McPriceListTemplateTable3ConfigModel
} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";

@Component({
  selector: 'mc-pricelist-templates-list-page',
  templateUrl: './mc-pricelist-templates-list-page.component.html',
})
export class McPricelistTemplatesListPageComponent implements OnInit {
  mcPriceListList!: McPriceListList;
  mcTemplateTable3Config = new  McPriceListTemplateTable3ConfigModel();
  mcForm = new McForm();
  successMsg = '';
  mcGod = McGod.getInstance();
  showAddEditMcPricelistTemplateMcb = new McBoolean();
  entityId!: number;
  mcTemplateFilter = new McPriceListFilter();

  // ---------------------------------------------------------------------
  constructor(private router: Router) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.entityId = McGod.getLoggedEntityIdFromToken();
    this.mcPriceListList = new McPriceListList();
    this.mcPriceListList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcTemplateFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.mcTemplateFilter.idEntityOwner = this.entityId;
    this.mcTemplateFilter.isTemplate = true;
    this.mcPriceListList.setSortB('id', 'DESC');

    this.mcPriceListList.loadByFilter(this.mcTemplateFilter, () => {
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }


  actShowAddPricelistTemplateMcb() {
    this.showAddEditMcPricelistTemplateMcb.setTrue();
  }

  onAddTemplatePricelistSaveEmit() {
    this.showAddEditMcPricelistTemplateMcb.setFalse();
    this.successMsg = McGod.t('cc.pricelist.pricelist-template-created');
    this.actLoad();
  }

  onPricelistTemplatesItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === McPriceList.ACTION_VIEW_DETAILS) {
      this.router.navigate([`/entity/${this.entityId}/pricelists/overview/${htmlButton.item.id}`]);
    }
    if (htmlButton.actionCdForEvent === McPriceList.ACTION_EDIT) {
      this.showAddEditMcPricelistTemplateMcb.setTrue();
    }
  }
}
