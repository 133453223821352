/*BbmTestObj*/
import {
} from '@miticon-ui/mc-components';
import {EbSepaExportTable3ConfigGENERATED} from '@miticon-ui/mc-core';

// import {EbSepaExportGENERATED} from '../_generated/eb-sepa-export-generated.model';

export class EbSepaExportTable3Config extends EbSepaExportTable3ConfigGENERATED {
  // ---------------------------------------------------------------------
  constructor() {
    super();


    this.addColumn('colActions', '', 'fldExportSepaActions()', '');
    this.addColumn('exportedFile', this.t('cc.out-factoring.exported-file'), 'sepaExportFilename', 'wf.filename');
      /*.setCustomMethod((aItemObject, aComponentConfig: McDownloadFileByClickConfig) => {
        aComponentConfig.item = aItemObject;
      })
      .getBuilder()
      .setEditComponent(McDownloadFileByClickComponent, '', downloadComponentConfig).setExtraCssClass('mc-custom-link');*/
    this.addColumn('colTypeCd', this.t('cc.factoring.export-sepa.export-type'), 'getTypeCdLabel()', 'typeCd');
    this.addColumn('colBankName', this.t('cc.ebics.bank-name'), 'bankName', 'bankName');
    this.addColumn('colExportDateTime', this.t('cc.factoring.export-sepa.export-date-&-time'), 'getCreatedAtString()', 'createdAt');
    this.addColumn('colRequestCollectionDate', 'Request collection date', 'getRequestCollectionDate()', 'requestedCollectionDate');
    this.addColumn('colAllDebtors', this.t('cc.factoring.imported-sepa.all-debtors'), 'allDebtors', '');
    this.addColumn('colTotalAmountDue', this.t('cc.factoring.imported-sepa.total-amount-due'), 'getTotalAmount()', '').extraCssClass = 'text-right';
    this.addColumn('colStatusCode', this.t('cc.common.view.status'), 'getStatusCdLabel()', 'statusCd');


  }
}
