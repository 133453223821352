<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1" style="text-transform: capitalize">{{'cc.pricelist.pricelists' | translate}}</h3>
</div>

<div class="row">

  <lib-mc-loader [showLoader]="mcPriceListList.apiLoadingFlg || mcTemplates.apiLoadingFlg"></lib-mc-loader>

  <div class="col-md-12">
    <div class="mc-filter fancy-label">


      <i class="fas fa-info-circle mc-tooltip-top-margin"
         placement="bottom-left"></i>
        <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm"
                        (eventEnterClicked)="actLoad()"></mcc-fi-textbox>


      <!--<mcc-fi-select [mcForm]="mcForm" name="selCreatedBy" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Created by"
      ></mcc-fi-select>-->


      <button class="mc-link mc-filter-clear-button filter-clear-top-right"
              (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


    </div>
  </div>

  <div class="col-md-12">
    <mcc-message [errorMsg]="mcPriceListList.apiErrorMessage"></mcc-message>
  </div>


  <div class="col-md-12 d-flex">
    <button *ngIf="mcGod.userHasMcBillingPricelistAddPermission()" class="system-action-btn ml-auto" (click)="actShowNewPricelistMcb()"><i class="fas fa-plus-circle"></i> {{'cc.pricelist.new-pricelist' | translate}}
    </button>
  </div>

  <div class="col-md-12 mb-5" *ngIf="mcPriceListList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="mcPriceListList"
                    [table3Config]="mcPriceListTable3Config"
                    (eventItemAction)="onPriclistTableItemAction($event)"
                    (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
  </div>
  <div class="mb-5 mt-5"></div>
  <mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showAddPricelistStep1" *ngIf="showAddPricelistStep1.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-select-template-part [mcForm]="mcFormForCreatePricelist" [mcTemplates]="mcTemplates"
                                                 (eventNext)="onAddPricelistStep1NextEmit($event)"
                                                 (eventCurrencySymbol)="onCurrencySymbolEmitted($event)"
                                                 (eventCanceled)="actCloseAddPricelistStepper()"></mc-pricelist-pricelist-select-template-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddPricelistStep2PricesAndEntities"
                      *ngIf="showAddPricelistStep2PricesAndEntities.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-prices-and-entities-part [mcForm]="mcFormForCreatePricelist"
                                                     [entitiesValue]="pricelistEntitiesValue"
                                                     [currencyCode]="createPricelistCurrencySelected"
                                                     [templatePriceList]="chosenTemplatePriceList"
                                                     [defaultTemplatePriceList]="defaultChosenTemplatePriceList"
                                                     (eventCanceled)="actCloseAddPricelistStepper()"
                                                     (eventPrev)="onAddPricelistStep2PrevEmit()"
                                                     (eventSelectedEntities)="onSelectedEntities($event)"
                                                     (eventNext)="onAddPricelistStep2NextEmit()"></mc-pricelist-pricelist-prices-and-entities-part>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper modalSizeCd="full" [showMcb]="showAddPricelistStep3ReviewAndConfirm"
                      *ngIf="showAddPricelistStep3ReviewAndConfirm.value"
                      (eventCanceled)="actCloseAddPricelistStepper()">
    <mc-pricelist-pricelist-review-and-confirm-part [mcForm]="mcFormForCreatePricelist"
                                                    [currency]="currencyCode"
                                                    [entities]="pricelistEntitiesValue"
                                                    [templatePriceList]="chosenTemplatePriceList"
                                                    (eventCanceled)="actCloseAddPricelistStepper()"
                                                    (eventSaved)="onAddPricelistSaveEmit()"
                                                    [selectedEntities]="selectedEntities"
                                                    (eventPrev)="onAddPricelistStep3PrevEmit()"></mc-pricelist-pricelist-review-and-confirm-part>
  </mcc-modal1-wrapper>

</div>

<router-outlet></router-outlet>

