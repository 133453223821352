<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.out-factoring.exported-sepa-list' | translate}}</h3>
</div>

<div>
    <lib-mc-loader [showLoader]="ebSepaExportList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaExportList.apiErrorFlg">
        Error: {{ebSepaExportList.apiErrorMessage}}
    </div>

  <div class="mc-filter fancy-label">

    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.out-factoring.search-by-sepa-filename' | translate}}" placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selStatusCd" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.common.view.status' | translate}}"
                   [optionsVll]="ebSepaExportStatusCdVll"></mcc-fi-select>
    <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.factoring.export-sepa.export-type' | translate}}"
                   [optionsVll]="exportTypeCdsVll"></mcc-fi-select>

    <!--Bank filter-->
    <ng-container *ngIf="bankVll.items.length">
      <mcc-multiselect-filter [optionVll]="bankVll" [clearedAllFilters]="clearedAllMultipleFilters"
                              [label]="'cc.ebics.bank-name' | translate" name="selBankCd" (eventSelectedItems)="onSelectedBank($event)">
      </mcc-multiselect-filter>
    </ng-container>

    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>

  </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>

    <!--  <a class="col-md-1 offset-md-11" routerLink="edit/0">Add New</a>-->
<!--
  <mcc-dialog-box *ngIf="showSentToBankDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="Revoke contract" name="revoke-contract"
                  description='Are you sure you want to mark this SEPA file as "Sent to bank"? Note: Transactions in it will also change status.'
                  [buttonsKvm]="sentToBankDialogButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxRevokeContractActionButtonClicked($event)"></mcc-dialog-box>-->

  <mcc-dialog-box *ngIf="showRevertDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.out-factoring.revert-this-exported-sepa' | translate}}" name="revert-export-sepa"
                  description="{{revertDialogMessage}}"
                  [buttonsKvm]="revertButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxRevertSepaActionButtonClicked($event)"></mcc-dialog-box>

    <div *ngIf="ebSepaExportList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebSepaExportList"
                        [table3Config]="ebSepaExportTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()"
                        (eventItemAction)="actExportSepaTableItemAction($event)"></mcc-fi-table-3>
    </div>

</div>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showUploadFileMcb" *ngIf="showUploadFileMcb.value">
  <lib-upload-file (eventUploaded)="onUploadFile($event)"></lib-upload-file>
</mcc-modal1-wrapper>

