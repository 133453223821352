import {Component, OnInit} from '@angular/core';
import {
  McMapping,
  McForm,
  McHtmlButton,
  McBoolean, McGod, McNotifyMessageModel, McUserList, McValueLabelList,
  CsrMappingService,
  CsrMappingFilter,
  SortCriteriaList,
  CsrMapping,
  CsrMappingItemList,
  CsrMappingList,
  CsrMappingTable3Config
} from "@miticon-ui/mc-core";
import {HttpParams} from "@angular/common/http";

const enum SharingUnsharinDialogAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_YES = 'btn-primary'
}

@Component({
  selector: 'mc-consumer-consumer-import-mappings-list-page',
  templateUrl: './mc-consumer-consumer-import-mappings-list-page.component.html',
})
export class McConsumerConsumerImportMappingsListPageComponent implements OnInit {

  mcForm!: McForm;
  csrMapping = new CsrMapping();
  csrMappingItemList!: CsrMappingItemList;
  csrMappingFilter = new CsrMappingFilter();
  csrMappingList!: CsrMappingList;
  csrMappingTable3Config = new  CsrMappingTable3Config();
  showMappingProfileMcb = new McBoolean();
  showMappingEditMcb = new McBoolean();
  showUnShareMappingMcb = new McBoolean();
  showShareMappingMcb = new McBoolean();
  showCopyMappingMcb = new McBoolean();
  csrMappingId!: number;
  sharingUnsharingCopyButtonKvm = new Map<string, string>();
  mcGod = McGod.getInstance();
  notifyMessage: McNotifyMessageModel = {message: '', type: ''};
  mcUsers = new McUserList();
  mcUsersVll = new McValueLabelList();
  csrMappingService = new CsrMappingService();
  isLoading = false;


  ngOnInit() {
    this.mcUsers.loadAllThatCreatedMappingOnEntity(() => {
      this.mcUsersVll = this.mcUsers.toVll('id', 'fldFullName()');
    });
    this.sharingUnsharingCopyButtonKvm.set('btn-secondary', McGod.t('cc.common.edit.cancel'));
    this.sharingUnsharingCopyButtonKvm.set('btn-primary', McGod.t('cc.common.edit.yes'));

    this.mcForm = new McForm();
    this.csrMappingList = new CsrMappingList();
    this.csrMappingList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.isLoading = true;
    this.csrMappingFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.csrMappingFilter.createdByUserId = this.mcForm.getValue('selUser');
    let params = new HttpParams()
      .set('term', this.csrMappingFilter.searchTerm ? this.csrMappingFilter.searchTerm : '')
      .set('userId', this.csrMappingFilter.createdByUserId ? this.csrMappingFilter.createdByUserId: '')
      .set('page', 0)
      .set('size', 100);

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrMappingService.get(params, 0, 100, sortCriteria)
      .subscribe((res) => {
        this.isLoading = false
        this.csrMappingList.apiSuccessFlg = true
        this.csrMappingList.items = res.content.map((object: any) => object = CsrMapping.createFromJson(object));
      });
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McMapping.ACTION_SHOW_EDIT) {
      this.actShowAddEdit(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_SHOW_PROFILE) {
      this.actShowProfile(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_COPY) {
      this.actionMenuCopyMapping(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_SHARE) {
      this.actShareMapping(mcButton.item);
    } else if (mcButton.actionCdForEvent === McMapping.ACTION_UNSHARE) {
      this.actUnshareMapping(mcButton.item);
    }
  }

  actShowAddEdit(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = this.csrMapping;
    this.showMappingEditMcb.setTrue();
  }

  actShowProfile(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showMappingProfileMcb.setTrue();
  }

  actCopyMapping(csrMapping: CsrMapping) {
    this.showCopyMappingMcb.setFalse();
    this.showMappingProfileMcb.setFalse();
    this.csrMapping = new CsrMapping();
    const entityId = McGod.getLoggedEntityIdFromToken();
    const userId = McGod.getLoggedUserIdFromToken();
    this.csrMapping.callCopyMappingWithMappingItems(csrMapping.id, userId, entityId, () => {
      this.notifyMessage = {message: `${csrMapping.mappingName} ${McGod.t('cc.import-mappings.mapping-successfully-copied')}`, type: 'success'};
      this.actLoad();
    }, () => {
      this.notifyMessage = {message: this.csrMapping.apiErrorMessage, type: 'error'};
    });
  }

  actShareMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showShareMappingMcb.setTrue();
  }

  actUnshareMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showUnShareMappingMcb.setTrue();
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selUser')?.get('value')?.setValue('');
    this.actLoad();
  }

  onUpdatedMapping() {
    this.showMappingEditMcb.setFalse();
    this.actLoad();
  }

  onCloseMapping() {
    this.showMappingEditMcb.setFalse();
  }

  onEditMapping() {
    this.showMappingProfileMcb.setFalse();
    this.showMappingEditMcb.setTrue();
  }

  actionMenuCopyMapping(csrMapping: CsrMapping) {
    this.csrMappingId = csrMapping.id;
    this.csrMapping = csrMapping;
    this.showCopyMappingMcb.setTrue();
  }

  onMcDialogBoxUnshareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showUnShareMappingMcb.setFalse();
    } else {
      this.unShareMapping();
    }
  }

  onMcDialogBoxShareMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showShareMappingMcb.setFalse();
    } else {
      this.shareMapping();
    }
  }

  onMcDialogBoxCopyMappingActionButtonClicked(action: string) {
    if (action === SharingUnsharinDialogAction.ACT_CANCEL) {
      this.showCopyMappingMcb.setFalse();
    } else {
      this.actCopyMapping(this.csrMapping);
    }
  }

  shareMapping() {
    this.csrMapping.sharedFlg = true;
    this.csrMapping.save(() => {
      this.showShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.notifyMessage = {message: `${this.csrMapping.mappingName} ${McGod.t('cc.import-mappings.successfully-shared')}`, type: 'success'};
      this.actLoad();
    }, () => {
      this.showShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.notifyMessage = {message: this.csrMapping.apiErrorMessage, type: 'error'};
    });
  }
  unShareMapping() {
    this.csrMapping.sharedFlg = false;
    this.csrMapping.save(() => {
      this.showUnShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.notifyMessage = {message: `${this.csrMapping.mappingName} ${McGod.t('cc.import-mappings.successfully-unshared')}`, type: 'success'};
      this.actLoad();
    }, () => {
      this.showUnShareMappingMcb.setFalse();
      this.showMappingProfileMcb.setFalse();
      this.notifyMessage = {message: this.csrMapping.apiErrorMessage, type: 'error'};
    });
  }

}
