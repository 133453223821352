/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbInkassoExport} from '../models/eb-inkasso-export.model';
import {EbInkassoExportList} from '../models/eb-inkasso-export-list.model';
import {EbInkassoExportFilter} from '../models/eb-inkasso-export-filter.model';
import {EbInkassoExportService} from '../services/eb-inkasso-export.service';
import {IEbInkassoExport} from '../_generated/eb-inkasso-export.interface';
import {McGod} from "../extra/mc-god.service";

export class EbInkassoExportListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'changeTransactionStatusFlg', 'idMcEntity', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalDebtors', 'totalTransactions', 'exportedByUserName', 'exportFileName'];
  public items: EbInkassoExport[] = [];
  public apiService: EbInkassoExportService;
 // protected tempObjList: EbInkassoExportList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbInkassoExportList {
    console.error('MTCN-ERROR: Not Implemented: EbInkassoExportList::createNewInstance(). Add this method to final class and return new EbInkassoExportList();');
    throw new Error('Cannot EbInkassoExportList::createNewInstance(). Add this method to final class and return new EbInkassoExportList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbInkassoExport[]): EbInkassoExportList {
    const  listObj: EbInkassoExportList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbInkassoExport = EbInkassoExport.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbInkassoExportList {
    const  listObj: EbInkassoExportList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbInkassoExportService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbInkassoExport[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbInkassoExport = EbInkassoExport.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbInkassoExportFilter: EbInkassoExportFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbInkassoExportFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbInkassoExport {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbInkassoExport();
    }
  }

  public replaceItem(newItem: EbInkassoExport) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

