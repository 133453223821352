import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-mc-loader',
  templateUrl: './mc-loader.component.html',
  styles: [`
    .mc-loader-wrapper {
      background-color: rgba(0, 0, 0, 0.6);;
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
      width: 100vw;
      height: 100vh;
    }
    .mc-loader-show {
      display: block;
    }
    .mc-loader-content {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @keyframes lds-eclipse {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes lds-eclipse {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .mc-loader {
      position: relative;
    }

    .mc-loader div {
      position: absolute;
      -webkit-animation: lds-eclipse 1s linear infinite;
      animation: lds-eclipse 1s linear infinite;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      box-shadow: 0 8px 0 0 #35B1F1;
      -webkit-transform-origin: 80px 84px;
      transform-origin: 80px 84px;
    }

    .mc-loader {
      width: 200px !important;
      height: 200px !important;
      -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
      transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }`
  ]
})
export class McLoaderComponent {
  @Input() showLoader!: boolean;
}
