import { Injectable} from '@angular/core';
import {CsrContractServiceGENERATED} from '../_generated/csr-contract-generated.service';
import {Observable} from 'rxjs';
import {CsrConsumer} from '../models/csr-consumer.model';
import {CsrContractProducts} from '../models/csr-contract-products.model';
import {CcMollieCustomer} from '../models/cc-mollie-customer.model';
import {tap} from 'rxjs/operators';
import {CsrRecurringDetails} from '../models/csr-recurring-details.model';
import {CsrNotificationDetails} from '../models/csr-notification-details.model';
import {CsrPaymentDetails} from '../models/csr-payment-details.model';
import {McProductList} from '../models/mc-product-list.model';
import {CsrContractProductsList} from '../models/csr-contract-products-list.model';
import {ICsrContract} from '../_generated/csr-contract.interface';


@Injectable({
  providedIn: 'root'
})
export class CsrContractService extends CsrContractServiceGENERATED {
  _rawJson: any;
  propertiesRegular: string[] = ['id'];
  recurringDetails = new CsrRecurringDetails();
  notificationDetails = new CsrNotificationDetails();
  paymentDetails = new CsrPaymentDetails();
  products = new McProductList();
  productSnapshots = new CsrContractProductsList();

  constructor() {
    super();
  }

  acceptContract(body: { confirmationCode: string, contractId: number }): Observable<CcMollieCustomer> {
    return this.httpClient.get<CcMollieCustomer>(this.apiUrl + `/ccmollie/v3/ccmolliecustomer/confirm/?confirmationCode=${body.confirmationCode}&contractId=${body.contractId}`);
  }

  sendInvitationEmail(body: { idCsrConsumer: number, idCsrContract: number }) {
    return this.httpClient.post(this.apiUrl + `/ccmollie/v3/ccmolliecustomer`, body);
  }

  validateConfirmationCode(body: { confirmationCode: string, contractId: number }): Observable<CsrConsumer> {
    return this.httpClient.get<CsrConsumer>(this.apiUrl + `/ccmollie/v3/ccmolliecustomer/validate/?confirmationCode=${body.confirmationCode}&contractId=${body.contractId}`);
  }

  readCsrContract(body: { confirmationCode: string, contractId: number }): Observable<ICsrContract> {
    return this.httpClient.get<ICsrContract>(this.apiUrl + `/ccmollie/v3/ccmolliecustomer/contract/?confirmationCode=${body.confirmationCode}&contractId=${body.contractId}`)
      .pipe(tap((responseObj) => {

      }));
  }
  readCsrContractProductsList(body: { confirmationCode: string, contractId: number }): Observable<CsrContractProducts[]> {
    return this.httpClient.get<CsrContractProducts[]>(this.apiUrl + `/ccmollie/v3/ccmolliecustomer/product/?confirmationCode=${body.confirmationCode}&contractId=${body.contractId}`);
  }

  _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrContract AABB()');
    if (this._rawJson.notificationDetails) {
      this.notificationDetails = CsrNotificationDetails.createFromJson(this._rawJson.notificationDetails);
    }
    if (this._rawJson.paymentDetails) {
      this.paymentDetails = CsrPaymentDetails.createFromJson(this._rawJson.paymentDetails);
    }
    if (this._rawJson.products) {
      this.products = McProductList.createFromJson(this._rawJson.products);
    }
    if (this._rawJson.productSnapshots) {
      this.productSnapshots = CsrContractProductsList.createFromJson(this._rawJson.productSnapshots);
    }
    if (this._rawJson.recurringDetails) {
      this.recurringDetails = CsrRecurringDetails.createFromJson(this._rawJson.recurringDetails);
    }
  }

  cancelContract(contractId: number): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/cancel/${contractId}`, {});
  }
}
