/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {PpOrder} from '../models/pp-order.model';
import {PpOrderService} from '../services/pp-order.service';
import {IPpOrder} from '../_generated/pp-order.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {PpOrderItemList} from '../models/pp-order-item-list.model';
import {McCurrency} from '../models/mc-currency.model';
import {PpPriceAdjustment} from '../models/pp-price-adjustment.model';


export abstract class PpOrderGENERATED extends BaseObject {


    public static readonly REF_TYPE_CD_CSR_CONSUMER: string = 'CSR_CONSUMER';
    public static readonly REF_TYPE_CD_CSR_CONTRACT: string = 'CSR_CONTRACT';
  public static readonly REF_TYPE_CD_LIST__ALL = [
    PpOrderGENERATED.REF_TYPE_CD_CSR_CONSUMER,
    PpOrderGENERATED.REF_TYPE_CD_CSR_CONTRACT
];

    public static readonly STATE_DRAFT: string = 'DRAFT';
    public static readonly STATE_SCHEDULED: string = 'SCHEDULED';
    public static readonly STATE_SEND_NOW: string = 'SEND_NOW';
    public static readonly STATE_CANCELED: string = 'CANCELED';
    public static readonly STATE_PRINT: string = 'PRINT';
    public static readonly STATE_COMPLETED: string = 'COMPLETED';
    public static readonly STATE_FAILED: string = 'FAILED';
  public static readonly STATE_LIST__ALL = [
    PpOrderGENERATED.STATE_DRAFT,
    PpOrderGENERATED.STATE_SCHEDULED,
    PpOrderGENERATED.STATE_SEND_NOW,
    PpOrderGENERATED.STATE_CANCELED,
    PpOrderGENERATED.STATE_PRINT,
    PpOrderGENERATED.STATE_COMPLETED,
    PpOrderGENERATED.STATE_FAILED
];

    public static readonly TYPE_CD_FRANCHISE_FEE: string = 'FRANCHISE_FEE';
    public static readonly TYPE_CD_EXTERNAL_FRANCHISE_FEE: string = 'EXTERNAL_FRANCHISE_FEE';
    public static readonly TYPE_CD_OTHER: string = 'OTHER';
  public static readonly TYPE_CD_LIST__ALL = [
    PpOrderGENERATED.TYPE_CD_FRANCHISE_FEE,
    PpOrderGENERATED.TYPE_CD_EXTERNAL_FRANCHISE_FEE,
    PpOrderGENERATED.TYPE_CD_OTHER
];

  public apiService: PpOrderService;
  public _rawJson: IPpOrder;
    id: number = 0;
  dueDate: number;
  idReceiverMcEntity: number;
  idRefReceiver: number;
  idSenderMcEntity: number;
  orderNumber = '';
  reason = '';
  refTypeCd: string;
  sendingDate: number;
  servicePeriodFrom: number;
  servicePeriodTo: number;
  state: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  totalAmount: number;
  typeCd: string;
  version: number;
  currency: McCurrency;  discount: PpPriceAdjustment;  entityName: string;  excludeDiscountedItems: boolean;  orderItems: PpOrderItemList;  recipient: string;  statusCodes: any;

  public properties: string[] = ['id', 'dueDate', 'idReceiverMcEntity', 'idRefReceiver', 'idSenderMcEntity', 'orderNumber', 'reason', 'refTypeCd', 'sendingDate', 'servicePeriodFrom', 'servicePeriodTo', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'typeCd', 'version', 'currency', 'discount', 'entityName', 'excludeDiscountedItems', 'orderItems', 'recipient', 'statusCodes'];
  public propertiesRegular: string[] = ['id', 'dueDate', 'idReceiverMcEntity', 'idRefReceiver', 'idSenderMcEntity', 'orderNumber', 'reason', 'refTypeCd', 'sendingDate', 'servicePeriodFrom', 'servicePeriodTo', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'typeCd', 'version', 'entityName', 'excludeDiscountedItems', 'recipient', 'statusCodes'];
  public propertiesSpecial: string[] = ['currency', 'discount', 'orderItems'];



  public static getRefTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpOrderGENERATED.REF_TYPE_CD_CSR_CONSUMER, McGod.t('Consumer'));
    list.add(PpOrderGENERATED.REF_TYPE_CD_CSR_CONTRACT, McGod.t('Contract'));


    return list;
  }

  public static getRefTypeCdLabel(aCd: string): string {
    const list = PpOrderGENERATED.getRefTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStateVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpOrderGENERATED.STATE_DRAFT, McGod.t('Draft'));
    list.add(PpOrderGENERATED.STATE_SCHEDULED, McGod.t('Scheduled'));
    list.add(PpOrderGENERATED.STATE_SEND_NOW, McGod.t('Send now'));
    list.add(PpOrderGENERATED.STATE_CANCELED, McGod.t('Canceled'));
    list.add(PpOrderGENERATED.STATE_PRINT, McGod.t('Print'));
    list.add(PpOrderGENERATED.STATE_COMPLETED, McGod.t('Completed'));
    list.add(PpOrderGENERATED.STATE_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStateLabel(aCd: string): string {
    const list = PpOrderGENERATED.getStateVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(PpOrderGENERATED.TYPE_CD_FRANCHISE_FEE, McGod.t('Franchise fee'));
    list.add(PpOrderGENERATED.TYPE_CD_EXTERNAL_FRANCHISE_FEE, McGod.t('External franchise fee'));
    list.add(PpOrderGENERATED.TYPE_CD_OTHER, McGod.t('Other'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = PpOrderGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): PpOrder {
    console.error('MTCN-ERROR: Not Implemented: PpOrder::createNewInstance(). Add this method to final class and return new PpOrder();');
    throw new Error('Cannot PpOrder::createNewInstance(). Add this method to final class and return new PpOrder();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IPpOrder): PpOrder {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PpOrderService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE PpOrder AABB()');
          if (this._rawJson['currency']) {
        this.currency = McCurrency.createFromJson(this._rawJson['currency']);
      }
      if (this._rawJson['discount']) {
        this.discount = PpPriceAdjustment.createFromJson(this._rawJson['discount']);
      }
      if (this._rawJson['orderItems']) {
        this.orderItems = PpOrderItemList.createFromJson(this._rawJson['orderItems']);
      }

  }

  // ---------------------------------------------------------------------

    public getDueDateStrD(): string {
       return McDateUtils.formatDateToString(this.dueDate);
    }

    public getDueDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.dueDate);
    }

    public getSendingDateStrD(): string {
       return McDateUtils.formatDateToString(this.sendingDate);
    }

    public getSendingDateStr(): string {
       return McDateUtils.formatDateTimeToString(this.sendingDate);
    }

    public getServicePeriodFromStrD(): string {
       return McDateUtils.formatDateToString(this.servicePeriodFrom);
    }

    public getServicePeriodFromStr(): string {
       return McDateUtils.formatDateTimeToString(this.servicePeriodFrom);
    }

    public getServicePeriodToStrD(): string {
       return McDateUtils.formatDateToString(this.servicePeriodTo);
    }

    public getServicePeriodToStr(): string {
       return McDateUtils.formatDateTimeToString(this.servicePeriodTo);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('currency')) {
        if (this.currency != null) {
            dto['currency'] = this.currency.toDto();
        } else {
            dto['currency'] = null;
        }
    }
    if (this.hasOwnProperty('discount')) {
        if (this.discount != null) {
            dto['discount'] = this.discount.toDto();
        } else {
            dto['discount'] = null;
        }
    }
    if (this.hasOwnProperty('orderItems')) {
        if (this.orderItems != null) {
            dto['orderItems'] = this.orderItems.toDto();
        } else {
            dto['orderItems'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getRefTypeCdLabel(): string {
    return PpOrderGENERATED.getRefTypeCdLabel(this.refTypeCd);
  }


 public  isRefTypeCdCSR_CONSUMER(): boolean {
    const result = (this.refTypeCd === PpOrderGENERATED.REF_TYPE_CD_CSR_CONSUMER);

    return result;
  }

 public  isRefTypeCdCSR_CONTRACT(): boolean {
    const result = (this.refTypeCd === PpOrderGENERATED.REF_TYPE_CD_CSR_CONTRACT);

    return result;
  }


  public getStateLabel(): string {
    return PpOrderGENERATED.getStateLabel(this.state);
  }


 public  isStateDRAFT(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_DRAFT);

    return result;
  }

 public  isStateSCHEDULED(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_SCHEDULED);

    return result;
  }

 public  isStateSEND_NOW(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_SEND_NOW);

    return result;
  }

 public  isStateCANCELED(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_CANCELED);

    return result;
  }

 public  isStatePRINT(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_PRINT);

    return result;
  }

 public  isStateCOMPLETED(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_COMPLETED);

    return result;
  }

 public  isStateFAILED(): boolean {
    const result = (this.state === PpOrderGENERATED.STATE_FAILED);

    return result;
  }


  public getTypeCdLabel(): string {
    return PpOrderGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdFRANCHISE_FEE(): boolean {
    const result = (this.typeCd === PpOrderGENERATED.TYPE_CD_FRANCHISE_FEE);

    return result;
  }

 public  isTypeCdEXTERNAL_FRANCHISE_FEE(): boolean {
    const result = (this.typeCd === PpOrderGENERATED.TYPE_CD_EXTERNAL_FRANCHISE_FEE);

    return result;
  }

 public  isTypeCdOTHER(): boolean {
    const result = (this.typeCd === PpOrderGENERATED.TYPE_CD_OTHER);

    return result;
  }



}
