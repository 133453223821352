
import {Table3Config} from '../extra/table-3-config';

// import {EbInkassoExportGENERATED} from '../_generated/eb-inkasso-export-generated.model';

export class EbInkassoExportTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colChangeTransactionStatusFlg', 'Change transaction status flag', 'changeTransactionStatusFlg', 'changeTransactionStatusFlg');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebFile', 'Webfile ID', 'idWebFile', 'idWebFile');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTotalDebtors', 'Total debtors', 'totalDebtors', 'totalDebtors');
    this.addColumn('colTotalTransactions', 'Total transactions', 'totalTransactions', 'totalTransactions');

*/
  }
}
