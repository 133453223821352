/*BbmTestObj*/
import {EbInkassoExportGENERATED} from '../_generated/eb-inkasso-export-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';

export class EbInkassoExport extends EbInkassoExportGENERATED {

  public static ACTION_DOWNLOAD = 'DOWNLOAD';
  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbInkassoExport();
  }

  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-download mc-cursor-pointer"></i> ${McGod.t('cc.out-factoring.download')}`, EbInkassoExport.ACTION_DOWNLOAD, this, [McGod.PERM_MC_PROCESS_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getCreatedDatetimeString() {
    return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
  }
}
