/*BbmTestObj*/
import {CsrMappingGENERATED} from '../_generated/csr-mapping-generated.model';

export class CsrMapping extends CsrMappingGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrMapping();
  }

  // ---------------------------------------------------------------------
}
