import {Component, OnInit} from '@angular/core';
import {
  EbFactoringList,
  EbFactoring,
  EbFactoringTable3Config,
  EbFactoringFilter,
  McGod,
  McEntity2List, McValueLabelList, McBoolean, McHtmlButton, McEntity2
} from '@miticon-ui/mc-core';
import {McForm} from "@miticon-ui/mc-core";
import {Subject} from "rxjs";

const enum DialogAction {
  ACT_CANCEL = "btn-secondary",
  ACT_REVOKE = "btn-primary"
}

@Component({
  selector: "mc-out-factoring-contract-list-page",
  templateUrl: "./mc-out-factoring-contract-list-page.component.html",
})

export class McOutFactoringContractListPageComponent implements OnInit {

  mcForm = new McForm();
  ebFactoringList: EbFactoringList = new EbFactoringList();
  ebFactoringTable3Config = new EbFactoringTable3Config();
  ebFactoringFilter = new EbFactoringFilter();
  ebFactoringStatusCdVll: McValueLabelList = EbFactoring.getStatusCdVll();
  entitiesWithTransactions = new McEntity2List();
  entitiesWithContractsVll!: McValueLabelList;
  entitiesWithContracts = new McEntity2List();
  ebFactoringTypeCdsVll!: McValueLabelList;
  profileEbFactoringId!: number;
  ebFactoring!: EbFactoring;
  notifyMessage = {message: "", type: ""};
  showProfileMcb = new McBoolean();
  showAddEditPart = new McBoolean();
  showSelectEntityPart = new McBoolean();
  selectedEntityId!: number;
  revokeDialogButtonsKvm = new Map<string, string>();
  showRevokeContractDialogMcb = new McBoolean();
  contractToRevoke!: EbFactoring;
  statusPendingMcb = new McBoolean();
  statusNotPendingMcb = new McBoolean();
  showSelectContractType = new McBoolean();
  mcGod = McGod.getInstance();
  contractType!: string;
  contractName!: string;
  isContractTypeService!: boolean;
  isContractBeingCloned = false;
  editEntityName!: string;
  ebFactoringId!: number;
  entityId!: number;
  entityForCloning!: McEntity2;
  statusCds: any[] = [];
  entityIds: any[] = [];
  clearedAllMultipleFilters = new Subject<boolean>();
  isMultiselectValid = false;


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.ebFactoringList = new EbFactoringList();
    this.ebFactoringList.setPager(0, 100);
    this.entitiesWithContracts.loadAllWhereFactoringParentIsLoggedInEntity(() => {
      this.entitiesWithContracts.items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.entitiesWithContractsVll = this.entitiesWithContracts.toVll('id', 'name');
    });
    this.actLoad();
    this.revokeDialogButtonsKvm.set(DialogAction.ACT_REVOKE, this.mcGod.t('cc.factoring.revoke'));
    this.revokeDialogButtonsKvm.set(DialogAction.ACT_CANCEL, this.mcGod.t('cc.common.edit.cancel'));
  }

  // ---------------------------------------------------------------------


  public actLoad(): void {
      this.statusPendingMcb.setFalse();
      this.ebFactoringFilter = new EbFactoringFilter();
      this.ebFactoringFilter.outFactoringFlg = true;
      this.ebFactoringTypeCdsVll = new McValueLabelList();
      this.ebFactoringTypeCdsVll.add('FACTORING', 'Factoring');
      this.ebFactoringTypeCdsVll.add('SERVICE', 'Service');
      const entitiesIdToStringArr = this.entityIds.map((e: any) => e.toString());
      const filteredEntityIds = entitiesIdToStringArr.filter(arr1 => arr1.length);
      const filteredStatusCds = this.statusCds.filter((arr: []) => arr.length);
      this.ebFactoringFilter.childEntityIds = filteredEntityIds;
      this.ebFactoringFilter.statusCds = filteredStatusCds;
      this.ebFactoringFilter.contractType = this.mcForm.getValue('selTypeCd');
      this.ebFactoringFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');

      this.ebFactoringList.loadByFilter(this.ebFactoringFilter);

  }

  actFactoringTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_PROFILE) {
      this.actShowProfileModal(htmlButton.item);
      if (htmlButton.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
        this.statusPendingMcb.setTrue();
        this.statusNotPendingMcb.setFalse();
      } else {
        this.statusNotPendingMcb.setTrue();
        this.statusPendingMcb.setFalse();
      }
    } else if (htmlButton.actionCdForEvent === EbFactoring.ACTION_SHOW_REVOKE) {
      if (htmlButton.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
        this.contractToRevoke = htmlButton.item;
        this.showRevokeContractDialogMcb.setTrue();
      } else {
        this.notifyMessage.message = this.mcGod.t('cc.out-factoring.you-can-only-revoke-contract-in-status-pending-acceptance');
        this.notifyMessage.type = "error";
      }
    } else if (htmlButton.actionCdForEvent === EbFactoring.ACTION_CLONE) {
      this.showSelectEntityPart.setTrue();
      if (htmlButton.item.typeCd === 'FACTORING') {
        this.isContractTypeService = false;
      }
      if (htmlButton.item.typeCd === 'SERVICE') {
        this.isContractTypeService = true;
      }
      this.isContractBeingCloned = true;
      this.ebFactoringId = htmlButton.item.id;
      this.entityId = htmlButton.item.idChildMcEntity;
      this.contractType = htmlButton.item.typeCd;
      this.ebFactoring = htmlButton.item;
    }
  }

  actClearFilter() {
    this.clearedAllMultipleFilters.next(true);
    this.mcForm.getControl("txbSearchTerm")?.get("value")?.setValue("");
    this.mcForm.getControl("selTypeCd")?.get("value")?.setValue("");
    this.actLoad();
  }

  actShowProfileModal(ebFactoring: EbFactoring) {
    this.profileEbFactoringId = ebFactoring.id;
    this.ebFactoring = ebFactoring;
    this.showProfileMcb.setTrue();
  }

  actRevoke(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    this.ebFactoring.statusCd = EbFactoring.STATUS_CD_REVOKED;
    this.ebFactoring.save(() => {
      this.actLoad();
      this.showRevokeContractDialogMcb.setFalse();
      this.showProfileMcb.setFalse();
      this.notifyMessage.message = `${this.mcGod.t('cc.my-factoring.factoring-contract')}  '${this.ebFactoring.factoringContractName}' ${this.mcGod.t('cc.out-factoring.revoked-successfully')}.`;
      this.notifyMessage.type = "success";
    }, () => {
      this.actLoad();
      this.showRevokeContractDialogMcb.setFalse();
      this.notifyMessage.message = this.ebFactoring.apiErrorMessage;
      this.notifyMessage.type = "error";
    });
  }

  onMcDialogBoxRevokeContractActionButtonClicked(action: string) {
    if (action === DialogAction.ACT_CANCEL) {
      this.showRevokeContractDialogMcb.setFalse();
    } else {
      this.actRevoke(this.contractToRevoke);
    }
  }

  onNext() {
    this.showSelectContractType.setTrue();
    this.showSelectEntityPart.setFalse();
  }

  onPrevious() {
    this.showSelectEntityPart.setTrue();
    this.showAddEditPart.setFalse();
  }

  onFactoringContractSaved(value: EbFactoring) {
    this.notifyMessage.message = `Contract '${value.factoringContractName}' created successfully`;
    this.notifyMessage.type = 'success';
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setFalse();
    this.showAddEditPart.setFalse();
    this.actLoad();
  }

  onFactoringContractCanceled() {
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setFalse();
  }

  onSelectedEntityId(value: any) {
    this.selectedEntityId = value;
  }

  actCancelProfileModal() {
    this.showProfileMcb.setFalse();
    this.profileEbFactoringId = 0;
    this.ebFactoring = new EbFactoring();
  }

  onSelectEntityAndContractTypeNext(value: any) {
    this.contractType = value;
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setTrue();
  }

  onEntityClone(event: McEntity2) {
    this.entityForCloning = event;
    this.selectedEntityId = event.id;
  }

  onCreateNewContract() {
    this.showSelectEntityPart.setTrue();
    this.isContractBeingCloned = false;
  }

  onSelectedStatusCds(selectedStatusCds: any[]) {
    this.statusCds = selectedStatusCds;
  }

  onSelectedEntity(entitiesVll: any[]) {
    this.entityIds = entitiesVll;
  }
}
