import {Component, OnInit} from '@angular/core';
import {
  EbSepaExportList,
  EbSepaExport,
  EbSepaExportFilter,
  EbSepaExportService,
  McValueLabelList,
  McHtmlButton,
  WebFile,
  McBoolean,
  McGod,
  McBankAccountService, EbFactoringService, BaseDirective
} from '@miticon-ui/mc-core';
import {McForm} from '@miticon-ui/mc-core';
import {EbSepaExportTable3Config} from './eb-sepa-export-table-3-config.model';
import * as fileSaver from 'file-saver';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from "ngx-toastr";

enum RevertDialogAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_REVERT = 'btn-primary'
}

@Component({
  selector: 'mc-out-factoring-sepa-out-list-page',
  templateUrl: './mc-out-factoring-sepa-out-list-page.component.html',
})
export class McOutFactoringSepaOutListPageComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  ebSepaExportList: EbSepaExportList = new EbSepaExportList();
  ebSepaExportTable3Config = new EbSepaExportTable3Config();
  ebSepaExportFilter = new EbSepaExportFilter();
  ebSepaExportStatusCdVll: McValueLabelList = EbSepaExport.getStatusCdVll();
  exportTypeCdsVll: McValueLabelList = EbSepaExport.getTypeCdVll();
  bankVll: McValueLabelList = new McValueLabelList();
  bankIds = [];
  clearedAllMultipleFilters = new Subject<boolean>();
  ebSepaExport!: EbSepaExport;
  mcGod = McGod.getInstance();

  notifyMessage = {message: '', type: ''};
  webFile = new WebFile();
  revertButtonsKvm = new Map<string, string>();
  showSentToBankDialogMcb = new McBoolean();
  showRevertDialogMcb = new McBoolean();
  sepaToMarkAsSent!: EbSepaExport;
  revertDialogMessage!: string;
  showUploadFileMcb = new McBoolean();
  selectedSepaExportId = 0;

  // ---------------------------------------------------------------------
  constructor(private ebSepaExportService: EbSepaExportService,
              private mcBankAccountService: McBankAccountService,
              private toastr: ToastrService) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcBankAccountService.bankAccounts.map(item => {
      this.bankVll.add(item.id, item.bankName);
    });

    this.ebSepaExportList.setPager(0, 100);
    this.actLoad();
    this.revertButtonsKvm.set(RevertDialogAction.ACT_REVERT, this.mcGod.t('cc.factoring.export-sepa.revert'));
    this.revertButtonsKvm.set(RevertDialogAction.ACT_CANCEL, this.mcGod.t('cc.common.edit.cancel'));
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebSepaExportFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebSepaExportFilter.statusCds = this.mcForm.getValueArray('selStatusCd');
    this.ebSepaExportFilter.typeCds = this.mcForm.getValue('selTypeCd');
    this.ebSepaExportFilter.bankAccountIds = this.bankIds;
    this.ebSepaExportList.loadByFilter(this.ebSepaExportFilter, () => {});
  }

  onSelectedBank(bankVll: []) {
    this.bankIds = bankVll;
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selStatusCd')?.get('value')?.setValue('');
    this.mcForm.getControl('selTypeCd')?.get('value')?.setValue('');
    this.clearedAllMultipleFilters.next(true);

    this.actLoad();
  }

  actExportSepaTableItemAction(htmlButton: McHtmlButton) {
    if (htmlButton.actionCdForEvent === EbSepaExport.ACTION_REVERT) {
      this.actRevertExportedSepa(htmlButton.item);
    } else if (htmlButton.actionCdForEvent === EbSepaExport.ACTION_SEND_VIA_EBICS) {
      this.ebSepaExportService.sendViaEbics(htmlButton.item.id).pipe(take(1)).subscribe(res => {
        this.actLoad();
      });
    } else if (htmlButton.actionCdForEvent === EbSepaExport.ACTION_DOWNLOAD) {
      this.actDownloadSepaFile(htmlButton.item.idWebfile);
    } else if (htmlButton.actionCdForEvent === EbSepaExport.ACTION_SENT_TO_BANK) {
      this.actSentToBank(htmlButton.item);
    } else if (htmlButton.actionCdForEvent === EbSepaExport.ACTION_UPLOAD_FILE) {
      this.showUploadFileMcb.setTrue();
      this.selectedSepaExportId = htmlButton.item.id;
    }
  }

  actRevertExportedSepa(ebSepaExport: EbSepaExport) {
    this.revertDialogMessage = `${this.mcGod.t('cc.out-factoring.sepa.are-you-sure-you-want-to-revert-the-exported-sepa-named')} "${ebSepaExport.sepaExportFilename}" ${this.mcGod.t('and all of its transactions')}?`;
    this.showRevertDialogMcb.setTrue();
    this.ebSepaExport = ebSepaExport;
  }

  onMcDialogBoxRevertSepaActionButtonClicked(action: string) {
    this.revertDialogMessage = '';
    if (action === RevertDialogAction.ACT_REVERT) {
      if (this.ebSepaExport.statusCd === EbSepaExport.STATUS_CD_EXPORTED) {
        this.ebSepaExport.revertExportedSepa(() => {
          this.actLoad();
          this.notifyMessage.message = `${this.mcGod.t('cc.out-factoring.sepa-file')} "${this.ebSepaExport.sepaExportFilename}" ${this.mcGod.t('cc.out-factoring.sepa.reverted-successfully')}.`;
          this.notifyMessage.type = 'success';
          this.showRevertDialogMcb.setFalse();
        }, () => {
          this.notifyMessage.message = this.ebSepaExport.apiErrorMessage;
          this.notifyMessage.type = 'error';
          this.showRevertDialogMcb.setFalse();
        });
      } else {
        this.notifyMessage.message = `You can only revert sepa files in status "Exported".`;
        this.notifyMessage.type = 'error';
      }
    } else {
      this.showRevertDialogMcb.setFalse();
    }
  }

  onUploadFile($event: any) {
    this.ebSepaExportService.uploadSepaExportFile(this.selectedSepaExportId, $event.file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.toastr.success('Success');
        this.showUploadFileMcb.setFalse();
        this.actLoad();
      }, (error) => {
        this.toastr.error(error.error.message);
      });
  }

  private actDownloadSepaFile(idWebfile: number) {
    this.webFile.typeCd = WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT
    this.webFile.getFileByIdAndType(idWebfile, WebFile.TYPE_CD_EB_BANK_SEPA_EXPORT, () => {
      this.webFile.downloadFile().subscribe(response => {
        fileSaver.saveAs(response, `${this.webFile.filename}`);
      });
    });
  }

  private actSentToBank(ebSepaExport: EbSepaExport) {
    this.sepaToMarkAsSent = ebSepaExport;
    this.showSentToBankDialogMcb.setTrue();
  }
}
