/*BbmTestObj*/
import {CsrRecurringDetailsGENERATED} from '../_generated/csr-recurring-details-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';

export class CsrRecurringDetails extends CsrRecurringDetailsGENERATED {

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrRecurringDetails();
  }

  getWeekDaysVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.every-monday'));
    list.add(2, McGod.t('cc.common.every-tuesday'));
    list.add(3, McGod.t('cc.common.every-wednesday'));
    list.add(4, McGod.t('cc.common.every-thursday'));
    list.add(5, McGod.t('cc.common.every-friday'));
    return list;
  }

  getMonthDaysVll(): McValueLabelList {
    const list = new McValueLabelList();
    for (let i = 1; i < 29; i++) {
      list.add(i, `${McGod.t('cc.jobs.edit.every')} ${i}.`);
    }
    return list;
  }


  getQuarterlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.1st-month-of-the-quarter'));
    list.add(2, McGod.t('cc.common.2nd-month-of-the-quarter'));
    list.add(3, McGod.t('cc.common.3rd-month-of-the-quarter'));
    return list;
  }

  getHalfYearlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.january-july'));
    list.add(2, McGod.t('cc.common.february-august'));
    list.add(3, McGod.t('cc.common.march-september'));
    list.add(4, McGod.t('cc.common.april-october'));
    list.add(5, McGod.t('cc.common.may-november'));
    list.add(6, McGod.t('cc.common.june-december'));
    return list;
  }

  getYearlyVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(1, McGod.t('cc.common.january'));
    list.add(2, McGod.t('cc.common.february'));
    list.add(3, McGod.t('cc.common.march'));
    list.add(4, McGod.t('cc.common.april'));
    list.add(5, McGod.t('cc.common.may'));
    list.add(6, McGod.t('cc.common.june'));
    list.add(7, McGod.t('cc.common.july'));
    list.add(8, McGod.t('cc.common.august'));
    list.add(9, McGod.t('cc.common.september'));
    list.add(10, McGod.t('cc.common.october'));
    list.add(11, McGod.t('cc.common.november'));
    list.add(12, McGod.t('cc.common.december'));
    return list;
  }

  public getMonthCdLabel(): string {
    return CsrRecurringDetails.getTypeCdLabel(this.typeCd);
  }

}
