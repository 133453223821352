import { McGod } from '@miticon-ui/mc-core';

export enum MkTableColumnType {
  ID, STANDARD, ACTION, INNER_HTML, BUTTON_ACTION, SVG_ICON, SELECT
}

export class MkTableColumn {
  constructor(public title: string,
              public propertyName: string,
              public width: number = 100,
              public type: MkTableColumnType = MkTableColumnType.STANDARD,
              public sortPropertyName?: string,
              public navigation?: string[],
              public iconClass?: string,
              public actionCd?: string,
              public options?: any) {
  }
}

export class MkTableConfig {
  columns: MkTableColumn[] = [];
  getNavigation = (id: any) => [`/entity/${McGod.getLoggedEntityIdFromToken()}/transactions/${id}`];


  addColumnStandard(title: string, propertyName: string, width: number = 250, sortPropertyName?: string) {
    this.columns.push(new MkTableColumn(title, propertyName, width, MkTableColumnType.STANDARD, sortPropertyName));
  }

  addColumnSelect(title: string, propertyName: string, options: any, actionCd: string, sortPropertyName?: string) {
    this.columns.push(new MkTableColumn(title, propertyName, 250, MkTableColumnType.SELECT, sortPropertyName, [], '', actionCd, options));
  }

  addColumnSvgIcon(title: string, propertyName: string, sortPropertyName?: string) {
    this.columns.push(new MkTableColumn(title, propertyName, 250, MkTableColumnType.SVG_ICON, sortPropertyName));
  }

  addColumnId(navigation: string[], sortPropertyName?: string) {
    this.columns.push(new MkTableColumn('ID', 'id', 80, MkTableColumnType.ID, sortPropertyName, navigation));
  }

  addColumnInnerHtml(title: string, propertyName: string, sortPropertyName?: string) {
    this.columns.push(new MkTableColumn(title, propertyName, 250, MkTableColumnType.INNER_HTML, sortPropertyName));
  }

  addColumnButtonAction(title: string, propertyName: string, iconClass: string, actionCd: string, sortPropertyName?: string) {
    this.columns.push(new MkTableColumn(title, propertyName, 250, MkTableColumnType.BUTTON_ACTION, sortPropertyName, [], iconClass, actionCd));
  }
}
