import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {McJobsService, McUtilityService, McDateUtils} from "@miticon-ui/mc-core";
import _moment from "moment";

@Component({
  selector: 'mc-job-view',
  templateUrl: './mc-job-view.component.html',
})
export class McJobViewComponent implements OnInit {

  jobData!: any;
  jobID: any;
  jobName!: string;
  jobType!: string;
  jobDescription!: string;
  jobStatus!: string;
  scheduleTime!: string;
  lastFiredTime!: string;
  nextFireTime!: string;
  scheduleType!: string;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  dateObject: McDateUtils;
  constructor(private activatedRoute: ActivatedRoute,
              private jobsService: McJobsService,
              private utilityService: McUtilityService) {
    this.dateObject = McDateUtils;
    this.activatedRoute.paramMap.subscribe(data => {
      this.jobID = data.get('jobId');
      this.getJobData(this.jobID);
    });
  }

  ngOnInit() {

  }

  getJobData(userID: number) {
    this.jobsService.getJobData(userID).subscribe(data => {
      this.jobData = data;

      this.jobName = data.name;
      this.jobType = data.jobClass;
      this.jobDescription = data.description;
      this.jobStatus = data.statusCode.toLowerCase();
      // this.scheduleTime = _moment(data.scheduleTime).format('MMMM Do YYYY, h:mm:ss a');
      if (data.lastFiredTime) {
      this.lastFiredTime = _moment(data.lastFiredTime).format('MMMM Do YYYY, h:mm:ss a'); }
      this.nextFireTime = _moment(data.nextFireTime).format('MMMM Do YYYY, h:mm:ss a');
      this.scheduleType = data.jobType;

    });
  }

  goBack() {
    this.utilityService.goBack();
  }
}
