import {Component, OnInit} from '@angular/core';
import {
  McEbics,
  McForm, McBoolean, McHtmlButton, McBankAccount, McBankAccountList, McBankAccountFilter, McBankAccountTable3Config, EbFactoringList, EbSepaExportService, BaseDirective
} from '@miticon-ui/mc-core';
import * as fileSaver from 'file-saver';
import {takeUntil} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-ebics-ebics-list-page',
  templateUrl: './mc-ebics-ebics-list-page.component.html',
  styleUrls: ['./mc-ebics-ebics-list-page.component.scss']
})
export class McEbicsEbicsListPageComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  mcEbics = new McEbics();
  mcBankAccount = new McBankAccount();
  mcBankAccountList = new McBankAccountList();
  mcBankAccountFilter = new McBankAccountFilter();
  mcBankAccountTable3Config = new McBankAccountTable3Config();
  notifyMessage = {errorMessage: '', successMessage: ''};
  showCreateEbicsMcb = new McBoolean();

  constructor(private ebSepaExportService: EbSepaExportService, private toastr: ToastrService) {
    super();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcBankAccountList = new McBankAccountList();
    this.mcBankAccountList.setPager(0, 100);

    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcBankAccountFilter = new McBankAccountFilter();
    //this.mcBankAccountFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.mcBankAccountList.loadByFilter(this.mcBankAccountFilter, () => {
      this.mcBankAccountList.items = this.mcBankAccountList.items.filter((item) => item.ebicsStatus !== McBankAccount.EBICS_STATUS_NONE);
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }

  onCreateEbicsAccount() {
    this.mcBankAccount = new McBankAccount();
    this.showCreateEbicsMcb.setTrue();
  }

  onTableItemAction(event: McHtmlButton) {
    this.mcEbics = event.item;
    if (event.actionCdForEvent === McEbics.ACTION_EDIT_EBICS_ACCOUNT) {
      this.mcBankAccount = event.item;
      this.showCreateEbicsMcb.setTrue();
    }
    if (event.actionCdForEvent === McEbics.ACTION_EBICS_REPORT) {
      this.ebSepaExportService.downloadEbicsFile(this.mcEbics.id).pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          fileSaver.saveAs(response, `Ebics #${this.mcEbics.id}`);
        }, (error) => {
          this.toastr.error(error.error.message);
        });
    }
  }

  onCanceledEbicsCreation() {
    this.actLoad();
    this.showCreateEbicsMcb.setFalse();
  }
}
