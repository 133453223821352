<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ 'cc.finance.generate-report' | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class='input-wrapper'>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.finance.booking-date-from' | translate}}*</mat-label>
          <input matInput
                 [matDatepicker]="dateFromPicker"
                 formControlName="bookingDateFrom"
                 [max]="maxDate"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker startView="multi-year" (monthSelected)="updateForm($event, dateFromPicker, 'bookingDateFrom')"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.finance.booking-date-to' | translate}}*</mat-label>
          <input matInput
                 [matDatepicker]="dateToPicker"
                 formControlName='bookingDateTo'
                 [max]="maxDate"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateToPicker startView="multi-year" (monthSelected)="updateForm($event, dateToPicker, 'bookingDateTo')"></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            class="primary-btn"
            (click)="generateReport()"
            [disabled]="!form.valid"
            type="submit">
      {{ 'cc.entity.generate' | translate}}
    </button>
  </div>
</ng-container>
