<form [formGroup]="filterForm">
  <mat-form-field *ngIf="type === 0" appearance="outline">
    <mat-label>{{name}}</mat-label>
    <mat-select (valueChange)="valueChangeSingle.emit($event)" [formControlName]="'select' + name">
      <mat-option>{{'cc.my-factoring.all' | translate}}</mat-option>
      <mat-option *ngFor="let item of values" [value]="item.value">
        {{filterLabelCapitalize(item.label)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="type === 1" appearance="outline">
    <mat-label>{{name}}</mat-label>
    <mat-select multiple (valueChange)="onMultiValueChange($event)" [formControlName]="'multiselect' + name" #multiselect>
      <div class="select-all">
        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()">{{"cc.my-factoring.all"| translate}}</mat-checkbox>
      </div>
      <mat-option *ngFor="let item of values" [value]="item" (click)="optionClick()">
        {{filterLabelCapitalize(item.label | translate)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="type === 2" appearance="outline">
    <mat-label>{{name}}</mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="singleDatepickerDateChange($event)" readonly [formControlName]="'singleDate' + name">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field *ngIf="type === 3" appearance="outline">
    <mat-label>{{name}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="{{'cc.factoring.start-date' | translate}}" (dateChange)="startDateChange($event)" [formControlName]="'fromDate' + name">
      <input matEndDate placeholder="{{'cc.factoring.end-date' | translate}}" (dateChange)="endDateChange($event)" [formControlName]="'toDate' + name">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>



