<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">Products</h3>
</div>

<div>
    <lib-mc-loader [showLoader]="mcProductList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="mcProductList.apiErrorFlg">
        Error: {{mcProductList.apiErrorMessage}}
    </div>

  <div class="mc-filter fancy-label">

      <mcc-fi-textbox [isClearEnabled]="true" label="Search" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>


    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">Clear all</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">Filter</button>


  </div>

  <div>
    <mcc-message [errorMsg]="errorMsg" [successMsg]="successMsg"></mcc-message>
  </div>

  <div class="mc-float-right">
    <button class="system-action-btn mt-2 mr-2" (click)="showCreateCategoryMcb.setTrue()"><i
      class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.product.edit.create-new-category' | translate}}
    </button>
    <button class="system-action-btn mt-2" (click)="onCreateProduct()"><i
      class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.product.view.create-new-product' | translate}}
    </button>
  </div>

  <mcc-modal1-wrapper [showMcb]="showCreateCategoryMcb" *ngIf="showCreateCategoryMcb.value" modalSizeCd="small">
    <mc-product-add-category (eventCanceled)="onCreateCategoryCanceled()" (eventSaved)="onCreateCategorySaved()"></mc-product-add-category>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showCreateProductMcb" *ngIf="showCreateProductMcb.value">
    <mc-product-add-edit-product (eventCanceled)="onCreateProductCanceled()" (eventSaved)="onCreateProductSaved($event)" [productId]="mcProduct?.id"></mc-product-add-edit-product>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper [showMcb]="showProductProfileMcb" *ngIf="showProductProfileMcb.value">
    <mc-product-product-profile-part (eventClose)="showProductProfileMcb.setFalse()" [mcProduct]="mcProduct"></mc-product-product-profile-part>
  </mcc-modal1-wrapper>

  <mcc-dialog-box *ngIf="showDeleteProduct.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.product.delete-product' | translate}}" name="delete-product"
                  description="{{'cc.product.are-you-sure-you-want-to-delete-product' | translate}}?"
                  [buttonsKvm]="buttonsDeleteProductDialog"
                  popupType="question"
                  (eventActionButtonClicked)="onDeleteProductDialogButtonsClicked($event)"></mcc-dialog-box>

  <!--<div class="col-md-12 pt-3 px-0 d-flex flex-row justify-content-end">
    <mc-create-category (categoryEvent)="actLoad()" class="mc-blue-link"></mc-create-category>
    <mc-create-edit-product (productEvent)="actLoad()" class="pl-3 mc-blue-link"></mc-create-edit-product>
  </div>-->

<!--    <a routerLink="edit/0">Add New</a>-->

    <div class="col-md-12 p-0" *ngIf="mcProductList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcProductList"
                  [table3Config]="mcProductTable3Config"
                        (eventItemAction)="onTableItemAction($event)"
                 (eventPaginationOrSortChanged)="actLoad()" (eventSelectionChanged)="receiveSelectedItems($event)"></mcc-fi-table-3>
    </div>

 <!-- <mcc-slide-action-set [actionSet]="actionSet" [selectedNumber]="selectedNumber"
                        (eventActionClicked)="slideActionItemClicked($event)"></mcc-slide-action-set>-->

</div>
