import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {FormGroup, Validators} from '@angular/forms';
import {MccFiRadioButtons2EventAfterValidation} from './mcc-fi-radio-buttons2-event-after-validation';
import {McForm, McValueLabel, McValueLabelList} from '@miticon-ui/mc-core';
import { Subscription} from 'rxjs';

@Component({
  selector: 'mcc-fi-radio-buttons2',
  templateUrl: './mcc-fi-radio-buttons2.component.html',
  styleUrls: ['./mcc-fi-radio-buttons2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MccFiRadioButtons2Component implements OnInit, OnDestroy, OnChanges {

  requiredFlg!: Validators;
  validatorList: any = [];
  // radioButtonsForm: FormGroup;
  labelPositionClass!: string;
  public formValidationTriggerSubscription!: Subscription;

  @Input() mcForm!: McForm;
  @Input() extraCssClasses!: string;
  @Input() valueToLabelKvm = new Map<string, string>();
  @Input() name!: string;
  @Input() value: any;
  // @Input() frmGroup: FormGroup;
  @Input() label!: string;
  @Input() infoMessageText!: string;
  @Input() validRequiredFlg = false;
  @Input() removeControlFromMcFormOnDestroy = true;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationInComponent = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  // @Output() eventValidationTurnOn = new EventEmitter();
  // @Input() validationTrigger = false;
  @Input() optionsVll!: McValueLabelList;
  @Input() disabledOptionsVll!: McValueLabelList;
  @Input() directionHorizontal = true;
  @Input() extraCssClass: any;
  @Input() labelOnTop = true;
  @Input() additionalTextForRadioVll!: McValueLabelList;
  @Input() additionalUpperTextForRadio!: string;
  @Input() additionalBottomTextForRadio!: string;


  // @Output() eventSelectionChanged = new EventEmitter<MccFiRadioButtonsEventSelectionChanged>();

  constructor() {
  }

  getRadioForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  setCurrentValue() {
    if (!this.getRadioForm()) {
      const radioForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, radioForm);
      }
    } else {
      this.getRadioForm().controls["value"].setValue(this.value);
      this.getRadioForm().controls["errorFlg"].setValue(false);
      this.getRadioForm().controls["errorMsg"].setValue('');
    }
  }

  ngOnInit() {

    this.validationSetup();

    this.labelPositionCssClass();

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateRadioButton();
      });
    }

    if (this.value === undefined) {
      this.value = null;
    }

    this.setCurrentValue();

  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }

    if (this.removeControlFromMcFormOnDestroy) {
      this.mcForm.formGroup.removeControl(this.name);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      if ((changes["value"].previousValue !== changes["value"].currentValue)) {
        this.setCurrentValue();
      }
    }
    if (changes["validRequiredFlg"]) {
      if ((changes["validRequiredFlg"].previousValue !== changes["validRequiredFlg"].currentValue)) {
        this.validationSetup();
        if (this.getRadioForm()) {
          this.getRadioForm().controls["value"].setValidators(this.validatorList);
          this.getRadioForm().controls["errorFlg"].setValue(false);
          this.getRadioForm().controls["errorMsg"].setValue('');
        }
      }
    }
  }

  validationSetup() {
    this.validatorList = [];
    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

    /*if (this.customValidator) {
      this.validatorList.push(this.customValidator);
    }*/
  }

  validateRadioButton() {
    this.validationSetup();
    this.getRadioForm().controls["value"].setValidators(this.validatorList);
    this.getRadioForm().get('value')?.updateValueAndValidity();
    if (this.getRadioForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getRadioForm().get('value')?.errors);
      if (validators) {
        this.getRadioForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          if (item === 'required') {
            this.getRadioForm().get('errorMsg')?.setValue(`This field is required`);
          } else {
            this.getRadioForm().get('errorMsg')?.setValue(`Some validation`);
          }
        });
      }
    } else {
      this.getRadioForm().get('errorFlg')?.setValue(false);
      this.getRadioForm().get('errorMsg')?.setValue(``);
    }
    this.fireEventAfterValidation();
  }

  labelPositionCssClass() {
    if (this.labelOnTop === true) {
      this.labelPositionClass = 'flex-column';
    } else {
      this.labelPositionClass = 'flex-row';
    }
  }

  public fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiRadioButtons2EventAfterValidation();
      eventObj.validFlg = !this.getRadioForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getRadioForm().get('errorFlg')?.setValue(false);
    this.getRadioForm().get('errorMsg')?.setValue('');
  }

  onRadioChanged(event: any, optionValue: any) {
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(optionValue);
    }
  }

  areButtonsDisabled(option: McValueLabel): boolean {
      return this.disabledOptionsVll.items.includes(option);
  }

  // ---------------------------------------------------------------------
  /*actSelectionChange(selectedValue) {
     const eventData = new MccFiRadioButtonsEventSelectionChanged(selectedValue);
     this.eventSelectionChanged.emit(eventData);
  }*/

}
