
  <h3 class="page-title">{{"cc.common.smart-search-box" | translate}}</h3>
<div>
  <lib-mc-loader [showLoader]="csrConsumerList.apiLoadingFlg"></lib-mc-loader>


  <app-shared-filter-wrapper>
    <div class="filter-container">
      <div class="filter-inputs">
        <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                        (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"
                        tooltipClass="mc-tooltip-length"
                        ngbTooltip="{{'cc.consumer.overview.search-by-consumer-name-consumer-id-email-company-name' | translate}}"
                        placeholder="Search"
                        placement="bottom-left">
        </mcc-fi-textbox>
      </div>

      <div class="filter-actions">
        <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
        <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
      </div>
    </div>
  </app-shared-filter-wrapper>

 <div class="actions-container">
   <span>{{'cc.common.consumers-list' | translate}}</span>
   <div class="actions-wrapper">
     <button *ngIf="mcGod.userHasMcConsumerAddPermission()" class="system-action-btn mr-2" (click)="actShowAddEdit()">
       <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.consumers.view.create-new-consumer' | translate}}
     </button>
     <button *ngIf="mcGod.userHasMcConsumerImportPerformPermission()" class="system-action-btn" (click)="actGoToDataImport()">
       <i class="fas fa-arrow-circle-right mc-add-new-user-icon"></i>{{'cc.consumers.consumers-overview.import-consumers' | translate}}
     </button>
   </div>
 </div>


  <mcc-message [errorMsg]="csrConsumerList.apiErrorMessage"></mcc-message>

  <div class="col-md-12 p-0" *ngIf="csrConsumerList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="csrConsumerList"
                    [table3Config]="csrConsumerTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()"
                    (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
  </div>

  <!--Add Consumer-->
  <mcc-modal1-wrapper *ngIf="showAddEditModalMcb.value" [showMcb]="showAddEditModalMcb">
    <mc-consumer-consumer-add-edit-part [csrConsumerId]="addEditConsumerId" (eventCanceled)="actCancel()"
                                        (eventSaved)="actOnAddEditSaved()">
    </mc-consumer-consumer-add-edit-part>
  </mcc-modal1-wrapper>

  <!--Import Consumers-->
  <mcc-modal1-wrapper *ngIf="showImportConsumersStep1.value" [showMcb]="showImportConsumersStep1">
    <mc-consumer-import-consumers-step-1 (eventCancel)="actCloseImportConsumerStep1()"
                                         (eventUploadSuccess)="actImportConsumersGoToStep2($event)">
    </mc-consumer-import-consumers-step-1>
  </mcc-modal1-wrapper>

  <mcc-modal1-wrapper *ngIf="showImportConsumersStep2.value" [showMcb]="showImportConsumersStep2">
    <mc-consumer-import-consumers-step-2 (eventCancel)="actCloseImportConsumersStep2()"
                                         (eventImported)="importedConsumers($event)"
                                         (eventPrevStep)="actPrevToImportConsumersStep1()"
                                         [webFileId]="webFileId">
    </mc-consumer-import-consumers-step-2>
  </mcc-modal1-wrapper>

</div>

<router-outlet></router-outlet>



