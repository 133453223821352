import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class McDashboardService {
  /*Private variables*/
  private apiUrl: string;


  // Constructor
  constructor(
    private httpClient: HttpClient,
    @Inject("environment") environment: any
  ) {
    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    // this.httpOptions = environment.httpOptions(HttpHeaders);
    // console.log(this.apiUrl);
  }

  // Get dataFromDB
  public dataForCharts() {
    return this.httpClient.get<any>(this.apiUrl + `/core/dashboard`);
  }

  getDashboardData() {
    return this.httpClient.get<any>(
      this.apiUrl + "/mcdashboard/v2/mcdashboard/statistics"
    );
  }

  getSoftwareVersion(): Observable<any> {
    return this.httpClient.get<any>(
      this.apiUrl + "/mcdashboard/v2/mcdashboard/sw-version"
    );
  }
}
