import { McProcessProcessFlowTransactionsEnteringTheFlowEditComponent } from './components/mc-process-process-flow-transactions-entering-the-flow-edit/mc-process-process-flow-transactions-entering-the-flow-edit.component';
import { McProcessProcessItemChangeNextActionDatetimeComponent } from './components/mc-process-process-item-change-next-action-datetime/mc-process-process-item-change-next-action-datetime.component';
import { McProcessPdfTemplateAddEditComponent } from './components/mc-process-pdf-template-add-edit/mc-process-pdf-template-add-edit.component';
import { McProcessPdfTemplateListComponent } from './pages/mc-process-pdf-template-list/mc-process-pdf-template-list.component';
import { McProcessProcessFlowFeesEditComponent } from './components/mc-process-process-flow-fees-edit/mc-process-process-flow-fees-edit.component';
import { McProcessProcessRunItemsProfileComponent } from './components/mc-process-process-run-items-profile/mc-process-process-run-items-profile.component';
import { McProcessLevelProfilePageComponent } from './pages/mc-process-level-profile-page/mc-process-level-profile-page.component';
import { McProcessProcessLevelAddEditComponent } from './components/mc-process-process-level-add-edit/mc-process-process-level-add-edit.component';
import { McProcessProcessFlowCriteriaEditComponent } from './components/mc-process-process-flow-criteria-edit/mc-process-process-flow-criteria-edit.component';
import { McProcessProcessRunListPageComponent } from './pages/mc-process-process-run-list-page/mc-process-process-run-list-page.component';
import { McProcessProcessRunAddEditComponent } from './components/mc-process-process-run-add-edit/mc-process-process-run-add-edit.component';
import { McProcessProcessItemListPageComponent } from './pages/mc-process-process-item-list-page/mc-process-process-item-list-page.component';
import { McProcessFlowLevelProfilePageComponent } from './pages/mc-process-flow-level-profile-page/mc-process-flow-level-profile-page.component';
import { McProcessProcessFlowLevelAddEditComponent } from './components/mc-process-process-flow-level-add-edit/mc-process-process-flow-level-add-edit.component';
import { McProcessProcessFlowAdditionalFlowInfoEditComponent } from './components/mc-process-process-flow-additional-flow-info-edit/mc-process-process-flow-additional-flow-info-edit.component';
import { McProcessProcessFlowGeneralFlowInfoEditComponent } from './components/mc-process-process-flow-general-flow-info-edit/mc-process-process-flow-general-flow-info-edit.component';
import { McProcessFlowProfilePageComponent } from './pages/mc-process-flow-profile-page/mc-process-flow-profile-page.component';
import { McProcessProcessFlowAddEditComponent } from './components/mc-process-process-flow-add-edit/mc-process-process-flow-add-edit.component';
import { McProcessProcessProfilePageComponent } from './pages/mc-process-process-profile-page/mc-process-process-profile-page.component';
import { McProcessProcessAddEditPartComponent } from './components/mc-process-process-add-edit-part/mc-process-process-add-edit-part.component';
import { McProcessProcessListPageComponent } from './pages/mc-process-process-list-page/mc-process-process-list-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McProcessRoutingModule } from './mc-process-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MccFiFileUploadModule, McComponentsModule} from "@miticon-ui/mc-components";
import {McFilterModule, McTableModule} from '@miticon-ui/mc-components';
import {TranslateModule} from "@ngx-translate/core";
import { McProcessProcessFlowCriteriaComponent } from './components/mc-process-process-flow-criteria/mc-process-process-flow-criteria.component';
import {AppSharedModule} from "../app-shared/app-shared.module";
import {McConsumerModule} from "../mc-consumer/mc-consumer.module";
import {McProcessProcessEmailListPageComponent } from './pages/mc-process-process-email-list-page/mc-process-process-email-list-page.component';
import {MatButtonModule} from "@angular/material/button";
import {McProcessConfigurationEditDialogComponent } from './pages/mc-process-configuration-edit-dialog/mc-process-configuration-edit-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import { McProcessEmailViewDialogComponent } from './pages/mc-process-email-view-dialog/mc-process-email-view-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
McProcessProcessFlowTransactionsEnteringTheFlowEditComponent,
McProcessProcessItemChangeNextActionDatetimeComponent,
McProcessPdfTemplateAddEditComponent,
McProcessPdfTemplateListComponent,
McProcessProcessFlowFeesEditComponent,
McProcessProcessRunItemsProfileComponent,
McProcessLevelProfilePageComponent,
McProcessProcessLevelAddEditComponent,
McProcessProcessFlowCriteriaEditComponent,
McProcessProcessRunListPageComponent,
McProcessProcessRunAddEditComponent,
McProcessProcessItemListPageComponent,
McProcessFlowLevelProfilePageComponent,
McProcessProcessFlowLevelAddEditComponent,
McProcessProcessFlowAdditionalFlowInfoEditComponent,
McProcessProcessFlowGeneralFlowInfoEditComponent,
McProcessFlowProfilePageComponent,
McProcessProcessFlowAddEditComponent,
McProcessProcessProfilePageComponent,
McProcessProcessAddEditPartComponent,
McProcessProcessListPageComponent,
McProcessProcessFlowCriteriaComponent,
McProcessProcessEmailListPageComponent,
McProcessConfigurationEditDialogComponent,
McProcessEmailViewDialogComponent,
  ],
  imports: [
    CommonModule,
    McProcessRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppSharedModule,
    MccFiFileUploadModule,
    McConsumerModule,
    SafeHtmlModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatTooltipModule
  ],
  exports: [
McProcessProcessFlowTransactionsEnteringTheFlowEditComponent,
McProcessProcessItemChangeNextActionDatetimeComponent,
McProcessPdfTemplateAddEditComponent,
McProcessPdfTemplateListComponent,
McProcessProcessFlowFeesEditComponent,
McProcessProcessRunItemsProfileComponent,
McProcessLevelProfilePageComponent,
McProcessProcessLevelAddEditComponent,
McProcessProcessFlowCriteriaEditComponent,
McProcessProcessRunListPageComponent,
McProcessProcessRunAddEditComponent,
McProcessProcessItemListPageComponent,
McProcessFlowLevelProfilePageComponent,
McProcessProcessFlowLevelAddEditComponent,
McProcessProcessFlowAdditionalFlowInfoEditComponent,
McProcessProcessFlowGeneralFlowInfoEditComponent,
McProcessFlowProfilePageComponent,
McProcessProcessFlowAddEditComponent,
McProcessProcessProfilePageComponent,
McProcessProcessAddEditPartComponent,
McProcessProcessListPageComponent,
McProcessProcessEmailListPageComponent,
McProcessConfigurationEditDialogComponent,
McProcessRoutingModule,
FormsModule,
  ]
})
export class McProcessModule { }
