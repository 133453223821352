<h3 class="page-title">{{'cc.common.blacklist' | translate}}</h3>

<div>
  <lib-mc-loader [showLoader]="ebConsumerBlacklistList.apiLoadingFlg"></lib-mc-loader>

  <app-shared-filter-wrapper>
    <div class="filter-container">
      <div class="filter-inputs">

        <!-- Search-->
        <mcc-fi-textbox [isClearEnabled]="false" label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                        (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"
                        tooltipClass="mc-tooltip-length"
                        ngbTooltip="{{'cc.out-factoring.search-by-consumer-name-email' | translate}}"
                        placeholder="Search"
                        placement="bottom-left">
        </mcc-fi-textbox>

        <!-- Added by-->
        <mcc-fi-select *ngIf="usersThatBlacklisted.apiSuccessFlg" [mcForm]="mcForm" name="selThatBlacklisted"
                       placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.out-factoring.add-by' | translate}}"
                       [optionsVll]="userThatAreBlacklistedVll">
        </mcc-fi-select>

        <!-- Removed by-->
        <mcc-fi-select *ngIf="usersThatRemovedFromBlacklist.apiSuccessFlg" [mcForm]="mcForm" name="selThatRemovedFromBlacklist"
                       placeholderOption="{{'cc.my-factoring.all' | translate}}" label="{{'cc.out-factoring.removed-by' | translate}}"
                       [optionsVll]="usersThatAreRemovedFromBlacklistVll">
        </mcc-fi-select>
      </div>

      <div class="filter-actions">
        <button class="clear-all" (click)="actClearFilter()">{{'cc.common.clear-all-filters' | translate}}</button>
        <button class="mc-button filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
      </div>
    </div>
  </app-shared-filter-wrapper>

  <div class="actions-container">
    <span>{{'cc.contract.blacklist-management' | translate}}</span>
    <div class="actions-wrapper">
      <button class="system-action-btn" (click)="actShowAddEdit()"><i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.out-factoring.add-consumer-to-blacklist' | translate}}</button>
    </div>
  </div>

    <mcc-modal1-wrapper [showMcb]="showAddEditModalMcb" *ngIf="showAddEditModalMcb.value">
      <mc-out-factoring-blacklist-add-edit-part [ebConsumerBlacklistId]="addEditConsumerId"
      (eventCanceled)="actCloseModal()" (eventSaved)="eventConsumerBlacklistSaved()"></mc-out-factoring-blacklist-add-edit-part>
    </mcc-modal1-wrapper>
  <mcc-modal1-wrapper *ngIf="showRemoveFromBlacklistMcb.value" [showMcb]="showRemoveFromBlacklistMcb" (eventCanceled)="actCloseModal()">
    <app-shared-blacklist-remove-part [ebConsumerBlacklistId]="removeConsumerBlacklist.id" (eventCanceled)="actCloseModal()" (eventRemoved)="eventRemovedFromBlacklist()"></app-shared-blacklist-remove-part>
  </mcc-modal1-wrapper>

  <div *ngIf="ebConsumerBlacklistList.apiErrorFlg">
    Error: {{ebConsumerBlacklistList.apiErrorMessage}}
  </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>

  <div *ngIf="ebConsumerBlacklistList.apiSuccessFlg">
    <mcc-fi-table-3 [objectList]="ebConsumerBlacklistList"
                    [table3Config]="ebConsumerBlacklistTable3Config"
                    (eventPaginationOrSortChanged)="actLoad()" (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
  </div>

</div>

<router-outlet></router-outlet>

