/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbInkassoExport} from '../models/eb-inkasso-export.model';
import {EbInkassoExportService} from '../services/eb-inkasso-export.service';
import {IEbInkassoExport} from '../_generated/eb-inkasso-export.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbInkassoExportGENERATED extends BaseObject {


    public static readonly STATUS_CD_EXPORTED: string = 'EXPORTED';
  public static readonly STATUS_CD_LIST__ALL = [
    EbInkassoExportGENERATED.STATUS_CD_EXPORTED
];

  public apiService: EbInkassoExportService;
  public _rawJson: IEbInkassoExport;
    id: number = 0;
  changeTransactionStatusFlg: boolean;
  idMcEntity: number;
  idWebFile: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  totalDebtors: number;
  totalTransactions: number;
  exportedByUserName: string;  exportFileName: string;

  public properties: string[] = ['id', 'changeTransactionStatusFlg', 'idMcEntity', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalDebtors', 'totalTransactions', 'exportedByUserName', 'exportFileName'];
  public propertiesRegular: string[] = ['id', 'changeTransactionStatusFlg', 'idMcEntity', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'totalDebtors', 'totalTransactions', 'exportedByUserName', 'exportFileName'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbInkassoExportGENERATED.STATUS_CD_EXPORTED, McGod.t('cc.eb-inkasso-export.exported'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbInkassoExportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbInkassoExport {
    console.error('MTCN-ERROR: Not Implemented: EbInkassoExport::createNewInstance(). Add this method to final class and return new EbInkassoExport();');
    throw new Error('Cannot EbInkassoExport::createNewInstance(). Add this method to final class and return new EbInkassoExport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbInkassoExport): EbInkassoExport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbInkassoExportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbInkassoExport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return EbInkassoExportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdEXPORTED(): boolean {
    const result = (this.statusCd === EbInkassoExportGENERATED.STATUS_CD_EXPORTED);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public exportForInkassoAndGet( ebInkassoExport: any, transactionIds: any, delimiter: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.exportForInkassoAndGet(ebInkassoExport,transactionIds,delimiter)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
