import { McProductProductListPageComponent } from './pages/mc-product-product-list-page/mc-product-product-list-page.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ContractsPredefinedListPageComponent} from "./pages/contracts-predefined-list-page/contracts-predefined-list-page.component";

const routes: Routes = [
  {
    path: 'list',
    component: McProductProductListPageComponent
  },
  {
    path: 'contracts-360',
    component: ContractsPredefinedListPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McProductRoutingModule {
}
