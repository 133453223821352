import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  McInvoiceService,
  McManagementService,
  McPermissionService,
  McRoleService,
} from '@miticon-ui/mc-core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import _moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { McTableService } from './mc-table.service';
import { Subscription } from 'rxjs';
import { McPriceAdjustmentComponent } from '../mc-price-adjustment/mc-price-adjustment.component';
import { DiscountBaseConfig } from './discount-base-config';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'mc-table',
  templateUrl: './mc-table.component.html',
})
export class McTableComponent implements OnInit, OnDestroy, OnChanges {
  public rowsPerPageList = [10, 20, 30, 40, 50];
  public initDiscountObject: DiscountBaseConfig = new DiscountBaseConfig();
  public setup!: {
    type?: string;
    isConfigurable?: boolean;
    hasCheckbox?: boolean;
    isDragAndDrop?: boolean;
    tableContent?: any;
    tableRowList?: any;
  };
  public tableSetupConfiguration!: object;
  public tableSetupConfigurationTableContent = false;
  public tableContentList: any[] = [];
  public selectAllRows!: boolean;
  public selectedRowsPerPage!: number;
  public totalPages!: number;
  public currentPage!: number;
  public goToPage!: null;
  public setupExist!: boolean;
  public editable!: boolean;
  public tableConfig: any;
  public _tableContent: any;
  public tableApiCallFinished = false;
  public paginationObject = {
    totalPages: 0,
    currentPage: 0,
    currentPageMinusOne: 0,
    currentPagePlusOne: 0,
    itemsPerPage: 10,
  };
  public totalItems: number;
  public permissionsForRoleId: any;
  public invoiceObject = {
    subtotalValue: null,
    newSubtotal: null,
    VATlist: [],
    finalVatList: {},
    invoiceSummary: 0,
    toPay: 0,
    discount: this.initDiscountObject,
    excludeDiscountedItems: false,
  };
  public messageText = '';
  public messageType = '';
  public designConfig: any;
  public utilityConfig: any;

  /*Permissions*/
  public userCanEditPermission: any[] = [];
  /*Define components*/
  private McPriceAdjustmentComponent = McPriceAdjustmentComponent;

  /*Private variables*/
  private modalSubscription: any;
  private apiGetRolesByIdSubscription: any;
  private settingsConfigurationModalSubscription: any;
  private apiResetProductSubscription!: Subscription;
  private invoiceProductListSubscription: any;
  private lastCreatedInvoiceId!: null;
  private linkedTooltipText!: string;

  /*Events*/
  @Input() set tableContent(value) {
    this._tableContent = Object.assign({}, value);
    this.tableApiCallFinished =
      value && value.hasOwnProperty('isLoaded') && value.isLoaded;
    this.refreshTableContent();
    if(value && value.totalElements) {
      this.totalItems = value.totalElements;
    }
  }

  @Input() entityId!: number;
  @Output() paginationEvent = new EventEmitter<any>();
  @Output() selectedRowsEvent = new EventEmitter<any>();
  @Output() onSendStateToParentComponent = new EventEmitter<any>();
  @Output() sendMessageToParent = new EventEmitter<any>();
  @ViewChildren('permissionsListModal')
  private permissionsListModal: any;
  @ViewChildren('tableSettings')
  private tableSettings: any;

  /*Constructor*/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private permissionService: McPermissionService,
    private managementService: McManagementService,
    private invoiceService: McInvoiceService,
    private viewContainerRef: ViewContainerRef,
    private translate: TranslateService,
    public tableService: McTableService,
    private injector: Injector,
    private roleService: McRoleService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    @Inject('tableConfig') tableConfig: any,
    @Inject('designConfig') designConfig: any,
    @Inject('utilityConfig') utilityConfig: any,
    @Inject('allPermissions') allPermissions: any
  ) {
    /*Store table config*/
    this.tableConfig = tableConfig;
    this.designConfig = designConfig;
    this.utilityConfig = utilityConfig;

    this._MatPaginatorIntl.itemsPerPageLabel = this.translate.instant("mem.table.page-rows");

    this.initDiscountObject = new DiscountBaseConfig();

    /*Configure permissions for page*/
    this.userCanEditPermission = [
      allPermissions.MC_ENTITY_CAN_UPDATE,
      allPermissions.MC_SYSTEM_USER_CAN_UPDATE,
      allPermissions.MC_LOGIN_CAN_UPDATE,
      allPermissions.MC_LOGIN_ADMIN,
    ];
  }


  /*Get table content*/
  get tableContent() {
    return this._tableContent;
  }


  /*Open modal*/
  public open(content: {}, id: any, labelValue: any): void {
    /*If attributes is no exists, stop method*/
    if (!content || !id || labelValue <= 0) {
      return;
    }

    /*Get permissions by role ID*/
    this.apiGetRolesByIdSubscription = this.managementService
      .getPermissionsByRoleId(id)
      .subscribe(
        (response) => {
          this.permissionsForRoleId = response;
          /*Open modal*/
          // @ts-ignore
          const { _results } = this[content];
          this.modalSubscription = this.modalService
            .open(_results[0], {
              centered: true,
              windowClass: 'mc-permissions-by-role-modal',
              size: 'lg',
            })
            .result.then(
              (result) => {},
              (reason) => {
                this.permissionsForRoleId = null;
              }
            );
        },
        (error) => {
          this.permissionsForRoleId = null;
        }
      );
  }

  /*On init class*/
  ngOnInit() {
    this.invoiceService.lastCreatedInvoice.subscribe(
      (invoiceId) => (this.lastCreatedInvoiceId = invoiceId)
    );
  }

  /*Open settings configuration modal*/
  public openSettingsConfigurationModal(modal: any): void {
    /*If attribute is no exists, stop method*/
    if (!modal) {
      return;
    }

    // @ts-ignore
    this.tableSetupConfiguration.tableContent.forEach(
      (configTableColumnItem: any) =>
        (configTableColumnItem.selected =
          // @ts-ignore
          this.setup.tableContent.findIndex(
            (setupTableColumnItem: { id: any }) =>
              setupTableColumnItem.id === configTableColumnItem.id
          ) >= 0 || false)
    );

    /*Open modal*/
    // @ts-ignore
    this.settingsConfigurationModalSubscription = this.modalService.open(
      // @ts-ignore
      this[modal]._results[0],
      {
        centered: true,
        windowClass: 'mc-table-settings-modal',
        size: 'lg',
      }
    );
  }

  /*On select row*/
  public onSelectRow(selectedItem: any) {
    this.selectAllRows = false;

    if (selectedItem.statusCode === 'inactive') {
      return;
    }
    this.tableContentList.filter((item) => {
      if (item.id === selectedItem.id) {
        item.selected = !item.selected;
      }
    });
    this.handleSelectedRows();
  }

  /*On select all rows*/
  public onSelectAllRows() {
    this.selectAllRows = !this.selectAllRows;
    this.tableContentList.filter((item) => {
      if (item.statusCode !== 'inactive') {
        item.selected = this.selectAllRows;
        this.selectedRowsEvent.emit(item);
      }
    });
    this.handleSelectedRows();
  }

  /*Handle selected rows event listener*/
  public handleSelectedRows() {
    const selectedRows: any[] = [];
    const possibleSelectedRows = this.tableContentList.filter(
      (contetnItem) => contetnItem.statusCode !== 'inactive'
    );

    this.tableContentList.forEach((item) => {
      if (item.selected && item.statusCode !== 'inactive') {
        selectedRows.push(item);
      }
    });
    this.selectedRowsEvent.emit(selectedRows);
    if (
      possibleSelectedRows.length > 0 &&
      possibleSelectedRows.length === selectedRows.length
    ) {
      this.selectAllRows = true;
    }
  }

  /*On sort column*/
  public onColumnSort(id: number, sort: string) {
    // @ts-ignore
    const orderByKey = this.setup.tableRowList.find(
      (item: any) => item.id === id
    );

    this.tableContentList.sort((a, b) => {
      if (sort === 'ascending') {
        if (isNaN(a[orderByKey.label])) {
          return a[orderByKey.label].localeCompare(b[orderByKey.label]);
        } else {
          return a[orderByKey.label] - b[orderByKey.label];
        }
      }
      if (sort === 'descending') {
        if (isNaN(b[orderByKey.label])) {
          return b[orderByKey.label].localeCompare(a[orderByKey.label]);
        } else {
          return b[orderByKey.label] - a[orderByKey.label];
        }
      }
    });

    // @ts-ignore
    this.setup.type === 'invoice' && this.onProceedInvoice();
  }

  /*On select page and size per page*/
  public onPagination(pageNumber: any) {
    this.goToPage = null;

    /*If page number is 0 don't emit pagination*/
    if (
      pageNumber < 1 ||
      pageNumber > this.paginationObject.totalPages ||
      pageNumber === this.paginationObject.currentPage
    ) {
      return;
    }

    /*Pagination object*/
    const pagination = {
      size: Number(this.paginationObject.itemsPerPage),
      page: Number(pageNumber) - 1,
    };

    /*Emit pagination*/
    this.paginationEvent.emit(pagination);
  }

  /*On select rows per page*/
  public onSelectSizePerPage() {
    /*Pagination object*/
    const pagination = {
      size: Number(this.paginationObject.itemsPerPage),
      page: Number(this.paginationObject.currentPage)
    };

    /*Emit pagination*/
    this.paginationEvent.emit(pagination);
  }

  onPageChanged(event: PageEvent) {
    this.paginationObject.currentPage = event.pageIndex;
    this.paginationObject.itemsPerPage = event.pageSize;
    this.totalItems = event.length;
    this.onSelectSizePerPage();
  }

  public onRedirect(
    page: any,
    paramAttribute: any,
    contentId: any,
    labelValue: any,
    routePermission: any
  ) {
    /*Check if route permission exists*/
    if (routePermission) {
      /*Stop propagation if user does not hav permission*/
      if (!this.permissionService.hasUserPermission(routePermission)) {
        return;
      }
    }
    const paramObject: any = {};
    /*If labelValue is not a number and less than 1 - stop method*/
    if (!isNaN(labelValue) && labelValue < 1) {
      return;
    }
    /*If page and contentID exists*/
    if (page && contentId) {
      /*todo: this part of code will be changed, when we change logic about switching between tables (set filter and select entity)*/
      if (paramAttribute) {
        paramObject[paramAttribute] = contentId;
        this.router.navigate([page(this.entityId), paramObject]);
      } else {
        this.router.navigateByUrl(`${page(this.entityId, contentId)}`);
      }
    }
  }

  /*Refresh table content*/
  private refreshTableContent(): any {
    /*Setup for table module
     * Check if tableConfig exist*/
    this.tableSetupConfiguration =
      this.tableConfig.find(
        (item: { name: any }) => item.name === this.tableContent._name
      );

    /*Check if setup is found*/
    if (!this.tableSetupConfiguration) {
      this.clearTableContent();
      return null;
    }

    // Format birthday
    const attributeName = this.tableContent.content;
    attributeName.forEach(
      (item: {
        attributeName: string;
        newValue: _moment.MomentInput;
        oldValue: _moment.MomentInput;
      }) => {
        if (item.attributeName === 'birthday') {
          item.newValue = _moment(item.newValue).format('LL');
          if (item.oldValue !== 'None') {
            item.oldValue = _moment(item.oldValue).format('LL');
          }
        }
      }
    );

    this.setup = Object.assign({}, this.tableSetupConfiguration);
    this.editable = this.tableContent.editable;
    this.setupExist = true;
    // @ts-ignore

    this.tableContentList =
      // @ts-ignore
      this.setup.type === 'regular' ||
      // @ts-ignore
      this.setup.name === 'invoice_summary'
        ? this.tableContent.content
        : this.tableContentList;
    this.goToPage = null;
    this.selectAllRows = false;

    if (this.tableContent.hasOwnProperty('pageable')) {
      this.paginationObject = {
        totalPages:
          this.tableContent.totalPages === 0
            ? this.tableContent.totalPages + 1
            : this.tableContent.totalPages,
        currentPage: this.tableContent.pageable.pageNumber + 1,
        currentPageMinusOne: this.tableContent.pageable.pageNumber,
        currentPagePlusOne: this.tableContent.pageable.pageNumber + 2,
        itemsPerPage: this.tableContent.size,
      };
    }

    // @ts-ignore
    if (this.setup.name === 'addProduct') {
      this.invoiceProductListSubscription =
        this.invoiceService.invoiceList.subscribe((list) => {
          if (list.length > 0) {
            list.map((item) => {
              this.tableContentList = this.tableContentList.map(
                (contentItem) => {
                  // @ts-ignore
                  if (contentItem.id === item.id) {
                    contentItem.selected = true;
                  }
                  return (contentItem = Object.assign({}, contentItem));
                }
              );
            });
          }
        });
    }

    /*For invoice table*/
    // @ts-ignore
    if (this.setup.type === 'invoice') {
      this.invoiceProductListSubscription =
        this.invoiceService.invoiceList.subscribe((list) => {
          /*Add product to content list*/
          this.tableContentList = list;
        });
      if (
        this.tableContentList &&
        this.tableContentList.length > 0 &&
        this.tableContentList[0].hasOwnProperty('orderItemSorterId')
      ) {
        this.tableContentList = this.tableContentList.sort(
          (a, b) => a.orderItemSorterId - b.orderItemSorterId
        );
      }
      this.tableContentList.map((product) => {
        product.discount =
          product.discount || this.initDiscountObject;
        product.totalPrice =
          product.totalPrice || product.quantity * product.unitPrice;
        this.onCalculateDiscount(product);
        return product;
      });

      this.invoiceObject.discount =
        this.tableContent.discount || this.initDiscountObject;
      this.onSubtotal();
      this.checkVAT();
      this.invoiceSummary();
      this.onProceedInvoice();
    }
  }

  /*On drag and drop*/
  public onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.tableContentList = event.container.data.map((item, index) => {
      // @ts-ignore
      item.orderItemSorterId = index + 1;
      return item;
    });
    event.container.data.forEach((item, index) => {
      // @ts-ignore
      item.orderItemSorterId = index + 1;
      this.invoiceService.updateProductInInvoice(index, item);
    });
    this.onProceedInvoice();
  }

  /*On select table row*/
  public onSelectTableRowItem(columnItem: any): void {
    columnItem.selected = !columnItem.selected;
    // @ts-ignore
    this.tableSetupConfigurationTableContent =
      // @ts-ignore
      this.tableSetupConfiguration.tableContent.filter(
        (contentItem: any) => contentItem.selected
      ).length === 0;
  }

  /*Set value for input field*/
  public setInputValue(content: any, td: any) {
    if (td.conf.eval) {
      // this.onSubtotal();
      // this.checkVAT();
      return td.hasOwnProperty('toFix')
        ? this.tableService.round(
            td.conf.eval(
              content[td.conf.unit1],
              content[td.conf.unit2]
            ),
            td.toFix
          )
        : td.conf.eval(
            content[td.conf.unit1],
            content[td.conf.unit2]
          );
    }
    return content[td.label] ? content[td.label] : td.conf.value;
  }

  /*Subtotal*/
  public onSubtotal() {
    // @ts-ignore
    this.invoiceObject.subtotalValue = this.tableService.round(
      this.tableContentList
        .map(
          (product) =>
            Number(product.totalPrice) - Number(product.discount.amount)
        )
        .reduce((a, b) => a + b, 0),
      // @ts-ignore
      this.setup.invoiceSummaryToFix
    );
    return this.invoiceObject.subtotalValue;
  }

  /*New subtotal*/
  public newSubtotal() {
    // @ts-ignore
    return (this.invoiceObject.newSubtotal =
      this.invoiceObject.VATlist.length === 1
        ? this.tableService.round(
            Number(this.invoiceObject.subtotalValue) -
              Number(
                this.tableService.round(this.invoiceObject.discount.amount, 2)
              ),
            // @ts-ignore
            this.setup.invoiceSummaryToFix
          )
        : this.invoiceObject.subtotalValue);
  }

  /*On change invoice value*/
  public onChangeInvoiceValue(content: any) {
    /*Do not allow - sign*/
    /*TODO: find better solution - new table.*/
    content.quantity = String(content.quantity).match(/\-/g)
      ? content.quantity.replace(/\-/g, '')
      : content.quantity;
    content.vat = String(content.vat).match(/\-/g)
      ? content.vat.replace(/\-/g, '')
      : content.vat;
    content.unitPrice = String(content.unitPrice).match(/\-/g)
      ? content.unitPrice.replace(/\-/g, '')
      : content.unitPrice;

    /*Fixed to decimals*/
    // @ts-ignore
    content.quantity =
      content.quantity &&
      !String(content.quantity).match(/^\d+(?:\.\d{1,2})?$/g)
        ? this.tableService.toFixedTrunc(
            content.quantity,
            // @ts-ignore
            this.setup.invoiceSummaryToFix
          )
        : content.quantity;
    // @ts-ignore
    content.vat =
      content.vat && !String(content.vat).match(/^\d+(?:\.\d{1,2})?$/g)
        ? this.tableService.toFixedTrunc(
            content.vat,
            // @ts-ignore
            this.setup.invoiceSummaryToFix
          )
        : content.vat;
    // @ts-ignore
    content.unitPrice =
      content.unitPrice &&
      !String(content.unitPrice).match(/^\d+(?:\.\d{1,2})?$/g)
        ? this.tableService.toFixedTrunc(
            content.unitPrice,
            // @ts-ignore
            this.setup.invoiceSummaryToFix
          )
        : content.unitPrice;

    content.totalPrice = content.quantity * content.unitPrice;
    this.onProceedInvoice();
  }

  /*Check VAT numbers*/
  private checkVAT() {
    // @ts-ignore
    this.invoiceObject.VATlist = Array.from(
      new Set(this.tableContentList.map((item) => String(Number(item.vat))))
    );

    Object.keys(this.invoiceObject.finalVatList).forEach((key) => {
      const vatExist = this.invoiceObject.VATlist.some((item) => item === key);
      if (!vatExist) {
        // @ts-ignore
        delete this.invoiceObject.finalVatList[key];
      }
    });
  }

  /*VAT summary*/
  public VATsubtotal(vat: any) {
    this.checkVAT();
    const defVat = Number(vat);
    let totalsForVAT = 0;

    if (this.invoiceObject.VATlist.length > 1) {
      this.tableContentList.map((content) => {
        if (Number(content.vat) === defVat) {
          if (content.discount.value > 0) {
            // @ts-ignore
            totalsForVAT += Number(
              this.tableService.round(
                this.calculatePercentage(
                  content.unitPrice - content.discount.amountPerOneQuantity,
                  defVat
                ),
                // @ts-ignore
                this.setup.invoiceSummaryToFix
              ) * content.quantity
            );
          } else {
            // @ts-ignore
            totalsForVAT += Number(
              this.tableService.round(
                this.calculatePercentage(content.unitPrice, defVat),
                // @ts-ignore
                this.setup.invoiceSummaryToFix
              ) * content.quantity
            );
          }
          // totalsForVAT += Number(content.discount.value > 0 ? content.totalPrice - content.discount.amount : content.totalPrice);
        }
      });
    } else {
      if (this.invoiceObject.discount.amount == 0) {
        this.tableContentList.map((content) => {
          if (Number(content.vat) === defVat) {
            if (content.discount.value > 0) {
              // @ts-ignore
              totalsForVAT += Number(
                this.tableService.round(
                  this.calculatePercentage(
                    content.unitPrice - content.discount.amountPerOneQuantity,
                    defVat
                  ),
                  // @ts-ignore
                  this.setup.invoiceSummaryToFix
                ) * content.quantity
              );
            } else {
              // @ts-ignore
              totalsForVAT += Number(
                this.tableService.round(
                  this.calculatePercentage(content.unitPrice, defVat),
                  // @ts-ignore
                  this.setup.invoiceSummaryToFix
                ) * content.quantity
              );
            }
            // totalsForVAT += Number(content.discount.value > 0 ? content.totalPrice - content.discount.amount : content.totalPrice);
          }
        });
      } else {
        // @ts-ignore
        totalsForVAT = this.tableService.round(
          this.calculatePercentage(this.invoiceObject.newSubtotal, defVat),
          // @ts-ignore
          this.setup.invoiceSummaryToFix
        );
      }
    }
    //   totalsForVAT = this.invoiceObject.newSubtotal;
    // @ts-ignore
    this.invoiceObject.finalVatList[vat] = this.tableService.round(
      totalsForVAT,
      // @ts-ignore
      this.setup.invoiceSummaryToFix
    );
    // }
    // @ts-ignore
    return this.tableService.round(
      totalsForVAT,
      // @ts-ignore
      this.setup.invoiceSummaryToFix
    );
  }

  public onCalculateAdditionalDiscount(): string {
    if (!this.invoiceObject.discount.excludeDiscountedItems) {
      // @ts-ignore
      this.invoiceObject.discount.amount = this.tableService.round(
        this.invoiceObject.discount.valueTypeCd === 'ABSOLUTE_VALUE'
          ? this.invoiceObject.discount.value
          : this.tableService.round(
              this.calculatePercentage(
                this.invoiceObject.subtotalValue,
                this.invoiceObject.discount.value
              ),
              2
            ),
        // @ts-ignore
        this.setup.invoiceSummaryToFix
      );
    } else {
      let notDiscountedItemsSubtotal = 0;
      this.tableContentList.map((product) => {
        if (!product.discount.value) {
          notDiscountedItemsSubtotal += product.totalPrice;
        }
      });

      // @ts-ignore
      this.invoiceObject.discount.amount = this.tableService.round(
        this.invoiceObject.discount.valueTypeCd === 'ABSOLUTE_VALUE'
          ? this.invoiceObject.discount.value
          : this.tableService.round(
              this.calculatePercentage(
                notDiscountedItemsSubtotal,
                this.invoiceObject.discount.value
              ),
              2
            ),
        // @ts-ignore
        this.setup.invoiceSummaryToFix
      );
    }
    this.invoiceObject.excludeDiscountedItems =
      this.invoiceObject.discount.excludeDiscountedItems;
    this.onProceedInvoice();
    // @ts-ignore
    return this.invoiceObject.discount.amount;
  }

  /*Calculate price discount*/
  public onCalculateDiscount(product: any) {
    if (product) {
      // product.discount.amount = this.tableService.round(product.discount.valueType === "ABSOLUTE_VALUE" ? product.discount.value * product.quantity : this.tableService.round(this.calculatePercentage(product.totalPrice, product.discount.value), this.setup["invoiceSummaryToFix"]), this.setup["invoiceSummaryToFix"]);
      // @ts-ignore
      product.discount.amountPerOneQuantity = this.tableService.round(
        product.discount.valueTypeCd === 'ABSOLUTE_VALUE'
          ? product.discount.value
          : this.calculatePercentage(product.unitPrice, product.discount.value),
        // @ts-ignore
        this.setup.invoiceSummaryToFix
      );
      // @ts-ignore
      product.discount.amount = Number(
        this.tableService.round(
          product.discount.amountPerOneQuantity * product.quantity,
          // @ts-ignore
          this.setup.invoiceSummaryToFix
        )
      );
      product.totalDiscount = product.discount.amount
        ? `-${product.discount.amount}`
        : '0';
    }
  }

  public totalDiscount() {
    let totalDiscount = 0;
    this.tableContentList.map(
      (item) => (totalDiscount += Number(item.discount.amount))
    );
    totalDiscount += Number(this.invoiceObject.discount.amount);
    // @ts-ignore
    return totalDiscount
      ? `-${this.tableService.round(
          totalDiscount,
          // @ts-ignore
          this.setup.invoiceSummaryToFix
        )}`
      : totalDiscount;
  }

  /*Calculate additional discount*/
  public calculatePercentage(
    amount: number | null,
    percentage: number
  ): number {
    return (Number(amount) * percentage) / 100;
  }

  /*Show new subtotal*/
  public showNewSubtotal(): boolean {
    return (
      Number(this.invoiceObject.newSubtotal) ===
        Number(this.invoiceObject.subtotalValue) ||
      this.tableContentList.some((product) => product.discount.value === 0) ||
      this.invoiceObject.discount.value === 0
    );
  }

  /*Invoice summary*/
  public invoiceSummary() {
    let invoiceSummary = 0;
    // if (this.invoiceObject.VATlist.length > 1) {
    //   this.tableContentList.map(content => {
    //     invoiceSummary += Number(this.calculatePercentage(Number(content.discount.value > 0 ? content.totalPrice - content.discount.amount : content.totalPrice), Number(content.vat))) + Number(content.discount.value > 0 ? content.totalPrice - content.discount.amount : content.totalPrice);

    // if (content.discount.value > 0) {
    //   invoiceSummary += Number(this.tableService.round(this.calculatePercentage(content.unitPrice - content.discount.amountPerOneQuantity, Number(vat)), this.setup["invoiceSummaryToFix"]) * content.quantity);
    // }
    // else {
    //   invoiceSummary += Number(this.tableService.round(this.calculatePercentage(content.unitPrice, Number(vat)), this.setup["invoiceSummaryToFix"]) * content.quantity);
    // }
    // });
    // } else {
    //   invoiceSummary = Number(this.calculatePercentage(this.invoiceObject.newSubtotal, this.invoiceObject.VATlist[0]) + Number(this.newSubtotal()));
    // }
    Object.keys(this.invoiceObject.finalVatList).forEach((key) => {
      // @ts-ignore
      invoiceSummary += Number(this.invoiceObject.finalVatList[key]);
    });
    this.invoiceObject.invoiceSummary = invoiceSummary += Number(
      this.invoiceObject.newSubtotal
    );
    // @ts-ignore
    return this.tableService.round(
      invoiceSummary,
      // @ts-ignore
      this.setup.invoiceSummaryToFix
    );
  }

  /*Remove product from list*/
  // @ts-ignore
  public removeProductFromInvoice(productIndex, useService = true) {
    // const productIndex = this.tableContentList.findIndex(productItem => productItem.id === product.id);
    //
    // if (productIndex > -1) {
    //   this.tableContentList.splice(productIndex, 1);
    if (useService) {
      this.invoiceService.removeProductFromInvoice(productIndex);
    }

    this.onProceedInvoice();
    // }
  }

  /*Reset product*/
  public resetProductInInvoice(
    product: { id: any; name: any },
    productIndex: string | number
  ) {
    /*Get product from DB*/
    this.apiResetProductSubscription = this.managementService
      .getProductById(product.id)
      .subscribe(
        (response) => {
          if (response) {
            const defineProduct = {
              ...response,
              quantity: 1,
              // @ts-ignore
              totalPrice: 1 * response.unitPrice,
              // @ts-ignore
              idVat: String(response.vat),
              discount: {
                value: 0,
                amount: 0,
                valueTypeCd: 'PERCENTAGE',
                typeCd: 'DISCOUNT',
              },
            };
            // @ts-ignore
            this.tableContentList[productIndex] = defineProduct;
            this.invoiceService.updateProductInInvoice(
              productIndex,
              defineProduct
            );
            this.invoiceObject.discount = this.initDiscountObject;
            this.onCalculateAdditionalDiscount();
            this.onProceedInvoice();
          }
        },
        (error) => {
          /*Translate and sent message/type to parent to display it*/
          this.translate
            .get('cc.table.product-does-not-exist', {
              productName: product.name,
            })
            .subscribe((translateResponse: string) => {
              this.sendMessageToParent.emit({
                message: translateResponse,
                type: 'error',
              });
            });
        }
      );
  }

  /*Proceed invoice*/
  public onProceedInvoice() {
    // @ts-ignore
    this.invoiceService._invoiceList = this.tableContentList;
    this.onSendStateToParentComponent.emit({
      orderItems: this.tableContentList,
      discount: this.invoiceObject.discount,
      excludeDiscountedItems:
        this.invoiceObject.discount.excludeDiscountedItems,
    });
  }

  /*On apply memo*/
  public onApplyMemo(value: any, index: number, above: any) {
    this.tableContentList = this.tableContentList.map(
      (contentItem, contentItemIndex) => {
        if (!above && contentItemIndex >= index) {
          contentItem.note = value;
        } else if (above && contentItemIndex <= index) {
          contentItem.note = value;
        }
        contentItem.isOpenMemo = false;
        return contentItem;
      }
    );
    this.onProceedInvoice();
  }

  /*Open memo*/
  public openOptions(item: { isOpenMemo: boolean }) {
    item.isOpenMemo = !item.isOpenMemo;
  }

  /*format td value*/
  public formatDisplayValue(
    content: { [x: string]: any },
    td: {
      [x: string]: string | number; // @ts-ignore
      hasOwnProperty: (arg0: string) => any;
    }
  ) {
    if (td.hasOwnProperty('toFix') && content[td['label']]) {
return this.tableService.round(content[td['label']], td['toFix']);
}

    if (td.hasOwnProperty('formatDate') && content[td['label']]) {
      // @ts-ignore
      return (
        _moment(content[td['label']])
          .zone(this.utilityConfig.timeZone.europeBerlin)
          // @ts-ignore
          .format(td['formatDate'])
      );
    }

    return content[td['label']];
  }

  /*On set table column*/
  public onChangeTableColumnSetup(): any {
    // @ts-ignore
    const selectedColumns = this.tableSetupConfiguration.tableContent.filter(
      (contentItem: { selected: any }) => contentItem.selected
    );

    if (!selectedColumns || selectedColumns.length === 0) {
      return (this.tableSetupConfigurationTableContent = true);
    } else {
      this.tableSetupConfigurationTableContent = false;
    }
    // @ts-ignore
    const contentRowList = this.tableSetupConfiguration.tableRowList.filter(
      (rowItem: { id: any }) =>
        selectedColumns.find(
          (selectedColumn: { id: any }) => selectedColumn.id === rowItem.id
        )
    );

    if (contentRowList) {
      // @ts-ignore
      this.setup.tableContent = selectedColumns.sort((a, b) => a.id - b.id);
      // @ts-ignore
      this.setup.tableRowList = contentRowList.sort((a, b) => a.id - b.id);
      this.settingsConfigurationModalSubscription.close();
    }
  }

  /*Clear table content*/
  private clearTableContent() {
    this.setupExist = false;
    this.editable = false;
    this.setup = Object.assign({}, {});
    this.tableContentList = [];
    this.selectedRowsPerPage = 0;
    this.totalPages = 0;
    this.currentPage = 0;
  }

  /*Check condition for showing icon*/
  public checkCondition(content: any, showingCondition: string): boolean {
    return !showingCondition ? true : !eval(showingCondition);
  }

  /*On receive state from child component*/
  public onReceiveFromChild(event: any): void {
    event && this.onSendStateToParentComponent.emit(event);
  }

  /*Validate discount*/
  public onValidatingDiscount() {
    const discounts = Array.from(
      new Set([
        ...this.tableContentList.map((item) => item.discount),
        this.invoiceObject.discount,
      ])
    );
    this.sendMessageToParent.emit(
      discounts.some((item) => item.isValid === false)
    );
  }

  /*Format status code*/
  public formatStatusCode(status: string) {
    return status && status.toLowerCase();
  }

  /*Image column style*/
  public imageColumnStyle(label: string) {
    return {
      width: label === 'image' ? '50px' : 'auto',
    };
  }

  /*Image column CSS class*/
  public imageColumnCssClass(label: string) {
    return label === 'image' ? 'p-0 m-0' : '';
  }

  /*Horizontal scrrol in table view*/
  public handleHorizontalScroll() {
    return '';
    // return this.setup["type"] === "invoice" || this.setup['name'] === 'invoice_summary' ? 'mc-table-horizontal-scroll': '';
  }

  /*Add product button CSS class*/
  public addProductCssClass(rowContent: { selected: any }) {
    return rowContent.selected ? 'mc-warning-button' : 'mc-success-button';
  }

  /*Set css style for disabling additional discount*/
  public setCssStyleOnDisableAdditionalDiscount() {
    const flag = this.invoiceObject.VATlist.length > 1;
    flag && (this.invoiceObject.discount = new DiscountBaseConfig());
    return { opacity: flag ? 0.5 : 1 };
  }

  /*On apply options*/
  public onApplyOptions(
    event: { objectProperty: string | number; bellow: any },
    item: { [x: string]: any },
    index: number
  ) {
    const property = item[event.objectProperty];
    this.tableContentList = this.tableContentList.map(
      (productItem, productIndex) => {
        if (event.bellow && productIndex >= index) {
          productItem[event.objectProperty] = Object.assign({}, property);
        } else if (!event.bellow && productIndex <= index) {
          productItem[event.objectProperty] = Object.assign({}, property);
        }
        this.onCalculateDiscount(productItem);
        return productItem;
      }
    );
  }

  public onChangeDescriptionAndMemo() {
    // this.invoiceService.updateProductInInvoice(contentIndex, content);
  }

  ngOnChanges() {
    // console.log('CHANGES...........', changes);
  }

  /*On destroy class*/
  ngOnDestroy() {
    this.apiGetRolesByIdSubscription &&
      this.apiGetRolesByIdSubscription.unsubscribe();
    this.invoiceProductListSubscription &&
      this.invoiceProductListSubscription.unsubscribe();
    this.apiResetProductSubscription &&
      this.apiResetProductSubscription.unsubscribe();
    // this.entityRoleTemplateSubscription && this.entityRoleTemplateSubscription.unsubscribe();
    // @ts-ignore
    this.setup.type === 'invoice' && this.invoiceService.clearInvoiceList();
  }

  setHighLightCssClass(content: any, el: Element): string {
    // @ts-ignore
    if (this.setup.name === 'invoiceOverview' && this.lastCreatedInvoiceId) {
      if (
        this.lastCreatedInvoiceId === content.id &&
        !el.classList.contains('mc-no-detect-html-changes')
      ) {
        this.renderer.addClass(el, 'mc-detect-html-changes');

        setTimeout(() => {
          this.renderer.removeClass(el, 'mc-detect-html-changes');
          this.renderer.addClass(el, 'mc-no-detect-html-changes');
          this.invoiceService.setLastCreatedInvoice(999999999);
        }, 2000);
      }
    }
    return '';
  }

  getTooltipForLinkedIcon(content: {
    linkedFlg: any;
    ancestorId: any;
    ancestorEntityName: any;
    caption: any;
  }): any {
    if (content.linkedFlg && content.ancestorId) {
      const entityOriginName = content.ancestorEntityName;
      const roleName = content.caption;
      this.linkedTooltipText =
        entityOriginName + ' | ' + roleName + ' (LINKED)';
      return this.linkedTooltipText;
    }
  }

  getTooltipForUnlinkedIcon(content: any): any {
    if (!content.linkedFlg && content.ancestorId) {
      const entityOriginName = content.ancestorEntityName;
      const roleName = content.caption;
      this.linkedTooltipText =
        entityOriginName + ' | ' + roleName + ' (UNLINKED)';
      return this.linkedTooltipText;
    }
  }
}
