<mcc-message class="w-100" [errorMsg]="message"></mcc-message>

<div class="card">
<!--    Payment part-->
  <h2 class="title-medium-dark">{{ 'cc.my-factoring.payment' | translate }}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.my-factoring.payment' | translate}}:</span>
      <span class='dialog-col-value'>
        {{
        csrContract.recurringDetails.getTypeCdLabel() ?
          csrContract.recurringDetails.getTypeCdLabel() : '-'
        }}
      </span>
    </div>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdQUARTERLY()">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.day' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') ?
            csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') : '-'
          }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.month' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getQuarterlyVll().getLabel(csrContract.recurringDetails.month,'') ?
            csrRecurringDetails.getQuarterlyVll().getLabel(csrContract.recurringDetails.month,'') : '-'
          }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdMONTHLY()">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.day' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') ?
            csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') : '-'
          }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdWEEKLY()">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.day' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getWeekDaysVll().getLabel(csrContract.recurringDetails.day,'') ?
            csrRecurringDetails.getWeekDaysVll().getLabel(csrContract.recurringDetails.day,'') : '-'
          }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdYEARLY()">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.day' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') ?
            csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') : '-'
          }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.month' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getYearlyVll().getLabel(csrContract.recurringDetails.month,'') ?
            csrRecurringDetails.getYearlyVll().getLabel(csrContract.recurringDetails.month,'') : '-'
          }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="csrContract.recurringDetails.isTypeCdHALF_YEARLY()">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.day' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') ?
            csrRecurringDetails.getMonthDaysVll().getLabel(csrContract.recurringDetails.day,'') : '-'
          }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.month' | translate}}:</span>
        <span class='dialog-col-value'>
          {{
          csrRecurringDetails.getHalfYearlyVll().getLabel(csrContract.recurringDetails.month,'') ?
            csrRecurringDetails.getHalfYearlyVll().getLabel(csrContract.recurringDetails.month,'') : '-'
          }}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="divider"></div>

  <!--    Details part-->
  <h2 class="title-medium-dark">{{ 'cc.billing.details' | translate }}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.contract-type' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.durationInMonths ? 'Limited' : 'Unlimited' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.contract-start-date' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.startDate ? (csrContract.startDate | date : "dd.MM.YYYY") : '-' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.contract-end-date' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.endDate ? (csrContract.endDate | date : "dd.MM.YYYY") : '-' }}
      </span>
    </div>
  </div>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumer.free-text' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.notes ? csrContract.notes : '-' }}
      </span>
    </div>
  </div>
  <div class="divider"></div>

  <!-- Products and services part-->
  <h2 class="title-medium-dark">{{ 'cc.consumer.products-and-services' | translate }}</h2>
  <div class="mc-table-wrapper products-table mt-large" *ngIf="selectedProductList.items">
    <div class="mc-table">
      <table class="w-100">
        <thead class="mc-table-head">
        <tr>
          <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
          <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
          <th class="text-left">{{'cc.common.view.description' | translate}}</th>
          <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
          <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let product of selectedProductList.items">
          <td class="text-left">{{product.id}}</td>
          <td class="text-left">{{product.name}}</td>
          <td class="text-left">{{product.description}}</td>
          <td class="text-right">{{product.fldUnitPriceLoc()}}</td>
          <td class="text-right">{{product.vatRate.toFixed(2).replace('.', ',')}}%</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumer.amount-netto' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ totalNettoStr ? totalNettoStr : '-' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumer.amount-brutto' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ totalBruttoStr ? totalBruttoStr : '-' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumer.total-netto' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ totalNettoStr ? totalNettoStr : '-' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.consumer.total-vat' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ totalVatStr ? totalVatStr : '-' }}
      </span>
    </div>
  </div>
  <div class="divider"></div>

  <!-- Payment Method part-->
  <h2 class="title-medium-dark">{{ 'cc.my-factoring.payment-method' | translate }}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.my-factoring.payment-method' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.paymentDetails.getTypeCdLabel() ? csrContract.paymentDetails.getTypeCdLabel() : '-' }}
      </span>
    </div>
    <ng-container *ngIf="this.csrContract.paymentDetails?.isTypeCdDIRECT_DEBIT() && primaryBankAccount">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.owner ? primaryBankAccount.owner : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.bankName ? primaryBankAccount.bankName : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.iban ? primaryBankAccount.iban : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.bic ? primaryBankAccount.bic : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.sepaMandateId ? primaryBankAccount.sepaMandateId : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.sepaMandateDate ? (primaryBankAccount.sepaMandateDate | date : "dd.MM.YYYY") : '-' }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="this.csrContract.paymentDetails?.isTypeCdTRANSFER() && mcBankAccount">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.accountOwner ? mcBankAccount.accountOwner : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.bankName ? mcBankAccount.bankName : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.iban ? mcBankAccount.iban : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.bic ? mcBankAccount.bic : '-' }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
