<h3 class="page-title">{{ 'cc.factoring.transactions' | translate }}</h3>

<div class="actions-container">
  <span>{{ 'cc.transactions.transactions-list' | translate }}</span>
  <div class="actions-wrapper">
    <button *ngIf="pmTransactionList.getCount() > 0 && parentFlg" class="system-action-btn" (click)="actExportAllTransactions()">
      <i class="fas fa-file-export"></i>{{ 'cc.out-factoring.transactions.export-all' | translate }}
    </button>
    <button *ngIf="pmTransactionList.getCount() > 0 && !parentFlg" class="system-action-btn" (click)="actExportToCsv()">
      <i class="fas fa-file-export"></i>{{ 'cc.factoring.export-to-csv' | translate }}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="pmTransactionList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="pmTransactionList.totalItemsCount"
  [itemsPerPage]="pmTransactionList.pageItemsPerPageCount"
  (filterChanged)="onFilterChanged($event)"
  (filterValuesChanged)="collectFilterValuesForExportAll($event)"
  (selectedItems)="onItemsSelected($event)"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
  [searchTooltip]="searchTooltip">
</mk-mat-table>

<mcc-modal1-wrapper *ngIf="showConsumerProfileMcb.value" [showMcb]="showConsumerProfileMcb" modalSizeCd="full">
  <mc-consumer-consumer-profile-part
    (eventClosed)="showConsumerProfileMcb.setFalse()"
    [csrConsumerId]="consumerId"
    [currencyCode]="actionPmTransaction.mcFactoring.mcCurrency.code">
  </mc-consumer-consumer-profile-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper [showMcb]="showPauseTransactionMcb" *ngIf="showPauseTransactionMcb.value">
  <app-shared-pause-transactions
    (eventPausedTransaction)="onPauseTransaction()"
    (eventUnpausedTransaction)="onUnpauseTransaction()"
    (eventPauseTransactionCanceled)="onPauseTransactionCanceled()"
    [pmTransactionList]="checkedTransactionList">
  </app-shared-pause-transactions>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showExportMcb.value" [showMcb]="showExportMcb" (eventCanceled)="actCanceledExportMcb()">
  <app-shared-factoring-export-part
    [isExportAll]="isExportAll"
    [pmTransactionFilter]="pmTransactionFilter"
    [pmTransactionList]="checkedTransactionList"
    (eventExported)="actTransactionsExported()"
    (eventSaved)="actExportTransactionsSaved($event)"
    (eventCanceled)="actCanceledExportMcb()">
  </app-shared-factoring-export-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper [showMcb]="showStornoMcb" *ngIf="showStornoMcb.value" modalSizeCd="small">
  <pm-transactions-storno-part [pmTransactions]="checkedTransactionList"
                               (eventCanceled)="onCancelStorno()" (eventSaved)="onSaveStorno()">
  </pm-transactions-storno-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showRecordPaymentMcb.value" [showMcb]="showRecordPaymentMcb">
  <app-shared-transaction-payment-add-edit-part [pmTransactions]="checkedTransactionList"
                                                (eventCanceled)="actCancelRecordPaymentMcb()"
                                                (eventSaved)="actRecordPaymentSaved()"
                                                [isParentEntity]="true">
  </app-shared-transaction-payment-add-edit-part>
</mcc-modal1-wrapper>
