import {Component, OnInit} from '@angular/core';
import {
  McPdfTemplateList,
  McGod,
  McPdfTemplate,
  McPdfTemplateTable3Config,
  McForm,
  McBoolean,
  McHtmlButton
} from "@miticon-ui/mc-core";

@Component({
  selector: 'mc-process-pdf-template-list',
  templateUrl: './mc-process-pdf-template-list.component.html',
})
export class McProcessPdfTemplateListComponent implements OnInit {
  mcPdfTemplateList!: McPdfTemplateList;
  mcPdfTemplateTable3Config = new  McPdfTemplateTable3Config();
  mcForm = new McForm();
  addPdfTemplateMcb = new McBoolean();
  mcPdfTemplate = new McPdfTemplate();
  successMsg = '';
  mcGod = McGod.getInstance();


  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcPdfTemplateList = new McPdfTemplateList();
    this.mcPdfTemplateList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcPdfTemplateList.loadByEntityId(McGod.getLoggedEntityIdFromToken());
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }

  actShowAddEditTemplate() {
    this.addPdfTemplateMcb.setTrue();
  }

  actCancelPdfTemplateAddEdit() {
    this.mcPdfTemplate = new McPdfTemplate();
    this.addPdfTemplateMcb.setFalse();
  }

  actSavedPdfTemplateAddEdit() {
    this.actLoad();
    this.addPdfTemplateMcb.setFalse();
    this.successMsg = 'PDF Template added successfully';
    this.mcPdfTemplate = new McPdfTemplate();
  }

  onTableItemAction(mcHtmlButton: McHtmlButton) {
    if (mcHtmlButton.actionCdForEvent === McPdfTemplate.ACTION_SHOW_PROFILE) {
      this.mcPdfTemplate = mcHtmlButton.item;

    } else if (mcHtmlButton.actionCdForEvent === McPdfTemplate.ACTION_SHOW_EDIT) {
      this.mcPdfTemplate = mcHtmlButton.item;
      this.actShowAddEditTemplate();
    }
  }
}
