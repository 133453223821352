<h3 class="text-uppercase">{{'cc.ebics.ebics' | translate}}</h3>

<div>
    <lib-mc-loader [showLoader]="mcBankAccountList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="mcBankAccountList.apiErrorFlg">
        Error: {{mcBankAccountList.apiErrorMessage}}
    </div>

<!--  Filter-->
  <div class="mc-filter fancy-label">
    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.ebics.search-by-bank' | translate}}" placement="bottom-left"></i>
    <mcc-fi-textbox [isClearEnabled]="true" label="Search" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">Clear all</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">Filter</button>
  </div>

<!--  Messages-->
  <ng-container *ngIf="notifyMessage.successMessage || notifyMessage.errorMessage">
    <mcc-message [successMsg]="notifyMessage.successMessage" [errorMsg]="notifyMessage.errorMessage" class="text-center"></mcc-message>
  </ng-container>

<!--  System Action Button-->
  <div class="mc-float-right">
    <button class="system-action-btn mt-4" (click)="onCreateEbicsAccount()"><i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.ebics.create-ebics-account' | translate}}</button>
  </div>

  <mcc-modal1-wrapper [showMcb]="showCreateEbicsMcb" *ngIf="showCreateEbicsMcb.value" (eventCanceled)="onCanceledEbicsCreation()">
    <mc-ebics-ebics-add-edit-part [mcBankAccount]="mcBankAccount" (eventCanceled)="onCanceledEbicsCreation()"></mc-ebics-ebics-add-edit-part>
  </mcc-modal1-wrapper>

<!--  Table-->
    <div *ngIf="mcBankAccountList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcBankAccountList"
                        [table3Config]="mcBankAccountTable3Config"
                        (eventItemAction)="onTableItemAction($event)"
                        (eventPaginationOrSortChanged)="actLoad()">
        </mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

