<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div class="consumer-360-profile" *ngIf="csrConsumer.exists()">
<div class='card-wrapper pb-4'>
  <app-shared-card header="{{'cc.common.basic-information' | translate}}">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.view.status' | translate}}:" [description]="csrConsumer.statusCd"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.jobs.edit.type' | translate}}:" [description]="csrConsumer.typeCd"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.overview.company-name' | translate}}:" [description]="csrConsumer.companyName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.first-name' | translate}}:" [description]="csrConsumer.firstName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.last-name' | translate}}:" [description]="csrConsumer.lastName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.title' | translate}}:" [description]="csrConsumer.title"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.gender' | translate}}:" [description]="csrConsumer.gender"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.birthday' | translate}}:" [description]="consumerBirthday"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card header="{{'cc.common.edit.address' | translate}}">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.street' | translate}}:" [description]="csrConsumer.street"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.address-supplement' | translate}}:" [description]="csrConsumer.addressSupplement"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.postal-code' | translate}}:" [description]="csrConsumer.postCode"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.city' | translate}}:" [description]="csrConsumer.city"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.users.edit.country' | translate}}:" [description]="csrConsumer.countryName"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card header="{{'cc.consumer.overview.contact' | translate}}">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.email' | translate}}:" [description]="csrConsumer.email"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.telephone' | translate}}:" [description]="csrConsumer.telephone"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.cellphone' | translate}}:" [description]="csrConsumer.cellphone"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.edit.fax' | translate}}:" [description]="csrConsumer.fax"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card header="{{'cc.consumer.overview.bank' | translate}}">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.iban' | translate}}:" [description]="csrConsumer.iban"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.bic' | translate}}:" [description]="csrConsumer.bic"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.account-owner' | translate}}:" [description]="csrConsumer.accountOwner"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.common.bank-name' | translate}}:" [description]="csrConsumer.bankName"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.overview.sepa-mandate-id' | translate}}:" [description]="csrConsumer.sepaMandanteId"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumer.overview.sepa-mandate-date-of-signature' | translate}}:" [description]="sepaMandateDate"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card header="{{'cc.users.edit.language' | translate}}">
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.iso-country' | translate}}:" [description]="csrConsumer.isoCountry"></mcc-row-title-description>
    <mcc-row-title-description extraCssClasses="card-control" title="{{'cc.consumers.edit.iso-language' | translate}}:" [description]="csrConsumer.isoLanguage"></mcc-row-title-description>
  </app-shared-card>

  <app-shared-card header="{{'cc.table.invoice.additional' | translate}}">
    <ng-container *ngIf="csrConsumer.entityConsumerAttributes">
      <div *ngFor="let attribute of consumerAttributes">
        <mcc-row-title-description [widerTitleFlg]="true" extraCssClasses="card-control" [title]="attribute.label" [description]="showCustomAttribute(attribute)"></mcc-row-title-description>
      </div>
    </ng-container>
  </app-shared-card>
 </div>
</div>
