<div class='card'>
  <h2 class="title-medium-dark mb-large">{{ 'cc.my-factoring.payment' | translate }}</h2>

  <h6 class="mc-mandatory-star">{{ 'cc.my-factoring.payment' | translate }}</h6>
  <mcc-fi-radio-buttons2 name="ctrlPayment"
                         [directionHorizontal] = true
                         [mcForm]="mcForm"
                         [validRequiredFlg]="true"
                         [optionsVll]="paymentsVll"
                         (eventValueChanged)="onPaymentChangeEmit($event)">
  </mcc-fi-radio-buttons2>

  <ng-container *ngIf="selectedPaymentCd">
    <ng-container *ngIf="selectedPaymentCd === paymentType.WEEKLY">
      <mcc-fi-select [mcForm]="mcForm"
                     [validRequiredFlg]="true"
                     name="ctrlDays"
                     label="{{'cc.common.day' | translate}}"
                     placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                     [optionsVll]="daysVll"></mcc-fi-select>
    </ng-container>
    <ng-container *ngIf="selectedPaymentCd === paymentType.MONTHLY || selectedPaymentCd === paymentType.QUARTERLY ||
        selectedPaymentCd === paymentType.HALF_YEARLY || selectedPaymentCd === paymentType.YEARLY">
      <mcc-fi-select [mcForm]="mcForm"
                     [validRequiredFlg]="true"
                     name="ctrlDays"
                     label="{{'cc.common.day' | translate}}"
                     placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                     [optionsVll]="monthDaysVll"></mcc-fi-select>
    </ng-container>
    <ng-container *ngIf="selectedPaymentCd === paymentType.QUARTERLY">
      <mcc-fi-select [mcForm]="mcForm"
                     [validRequiredFlg]="true"
                     name="ctrlMonthYear"
                     label="{{'cc.common.month' | translate}}"
                     placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                     [optionsVll]="quarterlyVll"></mcc-fi-select>
    </ng-container>
    <ng-container *ngIf="selectedPaymentCd === paymentType.HALF_YEARLY">
      <mcc-fi-select [mcForm]="mcForm"
                     [validRequiredFlg]="true"
                     name="ctrlMonthYear"
                     label="{{'cc.common.month' | translate}}"
                     placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                     [optionsVll]="halfYearlyVll"></mcc-fi-select>
    </ng-container>
    <ng-container *ngIf="selectedPaymentCd === paymentType.YEARLY">
      <mcc-fi-select [mcForm]="mcForm"
                     [validRequiredFlg]="true"
                     name="ctrlMonthYear"
                     label="{{'cc.common.month' | translate}}"
                     placeholderOption="{{'cc.common.edit.please-choose' | translate}}"
                     [optionsVll]="yearlyVll"></mcc-fi-select>
    </ng-container>
  </ng-container>
</div>
