import {Component, OnInit} from '@angular/core';
import {McProcessList, McProcess, McProcessTable3Config, McBoolean, McForm, McHtmlButton, McGod} from "@miticon-ui/mc-core";
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-process-process-list-page',
  templateUrl: './mc-process-process-list-page.component.html',
})
export class McProcessProcessListPageComponent implements OnInit {

  mcProcessList!: McProcessList;
  mcProcessTable3Config = new  McProcessTable3Config();
  showAddEditProcessMcb = new McBoolean();
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  successMsg!: string;
  errorMsg!: string;
  mcProcess = new McProcess();

  // ---------------------------------------------------------------------
  constructor(private router: Router, private toastr: ToastrService) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcProcessList = new McProcessList();
    this.mcProcessList.setPager(0, 100);
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcProcessList.loadByIdMcEntity(McGod.getLoggedEntityIdFromToken(), () => {}, () => {
      this.successMsg = '';
      this.errorMsg = this.mcProcessList.apiErrorMessage;
    });
  }

  actCreateProcess() {
    this.showAddEditProcessMcb.setTrue();
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }

  actCloseAddEditProcess() {
    this.showAddEditProcessMcb.setFalse();
  }

  actAddEditProcessSaved() {
    this.showAddEditProcessMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.processes.process-successfully-created')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.actLoad();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McProcess.ACTION_SHOW_PROFILE) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${mcButton.item.id}`);
    } else if (mcButton.actionCdForEvent === McProcess.ACTION_SHOW_ITEM_LIST) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${mcButton.item.id}`);
    } else if (mcButton.actionCdForEvent === McProcess.ACTION_SHOW_EMAIL) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-email/${mcButton.item.id}`);
    } else if (mcButton.actionCdForEvent === McProcess.ACTION_ACTIVATE) {
      this.activateProcess(mcButton.item);
    } else if (mcButton.actionCdForEvent === McProcess.ACTION_DEACTIVATE) {
      this.deactivateProcess(mcButton.item);
    }
  }

  activateProcess(mcProcess: McProcess) {
    this.mcProcess = mcProcess;
    this.mcProcess.statusCd = McProcess.STATUS_CD_ACTIVE;
    this.mcProcess.save( () => {
      this.successMsg = this.mcGod.t('cc.processes.process-successfully-activated');
      this.actLoad();
    }, () => {
      this.successMsg = '';
      this.errorMsg = this.mcProcess.apiErrorMessage;
      this.mcProcess.statusCd = McProcess.STATUS_CD_INACTIVE;
    });
  }

  deactivateProcess(mcProcess: McProcess) {
    this.mcProcess = mcProcess;
    this.mcProcess.statusCd = McProcess.STATUS_CD_INACTIVE;
    this.mcProcess.save( () => {
      this.successMsg = this.mcGod.t('cc.processes.process-successfully-deactivated');
      this.actLoad();
    }, () => {
      this.successMsg = '';
      this.errorMsg = this.mcProcess.apiErrorMessage;
      this.mcProcess.statusCd = McProcess.STATUS_CD_ACTIVE;
    });
  }

}
