<div class="content-wrapper">
  <lib-mc-loader [showLoader]="loadingFlg"></lib-mc-loader>
  <div class="content-header-slim">
    <!--    <div class="header-icon"><img src="/assets/images/recordpayments-title.svg"></div>-->
    <h3>Export</h3>
  </div>
  <div class="content-body">

    <mcc-message *ngIf="apiErrorFlg" [errorMsg]="errorMsg"></mcc-message>

    <div *ngIf="!exportedFlg">
      <div>
        <mcc-fi-radio-buttons2 label="Select export type"
                               name="ctrlExportType"
                               [mcForm]="mcForm"
                               [validRequiredFlg]="true"
                               [value]="7"
                               [directionHorizontal]="false"
                               (eventValueChanged)="onExportTypeChanged($event)"
                               [optionsVll]="exportTypeVll"></mcc-fi-radio-buttons2>
      </div>

      <div *ngIf="exportType">
        <div class="info-summary-lite p-3 ml-n2 text-uppercase mc-font-weight-500">
          <span class="m-0">{{'cc.transactions.number-of-selected-transactions' | translate}}: {{numberOfAllExportTransactions}}</span>
<!--          <span class="ml-4">{{'cc.transactions.filtered-total-amount' | translate}}: {{totalFilteredAmount}}</span>-->
        </div>

        <div *ngIf="!badInputsFlg">

          <!-------------------   FOR EXPORTING SEPA ------------------->
          <ng-container *ngIf="exportType === 'EXPORT_SEPA'">
            <div class="col-md-12">
              <mcc-fi-radio-buttons2 label="Export type"
                                     name="ctrlExportSepaType"
                                     [mcForm]="mcForm"
                                     [validRequiredFlg]="true"
                                     [directionHorizontal]="false"
                                     [optionsVll]="exportSepaTypeVll"></mcc-fi-radio-buttons2>
            </div>
            <div class="blue-info-msg mb-2"><i class="fas fa-info-circle"></i> The minimal requested collection date for
              the transactions is: {{largestTransactionDateString}}</div>

            <mcc-fi-date-picker
              label="{{'cc.out-factoring.requested-collection-date' | translate}}"
              name="dpCollectionDate"
              [markDisabledDays]="disableWeekendDays"
              [mcForm]="mcForm"
              [minDateInput]="largestTransactionDate"
              validRequiredFlg="true">
            </mcc-fi-date-picker>

            <mcc-fi-select
              name="selBankAccountId"
              label="{{'cc.common.edit.bank-account' | translate}}"
              value=""
              [mcForm]="mcForm"
              [optionsVll]="bankAccountVll"
              validRequiredFlg="true">
            </mcc-fi-select>
          </ng-container>

          <!-------------------   EXPORTING FOR INKASSSO ------------------->

          <ng-container *ngIf="exportType === 'EXPORT_FOR_INKASSO'">
            <mcc-fi-select
              name="ctrlDataSeparator"
              label="Data separator"
              [mcForm]="mcForm"
              [optionsVll]="dataSeparatorVll"
              validRequiredFlg="true">
            </mcc-fi-select>

            <mcc-fi-checkbox label="Change transaction status to 'Sent to Inkasso'"
                             [name]="'ctrlChangeTransactionStatus'"
                             [value]="true"
                             [isCheckboxChecked]="true"
                             [mcForm]="mcForm"></mcc-fi-checkbox>

          </ng-container>

        </div>
        <div *ngIf="badInputsFlg">
          <mcc-message [errorMsg]="badInputsErrorMsg"></mcc-message>
        </div>

      </div>
    </div>

    <!----------------- EXPORTED SUCCESSFULLY CONTENT ------------------>
    <div *ngIf="exportedFlg">
      <mcc-message [successMsg]="successMsg" [errorMsg]="apiErrorFlg ? errorMsg : ''"></mcc-message>
      <button *ngIf="webFile.exists()" class="mc-button-link" (click)="actDownload()"><i
        class="fas fa-file-csv"></i> {{webFile.originalFilename}}</button>
    </div>

  </div>

  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()"><i
      class="far fa-times-circle"></i> {{exportedFlg ? ('cc.common.close' | translate) : ('cc.common.edit.cancel' | translate)}}
    </button>
    <button class="btn btn-primary" (click)="actExport();" [disabled]="saveButtonDisabledMcb.value || !exportType || badInputsFlg"><i
      class="fas fa-file-export"></i> {{exportedFlg ? ('cc.out-factoring.download' | translate) : 'Export'}}
    </button>
  </div>
</div>
<router-outlet></router-outlet>
