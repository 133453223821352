import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CsrBankAccount,
  CsrConsumer,
  CsrContract, CsrContractService,
  CsrNotificationDetails,
  CsrPaymentDetails,
  CsrRecurringDetails,
  CurrencyUtil,
  EbFactoringService,
  IEbFactoring,
  McBankAccount,
  McBankAccountService,
  McConsumer,
  McCountry,
  McCurrency,
  McCurrencyService,
  McDateUtils,
  McEntity2,
  McForm,
  McGod,
  McProduct,
  McProductList,
  WebFile
} from '@miticon-ui/mc-core';
import {CdkStepper} from '@angular/cdk/stepper';
import {MccStepperComponent} from '@miticon-ui/mc-components';
import {ContractType} from '../mc-consumer-consumer-360-create-contract-details-part/mc-consumer-consumer-360-create-contract-details-part.component';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import {DatePipe, Location} from "@angular/common";

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-part.component.scss'],
  providers: [{provide: CdkStepper, useExisting: MccStepperComponent}]
})
export class McConsumerConsumer360CreateContractPartComponent implements OnInit {

  /*Variables*/
  selectedTab = 0;
  consumerContractMcForm = new McForm();
  mcGod = McGod.getInstance();
  validFileUpload = true;
  message = '';
  disableAutomaticRenewal = false;
  totalNetto = 0;
  totalVat = 0;
  selectedProductList = new McProductList();
  errorMessage = '';
  csrContract = new CsrContract();
  webFile = new WebFile();
  file: File;
  mcCountry = new McCountry();
  mcEntity = new McEntity2();
  currency = new McCurrency();
  paymentMethod = '';
  paymentPurpose = '';
  allowNext = false;
  loadingFlg = false;
  mcBankAccount: McBankAccount | null;
  primaryBankAccount: CsrBankAccount |  null;
  totalNettoStr!: string;
  totalBruttoStr!: string;
  totalVatStr!: string;
  ebFactoring!: IEbFactoring;
  csrConsumer = new CsrConsumer();
  csrConsumerId = 0;
  sendInvitationEmail = false;
  iconBack = ICON_BACK;
  validPaymentTab: boolean = false;
  validDetailsTab: boolean = false;
  validProductsTab: boolean = false;
  validPaymentMethodTab: boolean = false;

  @Input() entityCurrency!: string;
  @Output() eventCsrContractSaved = new EventEmitter();

  constructor(private mcBankAccountService: McBankAccountService,
              private currencyService: McCurrencyService,
              private csrContractService: CsrContractService,
              private factoringService: EbFactoringService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private location: Location,
              private datePipe: DatePipe) {}

  ngOnInit() {
    this.csrConsumerId = this.route.snapshot.params['id'];
    this.csrConsumer.loadById(this.csrConsumerId);

    this.ebFactoring = this.factoringService.activeChildFactoring;
    this.currency = this.ebFactoring.mcCurrency;

    this.totalNettoStr = CurrencyUtil.format(this.totalNetto, this.currency.code);
    this.totalBruttoStr = CurrencyUtil.format(this.totalNetto + this.totalVat, this.currency.code);
    this.totalVatStr = CurrencyUtil.format(this.totalVat, this.currency.code);

    this.selectedTab = 0;
  }

  onPrev() {
    this.selectedTab -= 1;
    const bankFormControls = [];
    this.message = '';
  }

  onNext() {
    if (this.selectedTab === 3 && !this.consumerContractMcForm.isValid()) {
      return;
    }
    if (this.selectedTab === 2) {
      this.paymentMethod = this.consumerContractMcForm.getValue('ctrlPaymentMethod');
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_DIRECT_DEBIT;
      }
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_CASH) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_CASH_ONSITE;
      }
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_TRANSFER) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_MONEY_TRANSFER;
      }
    }
    if (this.selectedTab === 3 && this.consumerContractMcForm.getValue('ctrlPaymentMethod') === CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT && this.consumerContractMcForm.getValue('ctrlUseDiffBankAccount')) {
      this.validateIban();
      return;
    }
    if (this.selectedTab === 2 && this.selectedProductList.getCount() === 0) {
      this.message = `${this.mcGod.t('cc.consumer.please-select-products-and-services')}.`;
      return;
    }
    if (this.selectedTab === 2 && this.selectedProductList.getCount() > 0) {
      this.selectedTab += 1;
      this.message = ``;
      return;
    }
    if (this.selectedTab === 3 && this.consumerContractMcForm.getValue('contractType') === ContractType.CONTRACT_TYPE_UNLIMITED) {
      this.message = `${this.mcGod.t('cc.consumer.field-limited-in-the-details-step-have-to-be-checked-to-enable-automatic-renewal')}.`;
      this.consumerContractMcForm.getControl('ctrlAutomaticRenewal')?.get('value')?.setValue(false);
      this.disableAutomaticRenewal = true;
    }
    if (this.selectedTab === 3 && this.consumerContractMcForm.getValue('contractType') === ContractType.CONTRACT_TYPE_LIMITED) {
      this.message = '';
      this.disableAutomaticRenewal = false;
    }
    if (this.selectedTab === 3) {
      this.mapCsrContractProperties();
    }
    if (this.consumerContractMcForm.isValid()) {
      this.selectedTab += 1;
      this.message = '';
    }
  }

  getPaymentPeriod() {
    const paymentPeriod = this.consumerContractMcForm.getValue('ctrlPayment');
    if (paymentPeriod === '') {
      return '';
    }
    if (paymentPeriod === CsrRecurringDetails.TYPE_CD_MONTHLY) {
      return this.mcGod.t('cc.consumer.monthly');
    }
    if (paymentPeriod === CsrRecurringDetails.TYPE_CD_HALF_YEARLY) {
      return this.mcGod.t('cc.consumer.half-yearly');
    }
    if (paymentPeriod === CsrRecurringDetails.TYPE_CD_QUARTERLY) {
      return this.mcGod.t('cc.consumer.quarterly');
    }
    if (paymentPeriod === CsrRecurringDetails.TYPE_CD_WEEKLY) {
      return this.mcGod.t('cc.consumer.weekly');
    }
    if (paymentPeriod === CsrRecurringDetails.TYPE_CD_YEARLY) {
      return this.mcGod.t('cc.consumer.yearly');
    }
  }

  onFileUploaded(value: File) {
    this.validFileUpload = !!value;
    this.file = value;
  }

  onTotalNettoAndVatValueEmit(value: {totalNetto: number; totalVat: number}) {
    this.totalNetto = value.totalNetto;
    this.totalVat = value.totalVat;

    this.totalNettoStr = CurrencyUtil.format(this.totalNetto, this.currency.code);
    this.totalBruttoStr = CurrencyUtil.format(this.totalNetto + this.totalVat, this.currency.code);
  }

  saveContract() {
    // Products quantity handler
    const tempProductList = new McProductList();
    this.selectedProductList.items.forEach(item => {
      if (item.noOfUnitsSelected > 1) {
        for (let i = 1; i < item.noOfUnitsSelected; i++) {
          let tempProduct = new McProduct();
          tempProduct = Object.assign(tempProduct, item);
          tempProductList.addItem(tempProduct);
        }
      }
    });
    tempProductList.items.forEach(item => {
      this.selectedProductList.addItem(item);
    });
    this.csrContract.products = this.selectedProductList;
    if(this.mcBankAccount) {
      this.csrContract.idBeneficiary = this.mcBankAccount.id;
    }
    this.csrContract.consumer360Flg = true;
    this.csrContract.save(() => {
      if (this.sendInvitationEmail) {
        this.csrContractService.sendInvitationEmail({idCsrConsumer: this.csrContract.idCsrConsumer, idCsrContract: this.csrContract.id})
          .pipe(take(1))
          .subscribe();
      }

      if (this.sendInvitationEmail) {
        this.toastr.success(`${McGod.t('cc.common.contract-successfully-created-and-invitation-email-was-sent')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      } else {
        this.toastr.success(`${McGod.t('cc.common.contract-successfully-created')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      }
      this.eventCsrContractSaved.emit();
      this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${this.csrConsumerId}/contracts/${this.csrConsumerId}`]);

    }, () => {
      this.toastr.error(`${this.csrContract.apiErrorMessage}`, '', {timeOut: 4000, extendedTimeOut: 4000});
    });
  }

  formatDate(dateString: string):any {
    const date = new Date(dateString);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  mapCsrContractProperties() {
    // ----------- First step -----------
    const recurringDetails = new CsrRecurringDetails();
    recurringDetails.typeCd = this.consumerContractMcForm.getValue('ctrlPayment');
    recurringDetails.day = this.consumerContractMcForm.getValue('ctrlDays');
    if (recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_QUARTERLY ||
      recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_HALF_YEARLY ||
      recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_YEARLY) {
      recurringDetails.month = this.consumerContractMcForm.getValue('ctrlMonthYear');
    }
    this.csrContract.recurringDetails = recurringDetails;

    // ---------- Second step -----------
    this.csrContract.endDate = this.consumerContractMcForm.getValue('ctrlContractEndDate') ? this.formatDate(this.consumerContractMcForm.getValue('ctrlContractEndDate')) : null;
    this.csrContract.startDate = this.formatDate(this.consumerContractMcForm.getValue('ctrlContractStartDate'));
    const notificationsDetails = new CsrNotificationDetails();
    notificationsDetails.sendInvoice = this.consumerContractMcForm.getValue('ctrlSendInvoice');
    if (notificationsDetails.sendInvoice) {
      if (this.consumerContractMcForm.getValue('ctrlEmailAddress') === 1) {
        notificationsDetails.email = this.csrConsumer.email;
      } else {
        notificationsDetails.email = this.consumerContractMcForm.getValue('ctrlEmailAddressTextBox');
      }
    }
    this.csrContract.notificationDetails = notificationsDetails;
    this.csrContract.notes = this.consumerContractMcForm.getValue('ctrlFreeText');

    // ----------- Third step -------------
    this.csrContract.priceUnit = this.totalNetto;
    this.csrContract.vatUnit = this.totalVat;

    // ----------- Fourth step ------------
    const paymentDetails = new CsrPaymentDetails();
    paymentDetails.typeCd = this.consumerContractMcForm.getValue('ctrlPaymentMethod');
    if (paymentDetails.isTypeCdDIRECT_DEBIT()) {
      if (this.consumerContractMcForm.getValue('ctrlUseDiffBankAccount')) {
        console.log('this logic is not implemented yet')
      }
    }

    // paymentDetails.idDdApproval = this.consumerContractMcForm.getValue('ctrlDirectDebitId');
    // paymentDetails.ddApprovalStartDate = this.consumerContractMcForm.getValue('ctrlDirectDebitStartDate');
    // paymentDetails.ddApprovalEndDate = this.consumerContractMcForm.getValue('ctrlDirectDebitEndDate');
    this.csrContract.paymentDetails = paymentDetails;
    if (paymentDetails.isTypeCdTRANSFER()) {
      this.csrContract.idBeneficiary = this.consumerContractMcForm.getValue('ctrlMoneyTransfer');
      //this.csrContract.paymentDetails.paymentPurpose = this.paymentPurpose;
    }

    // ---------- Fifth step ------------
    this.csrContract.autoRenewal = false;
    if (this.csrContract.autoRenewal && this.csrContract.durationInMonths) {
      this.csrContract.autoRenewalLength = this.consumerContractMcForm.getValue('ctrlAutomaticRenewalYears');
      const dateToRemoveDaysFrom = this.csrContract.endDate;
      const daysToSubtract = this.consumerContractMcForm.getValue('ctrlAutomaticRenewalDays');
      const newDate = McDateUtils.subtractDateToNumber(dateToRemoveDaysFrom, daysToSubtract, 'days');
      // const newDateObject = McDateUtils.formatTimestampDateObject(newDate);
      const formatIso = McDateUtils.formatDateToIsoString(newDate);
      this.csrContract.cancellationDeadline = Math.round(Number(formatIso));
    }

    this.csrContract.idCsrConsumer = this.csrConsumer.id;
    this.csrContract.idMcEntity = McGod.getLoggedEntityIdFromToken();
  }

  onPaymentMethodChanged(value: any) {
    this.paymentMethod = value;
  }

  onPaymentPurpose(value: any) {
    this.paymentPurpose = value;
  }

  onChangeMcBankAccount(value: any) {
    this.mcBankAccount = value;
  }

  onChangeCsrBankAccount(value: any) {
    this.primaryBankAccount = value;
  }

  validateIban() {
    this.loadingFlg = true;
    if(this.mcBankAccount) {
      this.mcBankAccount.iban = this.consumerContractMcForm.getValue('ctrlIban');
      this.mcBankAccountService.validateIban(this.mcBankAccount).subscribe(data => {
        if (!data) {
          this.message = this.mcGod.t('cc.consumer-invalid-iban-please-enter-the-iban-in-the-valid-format') + '.';
        } else {
          this.selectedTab++;
          this.message = '';
        }
        this.loadingFlg = false;
      }, error => {
        this.message = error.error.message;
        this.loadingFlg = false;
      });
    }
  }

  goBack() {
    this.location.back();
  }
}
