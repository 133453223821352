import { Injectable} from '@angular/core';
import {McCurrencyServiceGENERATED} from '../_generated/mc-currency-generated.service';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
import {McCurrency} from '../models/mc-currency.model';



@Injectable({
  providedIn: 'root'
})
export class McCurrencyService extends McCurrencyServiceGENERATED {

  public currencies: McCurrency[] = [];
  public sortCriteriaList = new SortCriteriaList();

  public getAllCurrencies() {
    this.getAll(0, 50, this.sortCriteriaList)
      .subscribe((response) => {
        this.currencies = response.content;
      });
  }

  public getCurrencyById(id: number): McCurrency | undefined {
    return this.currencies.find(currency => currency.id === id);
  }

}
