import {NgModule} from '@angular/core';
import {NgbButtonsModule, NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {McComponentsModule, McStatusModule} from '@miticon-ui/mc-components';
import {McSchedulerRoutingModule} from './mc-scheduler-routing.module';
import {McSchedulerComponent} from './mc-scheduler.component';
import {McTableModule, McFilterModule} from '@miticon-ui/mc-components';
import { McJobViewComponent } from './mc-job/mc-job-view/mc-job-view.component';
import { McJobCreateEditModalComponent } from './mc-job/mc-job-create-edit-modal/mc-job-create-edit-modal.component';
import { McCronexComponent } from './mc-job/mc-cronex/mc-cronex.component';
import {TranslateModule} from '@ngx-translate/core';
import {McCoreModule} from "@miticon-ui/mc-core";
import {McJobCreateComponent } from './mc-job/mc-job-create/mc-job-create.component';
import {MatDialogModule} from "@angular/material/dialog";
import {SafeHtmlModule} from "../../assets/pipes/safe-html/safe-html.module";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";

@NgModule({
  declarations: [
    McSchedulerComponent,
    McJobViewComponent,
    McJobCreateEditModalComponent,
    McCronexComponent,
    McJobCreateComponent,
  ],
  imports: [
    NgbButtonsModule,
    NgbModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    McComponentsModule,
    McCoreModule,
    McSchedulerRoutingModule,
    McTableModule,
    McFilterModule,
    McStatusModule,
    TranslateModule,
    MatDialogModule,
    SafeHtmlModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [McSchedulerComponent, McSchedulerRoutingModule],
})
export class McSchedulerModule {
}
