<!--Job overview wrapper-->
<section class="mc-edit-user mc-job-overview">

  <!--Back button-->
  <div class="row mx-0">
    <div class="mc-go-back-to-all" (click)="goBack()">
      < {{("cc.common.back-to-all" | translate) + " " + ("cc.common.jobs" | translate)}}
    </div>
  </div>

  <div class="mc-edit-user-general-data mt-4">
    <div class="row">

      <!--Header-->
      <div class="col-md-12 mc-edit-user-general-data-user-name">
        {{'cc.jobs.edit.job-details-for' | translate}}: {{jobName}}
      </div>

      <!--Notify message-->
      <div class="mc-create-user-notify-wrapper">
        <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
      </div>

      <div class="col-md-12 mc-edit-user-general-data-personal-info">

        <!--Edit modal-->
        <div class="row">
          <div class="col-md-12 mc-edit-job-wrapper">
            <mc-job-create-edit-modal class="clearfix" [editJob]="jobData"
                                      (onSuccessCreateEditJob)="$event && getJobData(jobID)"></mc-job-create-edit-modal>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-md-12">
            <div class="row mc-edit-user-first-block m-0 pb-3">

              <!--Status-->
              <div class="col-md-6 mc-edit-user-preview-data">
                <span class="mc-job-label col-md-3">{{'cc.common.view.status' | translate}}: </span>
                <span class="mc-edit-user-preview-data-field-value ">
                    <mc-status [status]="jobStatus"></mc-status>
                </span>
              </div>
              <div class="col-md-6"></div>

              <!--Type-->
              <div class="col-md-6 mc-edit-user-preview-data pb-0" *ngIf="jobType">
                <span class="mc-job-label col-md-3">{{'cc.jobs.edit.type' | translate}}: </span>
                <span class="mc-edit-user-preview-data-field-value text-lowercase">{{jobType.split("_").join(" ")}}</span>
              </div>
            </div>

            <div class="row mc-edit-user-second-block pt-3 m-0">
              <div class="col-md-6 mc-second-block-fields">

                <!--Name-->
                <div class="mc-edit-user-preview-data">
                  <span class="mc-job-label col-md-3">{{ "cc.common.name" | translate }}: </span>
                  <span class="mc-edit-user-preview-data-field-value">{{jobName}}</span>
                </div>

                <!--Description-->
                <div class="mc-edit-user-preview-data">
                  <span class="mc-job-label col-md-3">{{'cc.common.view.description' | translate}}
                    : </span>
                  <span class="mc-edit-user-preview-data-field-value">{{jobDescription}}</span>
                </div>

                <!--Schedule type-->
                <div class="mc-edit-user-preview-data pb-0">
                  <span class="mc-job-label col-md-3">{{'cc.job.view.schedule-type' | translate}}: </span>
                  <span class="mc-edit-user-preview-data-field-value text-lowercase">{{scheduleType.split("_").join(" ")}}</span>
                </div>
              </div>

              <div class="col-md-6">

                <!--Schedule time-->
                <div class="mc-edit-user-preview-data">
                  <span class="mc-job-label col-md-3">{{'cc.jobs.view.schedule-time' | translate}}
                    : </span>
                  <span class="mc-edit-user-preview-data-field-value">{{scheduleTime}}</span>
                </div>

                <!--Last fired time-->
                <div class="mc-edit-user-preview-data">
                  <span class="mc-job-label col-md-3">{{'cc.jobs.view.last-fired-time' | translate}}
                    : </span>
                  <span class="mc-edit-user-preview-data-field-value"
                        *ngIf="lastFiredTime">{{ lastFiredTime}}</span>
                </div>

                <!--Next fire time-->
                <div class="mc-edit-user-preview-data pb-0">
                  <span class="mc-job-label col-md-3">{{'cc.jobs.view.next-fire-time' | translate}}
                    : </span>
                  <span class="mc-edit-user-preview-data-field-value">{{nextFireTime}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section><!--Job overview wrapper /end-->
