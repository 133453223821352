import {Component, Input, OnInit} from '@angular/core';
import {
  CsrBankAccount,
  CsrConsumer,
  CsrContract,
  CsrRecurringDetails,
  CurrencyUtil,
  EbFactoringService,
  IEbFactoring, McBankAccount,
  McConsumer,
  McCurrency,
  McCurrencyService,
  McForm,
  McProductList,
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-create-contract-overview-and-confirmation-part',
  templateUrl: './mc-consumer-consumer-create-contract-overview-and-confirmation-part.component.html',
  styleUrls: ['./mc-consumer-consumer-create-contract-overview-and-confirmation-part.component.scss']
})
export class McConsumerConsumerCreateContractOverviewAndConfirmationPartComponent implements OnInit {

  @Input() csrContract!: CsrContract;
  @Input() csrConsumer!: CsrConsumer;
  @Input() consumerContractMcForm!: McForm;
  @Input() selectedProductList!: McProductList;
  @Input() totalVat!: number;
  @Input() totalNetto!: number;
  @Input() message!: string;
  @Input() currency!: McCurrency;
  @Input() entityCurrency!: string;
  @Input() totalNettoStr!: string;
  @Input() totalBruttoStr!: string;
  @Input() activeFactoringId!: number;
  @Input() mcForm!: McForm;
  @Input() selectedTab: any;
  @Input() mcBankAccount!: McBankAccount;
  @Input() primaryBankAccount!: CsrBankAccount;

  ebFactoring!: IEbFactoring;
  totalVatStr!: string;

  csrRecurringDetails = new CsrRecurringDetails();

   constructor(private currencyService: McCurrencyService,
               private ebFactoringService: EbFactoringService) {}

  ngOnInit() {
     this.csrContract.recurringDetails.day = Number(this.csrContract.recurringDetails.day);
     this.csrContract.recurringDetails.month = Number(this.csrContract.recurringDetails.month);

     this.ebFactoring = this.ebFactoringService.activeChildFactoring;
     this.totalVatStr = CurrencyUtil.format(this.totalVat, this.ebFactoring.mcCurrency.code);

  }

  format(value: number): string {
    if (value) {
      return CurrencyUtil.format(value, this.ebFactoring.mcCurrency.code);
    }
    return CurrencyUtil.format(0, this.ebFactoring.mcCurrency.code);
  }
}
