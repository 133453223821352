import _moment from "moment";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export class McDateUtils {

  static lang = "en";


  static newFormatDateString(date: string | number | Date) {
    const myDate = new Date(date);
    myDate.setDate(myDate.getDate());
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '.'
      + ('0' + (myDate.getMonth() + 1)).slice(-2) + '.'
      + myDate.getFullYear();
    return myDateString;
  }

  static formatHoursAndMinutes(n: string | number) {
    return n < 10 ? '0' + n : n;
  }

  static newFormatDateTimeString(date: string | number | Date) {
    const myDate = new Date(date);
    myDate.setDate(myDate.getDate());
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '.'
      + ('0' + (myDate.getMonth() + 1)).slice(-2) + '.'
      + myDate.getFullYear() + ' ' + this.formatHoursAndMinutes(myDate.getHours()) + ':' + this.formatHoursAndMinutes(myDate.getMinutes()) + ' ' + 'h';
    return myDateString;
  }

  /*Convert timestamp in date string -
  * default value is LL -> December 25, 2019*/
  static formatTimestampDateString(timestamp: number, formatOption: string = "LL", locale: string = this.lang): string {
    return _moment(timestamp).locale(locale).format(formatOption);
  }



  /*Convert timestamp in date object -
  * default value is LL -> December 25, 2019*/
  static formatTimestampDateObject(timestamp: number): Date {
    return new Date(timestamp);
  }

  /*Return timestamp number from date string*/
  static formatDateToTimestamp(date: string | Date): number {
    return Number(_moment(date).format("x"));
  }

  static formatStringToTimestamp(date: string): number {
    return Number(new Date(date));
  }

  static formatDateToString(date: number, formatOption: string = "LL", locale: string = this.lang): string {
    if (date != null) {
      return _moment(date).locale(locale).format(formatOption);
    } else {
      return '';
    }
  }

  static formatDateTimeToString(dateTime: number, formatOption: string = "lll", locale: string = this.lang): string {
    if (dateTime != null) {
      return _moment(dateTime).locale(locale).format(formatOption);
    } else {
      return '';
    }
  }
  /*Format ngbDate object to timestamp*/
  static formatNgbDateToTimestamp(date: NgbDate): number {
    return Number(_moment(new Date(date.year, date.month - 1, date.day)).format("x"));
  }

  /*Format timestamp to ngbDate*/
  static formatTimestampToNgbDate(timestamp: number): NgbDate {
    return new NgbDate(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() + 1, new Date(timestamp).getDate());
  }

  /*Format ngbDate to string*/
  static formatNgbDateToString(ngbDate: NgbDate, formatOption: string): string {
    return this.formatTimestampDateString(this.formatNgbDateToTimestamp(ngbDate), formatOption);
  }

  /*Format ngbDateStruct object to timestamp*/
  static formatNgbDateStructToTimestamp(date: NgbDateStruct): number {
    return Number(_moment(new Date(date.year, date.month - 1, date.day)).format("x"));
  }

  /*Format timestamp to ngbDateStructure*/
  static formatTimestampToNgbDateStruct(timestamp: number): NgbDateStruct {
    return new NgbDate(new Date(timestamp).getFullYear(), new Date(timestamp).getMonth() + 1, new Date(timestamp).getDate());
  }

  /*Format ngbDateStruct to string*/
  static formatNgbDateStructToString(ngbDateStruct: NgbDateStruct, formatOption: string = "LL"): string {
    return this.formatTimestampDateString(this.formatNgbDateStructToTimestamp(ngbDateStruct), formatOption);
  }

  /*Format ngbDateStruct to Date*/
  static formatNgbDateStructToDate(ngbDateStruct: NgbDateStruct, formatOption: string = "LL"): Date {
    return new Date(ngbDateStruct.year, ngbDateStruct.month - 1, ngbDateStruct.day);
  }

  /*Format ngbDateStruct to Date and add one day*/
  static formatNgbDateStructToDateAndAddOneDay(ngbDateStruct: NgbDateStruct, formatOption: string = "LL"): Date {
    return new Date(ngbDateStruct.year, ngbDateStruct.month - 1, ngbDateStruct.day + 1);
  }


  static formatStringToDateAndTime(date: string): string {
    return _moment(date).format('lll');
  }

  static formatNumberToDate(date: number): string {
    return _moment(date).format('ll');
  }

  /*Get now date object*/
  static getNowDateObject(): Date {
    return new Date();
  }

  /*Get now timestamp*/
  static getNowTimestamp(): number {
    return Number(_moment(new Date()).format("x"));
  }

  static getDateForServicePeriodSepaIn(servicePeriod: 'currentMonth' | 'lastMonth' | 'twoMonthsAgo' | 'nextMonth'): string {
    const dateFormatToSend = '2019-10-01T12:30:41.830Z';
    let dateToReturn = '';
    switch (servicePeriod) {
      case 'currentMonth':
        dateToReturn = _moment().format().substring(0, 8) + dateFormatToSend.substring(8);
        break;
      case 'lastMonth':
        dateToReturn = _moment().subtract(1, 'months').format().substring(0, 8) + dateFormatToSend.substring(8);
        break;
      case 'twoMonthsAgo':
        dateToReturn = _moment().subtract(2, 'months').format().substring(0, 8) + dateFormatToSend.substring(8);
        break;
      case 'nextMonth':
        dateToReturn =  _moment().add(1, 'months').format().substring(0, 8) + dateFormatToSend.substring(8);
        break;
    }
    return dateToReturn;
  }

  static addOrSubtractDateToString(date: number, addOrSubtract: 'add' | 'subtract', numberToAddOrSubtract: number, format: string, unit: 'months' | 'days' | 'years') {
    if (addOrSubtract === 'add') {
      return _moment(date).add(numberToAddOrSubtract, unit).format(format);
    } else {
      return _moment(date).subtract(numberToAddOrSubtract, unit).format(format);
    }
  }

  // Return larger date
  static compareAndReturnLargerDate(date1: number, date2: number) {
    if (_moment(date1).isAfter(_moment(date2), 'second')) {
      return date1;
    } else {
      return date2;
    }
  }

  static addOrSubtractDateToNumber(date: number, addOrSubtract: 'add' | 'subtract', numberToAddOrSubtract: number, unit: 'months' | 'days' | 'years') {
    if (addOrSubtract === 'add') {
      return this.formatStringToTimestamp(_moment(date).add(numberToAddOrSubtract, unit).format());
    } else {
      return this.formatStringToTimestamp(_moment(date).subtract(numberToAddOrSubtract, unit).format());
    }
  }

  static subtractDateToNumber(date: number, numberToSubtract: number, unit: 'months' | 'days' | 'years'): string {
      // return this.formatStringToTimestamp(_moment(date).subtract(numberToSubtract, unit).format());
      return _moment(date).subtract(numberToSubtract, unit).format();
  }

  static formatDateToIsoString(date: string): string {
    return _moment(date).format('X');
  }

  static formatStringToString(date: string, formatOption: string = 'LL'): string {
    return _moment(date).locale('en').format(formatOption);
  }

  // Return before ending date

  static subtractTwoDates(dateToBeSubtractFrom: number, dateToBeSubtracted: number): number {
    const date1 = new Date(dateToBeSubtractFrom);
    const date2 = new Date(dateToBeSubtracted);

    const differenceInTime = date1.getTime() - date2.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  }

  static formatDateWithoutTimezone(date: any): string {
    const dateForFormatting = new Date(date);
    const result = new Date(dateForFormatting.toISOString().slice(0, -1)).toString();
    const formattedResult = _moment(result).locale('de').format('L');
    return formattedResult;
  }

  static addHoursToDate(date: any, hours: number) {
    return new Date(date.setHours(date.getHours() + hours));
  }

  static compareCurrentAndChosenMomentDates(date: number) {
    return _moment().format("YYYY-MM-DD") >= _moment(date).format("YYYY-MM-DD");
  }
}
