<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div class="mc-consumer-360-create-contract">
  <span class="back-option" (click)="goBack()">
    <span [innerHTML]="iconBack | safeHtml"></span>
    {{ "cc.consumer360.back-option" | translate }}
  </span>
  <div class="card mt-medium">
    <h2 class="title-medium-dark">{{"cc.consumers.create-contract" | translate}}</h2>
    <div class="line"></div>
    <div class='dialog-col'>
      <div class='dialog-col-label'>{{ 'cc.my-factoring.consumer' | translate}}:</div>
      <div class='dialog-col-value'>{{ csrConsumer.isTypeCdPERSON() ? (csrConsumer.firstName + " " + csrConsumer.lastName) : csrConsumer.companyName }}</div>
    </div>
    <div class='dialog-col'>
      <div class='dialog-col-label'>{{ 'cc.consumer.view.consumer-id' | translate}}:</div>
      <div class='dialog-col-value'>{{ csrConsumer.id }}</div>
    </div>
  </div>

  <div class="header-info mb-small">
    <p class="header-info_label">
      {{ 'cc.my-factoring.payment' | translate }}:
      <span class="header-info_value">
        {{ getPaymentPeriod() }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.my-factoring.payment-method' | translate }}:
      <span class="header-info_value">
        {{ paymentMethod }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.consumer.amount-netto' | translate }}:
      <span class="header-info_value">
        {{ totalNettoStr }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.product.view.vat' | translate }}:
      <span class="header-info_value">
        {{ csrConsumer && csrConsumer.euVat ? csrConsumer.euVat + '%' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.consumer.amount-brutto' | translate }}:
      <span class="header-info_value">
        {{ totalBruttoStr }}
      </span>
    </p>
  </div>

  <mat-stepper [linear]="false">
    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.my-factoring.payment"| translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-payments-part
        [mcForm]="consumerContractMcForm"
        (emitValidPaymentTab)="validPaymentTab = $event">
      </mc-consumer-consumer-360-create-contract-payments-part>
      <div class="card card-flex-end">
        <button class="primary-btn ml-small"
                (click)="onNext()"
                [disabled]="!validPaymentTab"
                *ngIf="selectedTab < 4"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.billing.details" | translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-details-part
        [selectedTab]="selectedTab"
        [csrConsumer]="csrConsumer"
        (emitValidDetailsTab)="validDetailsTab = $event"
        [mcForm]="consumerContractMcForm">
      </mc-consumer-consumer-360-create-contract-details-part>

      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()"
                [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="onNext()"
                [disabled]="!validDetailsTab"
                *ngIf="selectedTab < 4"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.consumer.products-and-services" | translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-products-and-services-part *ngIf="selectedTab > 1"
                                                                           (totalNettoAndVatValueEmit)="onTotalNettoAndVatValueEmit($event)"
                                                                           [errorMessage]="message"
                                                                           [totalNetto]="totalNetto"
                                                                           [totalVat]="totalVat"
                                                                           [activeFactoringId]="ebFactoring.id"
                                                                           [mcForm]="consumerContractMcForm"
                                                                           [selectedProductList]="selectedProductList"
                                                                           [entityCurrency]="entityCurrency"
                                                                           [currency]="currency"></mc-consumer-consumer-360-create-contract-products-and-services-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="onNext()"
                [disabled]="selectedProductList.items.length === 0"
                *ngIf="selectedTab < 4"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.my-factoring.payment-method" | translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-payment-method-part *ngIf="selectedTab > 2"
                                                                    [selectedTab]="selectedTab"
                                                                    [csrConsumer]="csrConsumer"
                                                                    (eventFileUploaded)="onFileUploaded($event)"
                                                                    [errorMessage]="message"
                                                                    [mcBankAccount]="mcBankAccount"
                                                                    (eventPaymentMethod)="onPaymentMethodChanged($event)"
                                                                    (eventPaymentPurpose)="onPaymentPurpose($event)"
                                                                    (eventMcBankAccount)="onChangeMcBankAccount($event)"
                                                                    (eventPrimaryCsrBankAccount)="onChangeCsrBankAccount($event)"
                                                                    [mcForm]="consumerContractMcForm"></mc-consumer-consumer-360-create-contract-payment-method-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="onNext()"
                [disabled]="!paymentMethod || (paymentMethod === 'Money transfer'  ? !mcBankAccount : !primaryBankAccount)"
                *ngIf="selectedTab < 4"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.consumer.overview-and-confirmation" | translate }}
      </ng-template>
      <mc-consumer-consumer-create-contract-overview-and-confirmation-part *ngIf="selectedTab === 4" [csrConsumer]="csrConsumer"
                                                                           [csrContract]="csrContract"
                                                                           [mcForm]="consumerContractMcForm"
                                                                           [totalBruttoStr]="totalBruttoStr"
                                                                           [totalNettoStr]="totalNettoStr"
                                                                           [activeFactoringId]="ebFactoring.id"
                                                                           [selectedProductList]="selectedProductList"
                                                                           [consumerContractMcForm]="consumerContractMcForm"
                                                                           [mcBankAccount]="mcBankAccount"
                                                                           [primaryBankAccount]="primaryBankAccount"
                                                                           [totalVat]="totalVat" [totalNetto]="totalNetto"
                                                                           [message]="message"> </mc-consumer-consumer-create-contract-overview-and-confirmation-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="onNext()"
                *ngIf="selectedTab < 4"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="saveContract()" *ngIf="selectedTab === 4">
          {{ "cc.common.create" | translate }}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<lib-mc-loader [showLoader]="mcCountry.apiLoadingFlg || mcEntity.apiLoadingFlg"></lib-mc-loader>
