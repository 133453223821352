import {Inject, Injectable} from '@angular/core';
import {CsrConsumerServiceGENERATED} from '../_generated/csr-consumer-generated.service';
import {Observable} from "rxjs";
import {ICsrConsumer} from "../_generated/csr-consumer.interface";
import {CsrConsumer} from "../models/csr-consumer.model";


@Injectable({
  providedIn: 'root'
})
export class CsrConsumerService extends CsrConsumerServiceGENERATED {
  public getConsumerBalance(consumerId: number): Observable<number> {
    return this.httpClient.get<number>(this.getApiServiceRootUrl() + `/${consumerId}/balance`);
  }

  public getAttributes() {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/custom-attributes`);
  }
}
