<h3>{{'cc.common.export-for-inkasso-list' | translate}}</h3>
<div>
  <lib-mc-loader [showLoader]="ebInkassoExportList.apiLoadingFlg"></lib-mc-loader>
  <div class="col-md-12">
    <div class="mc-filter fancy-label">
        <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" (eventEnterClicked)="actLoad()"
                      [mcForm]="mcForm"></mcc-fi-textbox>

      <div class="wide-date">
        <mcc-fi-date-picker
          datePickerViewType="RANGE_SELECTION"
          [mcForm]="mcForm"
          name="ctrlExportedDateRange"
          label="Date range"
          (eventFromDate)="onExportedDateFrom($event)"
          (eventToDate)="onExportedDateTo($event)"
          [allowManualEntryFlg]="false"
          monthsDisplayed="2"
          [clearDateRange]="clearDateRangeSubject"
        >
        </mcc-fi-date-picker>
      </div>

      <mcc-fi-select [mcForm]="mcForm" name="selExportedBy" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Exported by"
                     [optionsVll]="exportedByVll" [isSelectInFilter]="true"></mcc-fi-select>

      <button class="mc-link mc-filter-clear-button filter-clear-top-right"
              (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
      <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>

  <div class="col-md-12">
    <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>
  </div>

    <div *ngIf="ebInkassoExportList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebInkassoExportList"
                  [table3Config]="ebInkassoExportTable3Config" (eventItemAction)="onTableItemAction($event)"
                 (eventPaginationOrSortChanged)="actLoad()"></mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

