import {Component, OnInit} from '@angular/core';
import {CdkStepper} from '@angular/cdk/stepper';
import {MccStepperComponent} from '@miticon-ui/mc-components';
import {ActivatedRoute, Router} from '@angular/router';
import {CsrConsumer, McGod} from '@miticon-ui/mc-core';
import {Location} from '@angular/common';
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";

@Component({
  selector: 'mc-consumer-consumer-360-tabs-page',
  templateUrl: './mc-consumer-consumer-360-tabs-page.component.html',
  styleUrls: ['./mc-consumer-consumer-360-tabs.scss'],
  providers: [{provide: CdkStepper, useExisting: MccStepperComponent}]
})
export class McConsumerConsumer360TabsPageComponent implements OnInit {

  selectedTab!: number;
  consumerId!: number;
  csrConsumer = new CsrConsumer();
  iconBack = ICON_BACK;

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) {
  }

  ngOnInit() {
    this.onTabSwitch();
    this.consumerId = this.route.snapshot.params['id'];
    this.actLoad();
  }


  public actLoad(): void {
    this.csrConsumer.loadById(this.consumerId);
  }

  goBack() {
    this.location.back();
  }
  goToPrevious() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/smart-search-box`]);
  }

  onTabSwitch() {
    // @ts-ignore
    const url = this.route.snapshot['_routerState'].url;

    switch (true) {
      case url.includes('profile'): this.selectedTab = 0; break;
      case url.includes('contracts'): this.selectedTab = 1; break;
    }
  }
}
