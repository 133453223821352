import {Component, OnInit} from '@angular/core';
import {
  McConsumerList,
  McForm,
  McValueLabelList,
  McHtmlButton,
  McConsumerFilter,
  McGod,
  CsrConsumerList,
  CsrConsumerTable3Config, CsrConsumerFilter, McBoolean, McConsumer, McConsumerImport
} from '@miticon-ui/mc-core';
import {Subject} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'mc-consumer-consumer-360-smart-search-box',
  templateUrl: './mc-consumer-consumer-360-smart-search-box.component.html',
})
export class McConsumerConsumer360SmartSearchBoxComponent implements OnInit {

  mcConsumerList!: McConsumerList;
  csrConsumerList = new CsrConsumerList();
  csrConsumerTable3Config = new CsrConsumerTable3Config();
  csrConsumerFilter = new CsrConsumerFilter();
  mcForm = new McForm();
  clearedAllMultipleFilters = new Subject<boolean>();
  entitiesVll!: McValueLabelList;
  mcConsumerFilter = new McConsumerFilter();
  showAddEditModalMcb = new McBoolean();
  showProfileMcb = new McBoolean();
  addEditConsumerId = 0;
  mcConsumer!: McConsumer;
  mcGod = McGod.getInstance();
  mcConsumerImport = new McConsumerImport();
  webFileId!: number;
  showImportConsumersStep1 = new McBoolean();
  showImportConsumersStep2 = new McBoolean();

  constructor(private toastr: ToastrService, private router: Router) {
  }

  ngOnInit() {
    this.entitiesVll = new McValueLabelList();
    this.actLoad();
  }

  public actLoad(): void {
    this.csrConsumerFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');

    this.csrConsumerList.setPager(0, 100);
    this.csrConsumerList.loadByFilter(this.csrConsumerFilter, () => {
      this.csrConsumerList.items.map(item => {
        item.itemRouteLink = `/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${item.id}/profile/${item.id}`;
      });
      this.csrConsumerList.items = this.csrConsumerList.items.sort((a, b) => b.id - a.id);
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.clearedAllMultipleFilters.next(true);
    this.actLoad();
  }

  actShowAddEdit(itemId = 0) {
    this.addEditConsumerId = itemId;
    this.showAddEditModalMcb.setTrue();
  }

  actShowImportConsumersStep1() {
    this.showImportConsumersStep1.setTrue();
  }

  actCloseImportConsumerStep1() {
    this.showImportConsumersStep1.setFalse();
  }

  actImportConsumersGoToStep2(webFileId: number) {
    this.webFileId = webFileId;
    this.showImportConsumersStep2.setTrue();
    this.showImportConsumersStep1.setFalse();
  }

  actCloseImportConsumersStep2() {
    this.showImportConsumersStep2.setFalse();
  }

  actGoToDataImport() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/data/import/add`);
  }

  actPrevToImportConsumersStep1() {
    this.showImportConsumersStep1.setTrue();
    this.showImportConsumersStep2.setFalse();
  }

  importedConsumers(webFileId: number) {
    this.showImportConsumersStep2.setFalse();
    this.mcConsumerImport.loadByEntityIdAndWebFileId(McGod.getLoggedEntityIdFromToken(), webFileId, () => {
      this.toastr.success(`${McGod.t('cc.consumers.consumers-successfully-imported')}. ` + `${McGod.t('cc.common.processed')}: ` + this.mcConsumerImport.processedRecords + ' ' +
                                  `${McGod.t('cc.common.added')}: ` + this.mcConsumerImport.addedRecords + ' ' + `${McGod.t('cc.common.updated')}: ` + this.mcConsumerImport.updatedRecords + ' ' +
                                  `${McGod.t('cc.common.skipped')}: ` + this.mcConsumerImport.skippedRecords, '', {timeOut: 6000, extendedTimeOut: 4000});

    });
    this.actLoad();
  }

  actOnAddEditSaved() {
    this.addEditConsumerId = -1;
    this.showAddEditModalMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.consumer.consumer-saved-successfully')}.`, '', {timeOut: 6000, extendedTimeOut: 4000});
    this.actLoad();
  }

  actCancel() {
    this.addEditConsumerId = -1;
    this.showAddEditModalMcb.setFalse();
    this.showProfileMcb.setFalse();
    this.mcConsumer = new McConsumer();
  }

  onTableItemAction(mcButton: McHtmlButton) {
  }
}
