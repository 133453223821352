/*BbmTestObj*/
import {McBillingPayoutFileFeCtdGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CurrencyUtil} from "../common/currency-util";

export class McBillingPayoutFileFeCtd extends McBillingPayoutFileFeCtdGENERATED {

  public static ACTION_VIEW = 'VIEW';
  public static ACTION_DOWNLOAD_FILE = 'DOWNLOAD-FILE';
  public static ACTION_SEND_VIA_EBICS = 'SEND VIA EBICS';
  public static ACTION_DELETE = 'DELETE';
  public static ACTION_UPLOAD_FILE = 'UPLOAD_FILE';
  private _fldActions: McHtml | null = null;
  private _fldNameLink: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McBillingPayoutFileFeCtd();
  }


  // ---------------------------------------------------------------------

  fldActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> View file`, McBillingPayoutFileFeCtd.ACTION_VIEW, this, [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.common.upload-file')}`, McBillingPayoutFileFeCtd.ACTION_UPLOAD_FILE, this, [McGod.PERM_MC_BILLING_UPLOAD_PAYOUT_FILE], '', ActionOptions.OPTION_EDIT);
      if (this.isTypeCdEBICS() && !this.isStatusCdSENT_TO_BANK()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-paper-plane mc-cursor-pointer"></i> ${this.t('cc.ebics.send-via-ebics')}`, McBillingPayoutFileFeCtd.ACTION_SEND_VIA_EBICS, this, [McGod.PERM_EB_FACTORING_EBICS_PAYOUT_FILES], '', ActionOptions.OPTION_EDIT);
      }
      if (!this.isStatusCdFAILED()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-download mc-cursor-pointer"></i> Download file`, McBillingPayoutFileFeCtd.ACTION_DOWNLOAD_FILE, this, [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_DOWNLOAD], ``, ActionOptions.OPTION_VIEW);
      }
      if (this.isFinished()) {
        actSet.addActionB(`<i class="far mc-submit-color fa-trash-alt mc-cursor-pointer"></i> Delete file`, McBillingPayoutFileFeCtd.ACTION_DELETE, this, [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_DELETE], '', ActionOptions.OPTION_DELETE);
      }

      this._fldActions = html;
    }
    return this._fldActions.items;
  }

  fldPayoutDate(): string {
    if (this.payoutDate) {
      return McDateUtils.newFormatDateString(this.payoutDate);
    }
    return '';
  }

  isFinished() {
    return !this.isStatusCdPENDING() && !this.isStatusCdIN_PROGRESS();
  }

  getPayoutFileIcon(): string {
    if (this.isStatusCdFAILED()) {
      return `<div class="icon-tooltip mr-2">
                    <img style="height: 19px;" src="assets/images/failed.svg">
                    <span class="icon-tooltip-text">Failed</span></div>`;
    }
    if (this.isStatusCdIN_PROGRESS()) {
      return `<div class="icon-tooltip mr-2">
                    <img style="height: 19px;" src="assets/images/in-progress.svg">
                    <span class="icon-tooltip-text">In Progress</span></div>`;
    }
    if (this.isStatusCdPENDING()) {
      return `<div class="icon-tooltip mr-2">
                    <img style="height: 19px;" src="assets/images/pending.svg">
                    <span class="icon-tooltip-text">Pending</span></div>`;
    }
    if (this.isStatusCdSENT_TO_BANK()) {
      return `<div class="icon-tooltip mr-2">
                    <img style="height: 19px;" src="assets/images/common-icons/done-icon.svg">
                    <span class="icon-tooltip-text">Sent to bank</span></div>`;
    }
    return '';
  }

  getFilenameGrayout() {
        return `<span class="mc-disable-button">${this.fileName}</span>`;
    }

  getFilenameWithIcon() {
    if (!this.isStatusCdCREATED()) {
      return this.getPayoutFileIcon() + this.getFilenameGrayout();
    }
    if (!this._fldNameLink) {
      const html = new McHtml();
      html.addLinkB(`<div class="icon-tooltip mr-2">
                    <img style="height: 19px;" src="assets/images/file-csv-solid.svg">
                    <span class="icon-tooltip-hidden"></span></div> ${this.fileName}`, `entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/payout/${this.id}`, this, [McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW], 'mc-blue-link', ActionOptions.OPTION_VIEW);
      this._fldNameLink = html;
    }
    return this._fldNameLink.items;
  }

  fldCreatedDateTime(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sysCreatedDatetime);
    }
    return '';
  }

  fldTotalFileAmount() {
    return CurrencyUtil.format(this.totalFileAmount, this.mcCurrency.code);
  }

  formatMoneyValues(sum: number) {
    return sum.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
  }


}
