/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrContractPreDefined} from '../models/csr-contract-pre-defined.model';
import {CsrContractPreDefinedService} from '../services/csr-contract-pre-defined.service';
import {ICsrContractPreDefined} from '../_generated/csr-contract-pre-defined.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {CsrDiscount} from '../models/csr-discount.model';
import {McProductList} from '../models/mc-product-list.model';
import {CsrReccuringOptionsList} from '../models/csr-reccuring-options-list.model';


export abstract class CsrContractPreDefinedGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
  public static readonly STATUS_CD_LIST__ALL = [
    CsrContractPreDefinedGENERATED.STATUS_CD_ACTIVE,
    CsrContractPreDefinedGENERATED.STATUS_CD_INACTIVE,
    CsrContractPreDefinedGENERATED.STATUS_CD_PENDING
];

    public static readonly VISIBILITY_CD_PUBLIC: string = 'PUBLIC';
    public static readonly VISIBILITY_CD_PRIVATE: string = 'PRIVATE';
  public static readonly VISIBILITY_CD_LIST__ALL = [
    CsrContractPreDefinedGENERATED.VISIBILITY_CD_PUBLIC,
    CsrContractPreDefinedGENERATED.VISIBILITY_CD_PRIVATE
];

  public apiService: CsrContractPreDefinedService;
  public _rawJson: ICsrContractPreDefined;
    id: number = 0;
  description = '';
  durationInMonths: number;
  endDate: number;
  idCsrDiscount: number;
  idMcEntity: number;
  name = '';
  originalAmount: number;
  pricePerMonth: number;
  startDate: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  totalAmount: number;
  totalNetto: number;
  visibilityCd: string;
  discount: CsrDiscount;  priceUnit: any;  products: McProductList;  reccuringOptions: CsrReccuringOptionsList;

  public properties: string[] = ['id', 'description', 'durationInMonths', 'endDate', 'idCsrDiscount', 'idMcEntity', 'name', 'originalAmount', 'pricePerMonth', 'productsJsonb', 'reccuringOptionsJsonb', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'totalNetto', 'visibilityCd', 'discount', 'priceUnit', 'products', 'reccuringOptions'];
  public propertiesRegular: string[] = ['id', 'description', 'durationInMonths', 'endDate', 'idCsrDiscount', 'idMcEntity', 'name', 'originalAmount', 'pricePerMonth', 'productsJsonb', 'reccuringOptionsJsonb', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalAmount', 'totalNetto', 'visibilityCd', 'priceUnit'];
  public propertiesSpecial: string[] = ['discount', 'products', 'reccuringOptions'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrContractPreDefinedGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(CsrContractPreDefinedGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));
    list.add(CsrContractPreDefinedGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = CsrContractPreDefinedGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getVisibilityCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrContractPreDefinedGENERATED.VISIBILITY_CD_PUBLIC, McGod.t('Show to everybody'));
    list.add(CsrContractPreDefinedGENERATED.VISIBILITY_CD_PRIVATE, McGod.t('Show only in internal app'));


    return list;
  }

  public static getVisibilityCdLabel(aCd: string): string {
    const list = CsrContractPreDefinedGENERATED.getVisibilityCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrContractPreDefined {
    console.error('MTCN-ERROR: Not Implemented: CsrContractPreDefined::createNewInstance(). Add this method to final class and return new CsrContractPreDefined();');
    throw new Error('Cannot CsrContractPreDefined::createNewInstance(). Add this method to final class and return new CsrContractPreDefined();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrContractPreDefined): CsrContractPreDefined {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrContractPreDefinedService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrContractPreDefined AABB()');
          if (this._rawJson['discount']) {
        this.discount = CsrDiscount.createFromJson(this._rawJson['discount']);
      }
      if (this._rawJson['products']) {
        this.products = McProductList.createFromJson(this._rawJson['products']);
      }
      if (this._rawJson['reccuringOptions']) {
        this.reccuringOptions = CsrReccuringOptionsList.createFromJson(this._rawJson['reccuringOptions']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getOriginalAmountStr(): string {
       return McGod.formatNumberToMoney(this.originalAmount);
    }

    public getPricePerMonthStr(): string {
       return McGod.formatNumberToMoney(this.pricePerMonth);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalAmount);
    }

    public getTotalNettoStr(): string {
       return McGod.formatNumberToMoney(this.totalNetto);
    }

    public getPriceUnitStr(): string {
       return McGod.formatNumberToMoney(this.priceUnit);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('discount')) {
        if (this.discount != null) {
            dto['discount'] = this.discount.toDto();
        } else {
            dto['discount'] = null;
        }
    }
    if (this.hasOwnProperty('products')) {
        if (this.products != null) {
            dto['products'] = this.products.toDto();
        } else {
            dto['products'] = [];
        }
    }
    if (this.hasOwnProperty('reccuringOptions')) {
        if (this.reccuringOptions != null) {
            dto['reccuringOptions'] = this.reccuringOptions.toDto();
        } else {
            dto['reccuringOptions'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return CsrContractPreDefinedGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === CsrContractPreDefinedGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === CsrContractPreDefinedGENERATED.STATUS_CD_INACTIVE);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === CsrContractPreDefinedGENERATED.STATUS_CD_PENDING);

    return result;
  }


  public getVisibilityCdLabel(): string {
    return CsrContractPreDefinedGENERATED.getVisibilityCdLabel(this.visibilityCd);
  }


 public  isVisibilityCdPUBLIC(): boolean {
    const result = (this.visibilityCd === CsrContractPreDefinedGENERATED.VISIBILITY_CD_PUBLIC);

    return result;
  }

 public  isVisibilityCdPRIVATE(): boolean {
    const result = (this.visibilityCd === CsrContractPreDefinedGENERATED.VISIBILITY_CD_PRIVATE);

    return result;
  }


  
}
