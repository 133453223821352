import {Component, OnInit} from '@angular/core';
import {
  EbConsumerBlacklistList,
  EbConsumerBlacklist,
  EbConsumerBlacklistTable3Config,
  McForm, McHtmlButton, McBoolean, McUserList, EbConsumerBlacklistFilter, McConsumer, McGod, McValueLabelList
} from '@miticon-ui/mc-core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-out-factoring-blacklist-list-page',
  templateUrl: './mc-out-factoring-blacklist-list-page.component.html',
})
export class McOutFactoringBlacklistListPageComponent implements OnInit {

  ebConsumerBlacklistList!: EbConsumerBlacklistList;
  ebConsumerBlackList = new EbConsumerBlacklist();
  ebConsumerBlacklistTable3Config = new EbConsumerBlacklistTable3Config();
  mcForm = new McForm();
  mcGod = McGod.getInstance();

  showAddEditModalMcb = new McBoolean();
  showRemoveFromBlacklistMcb = new McBoolean();
  addEditConsumerId = 0;
  ebConsumerBlacklistFilter = new EbConsumerBlacklistFilter();
  usersThatBlacklisted = new McUserList();
  userThatAreBlacklistedVll = new McValueLabelList();
  usersThatRemovedFromBlacklist = new McUserList();
  usersThatAreRemovedFromBlacklistVll = new McValueLabelList();
  removeConsumerBlacklist = new EbConsumerBlacklist();
  notifyMessage = {message: '', type: ''};

  // ---------------------------------------------------------------------
  constructor(private toastr: ToastrService) {
    this.ebConsumerBlacklistTable3Config = new EbConsumerBlacklistTable3Config();
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.ebConsumerBlacklistList = new EbConsumerBlacklistList();
    this.ebConsumerBlacklistList.setPager(0, 100);
    this.actLoad();

    if (this.ebConsumerBlackList.exists()) {
      this.ebConsumerBlackList.loadById(this.addEditConsumerId);
    } else {
      this.ebConsumerBlackList = new EbConsumerBlacklist();
    }
  }

  actShowAddEdit(itemId = 0) {
    this.addEditConsumerId = itemId;
    this.showAddEditModalMcb.setTrue();
  }

  actShowRemoveFromBlacklist(ebConsumerBlacklist: EbConsumerBlacklist) {
    if (ebConsumerBlacklist.exists()) {
      if (ebConsumerBlacklist.removedDate) {
        this.notifyMessage.message = 'Consumer is already removed from blacklist.';
        this.notifyMessage.type = 'error';
      } else {
        this.removeConsumerBlacklist = ebConsumerBlacklist;
        this.showRemoveFromBlacklistMcb.setTrue();
      }
    }
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebConsumerBlacklistFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebConsumerBlacklistFilter.addedByUserIds = this.mcForm.getValueArray('selThatBlacklisted');
    this.ebConsumerBlacklistFilter.removedByUserIds = this.mcForm.getValueArray('selThatRemovedFromBlacklist');
    this.ebConsumerBlacklistList.loadByFilter(this.ebConsumerBlacklistFilter, () => {
    });
    this.usersThatBlacklisted.loadAllThatAddedConsumersOnBlacklist(() => {
      this.userThatAreBlacklistedVll = this.usersThatBlacklisted.toVll('id', 'fldFullName()');
    });
    this.usersThatRemovedFromBlacklist.loadAllThatRemovedConsumersFromBlacklist(() => {
      this.usersThatAreRemovedFromBlacklistVll = this.usersThatRemovedFromBlacklist.toVll('id', 'fldFullName()');
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selThatBlacklisted')?.get('value')?.setValue('');
    this.mcForm.getControl('selThatRemovedFromBlacklist')?.get('value')?.setValue('');
    this.actLoad();
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McConsumer.ACTION_SHOW_EDIT) {
      this.actShowRemoveFromBlacklist(mcButton.item);
    }
  }

  actCloseModal() {
    this.showAddEditModalMcb.setFalse();
    this.showRemoveFromBlacklistMcb.setFalse();
    this.removeConsumerBlacklist = new EbConsumerBlacklist();
  }

  eventConsumerBlacklistSaved() {
    this.addEditConsumerId = -1;
    this.actLoad();
    this.showAddEditModalMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.out-factoring.consumer-successfully-blacklisted')}.`, '', {timeOut: 4000, extendedTimeOut: 10});
  }

  eventRemovedFromBlacklist() {
    this.actLoad();
    this.showRemoveFromBlacklistMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.out-factoring.consumer-successfully-removed-from-blacklist')}.`, '', {timeOut: 4000, extendedTimeOut: 10});
  }
}
