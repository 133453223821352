/*BbmTestObj*/
import {PmTransactionGENERATED} from '../_generated/pm-transaction-generated.model';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McGod} from '../extra/mc-god.service';
import {McHtmlActionSet} from '../_core/mc-html-action-set';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McDateUtils} from '../common/mc-date-utils';
import {CurrencyUtil} from '../common/currency-util';

export class PmTransaction extends PmTransactionGENERATED {

  public static ACTION_VIEW_CONSUMER_PROFILE = 'VIEW-CONSUMER-PROFILE';
  public static ACTION_VIEW_DETAILS = 'VD';
  public static ACTION_RECORD_PAYMENT = 'RP';
  public static ACTION_EXPORT = 'EXPORT';
  public static ACTION_MARK_AS_SENT_TO_INKASSO = 'MARK-AS-SENT-TO-INKASSO';
  public static ACTION_MARK_AS_RETURNED_FROM_INKASSO = 'MARK-AS-RETURNED-FROM-INKASSO';
  public static ACTION_EDIT = 'EDIT';
  public static ACTION_SETTLE_WITH_BALANCE = 'SETTLE-WITH-BALANCE';
  public static ACTION_VIEW_CONSUMER_TRANSACTION = 'VIEW-CONSUMER-TRANSACTION';
  public static ACTION_VIEW_BILLING_ITEMS = 'VIEW-BILLING-ITEMS';
  public static ACTION_VIEW_PAYOUT_ITEMS = 'VIEW-PAYOUT-ITEMS';
  public static ACTION_CHANGE_COLLECTION_DATE = 'CHANGE-COLLECTION-DATE';
  public static ACTION_PAUSE_TRANSACTIONS = 'PAUSE-TRANSACTIONS';
  public static ACTION_UNPAUSE_TRANSACTIONS = 'UNPAUSE-TRANSACTIONS';
  public static ACTION_STORNO = 'STORNO';
  public static ACTION_MATCH = 'MATCH';
  public static FILTER_CREATE_TYPE = 'cc.common.create-type';
  public static FILTER_STATUS = 'cc.common.status';
  public static FILTER_PAUSE = 'Paused';
  public static FILTER_DATE_RANGE = 'Payment due date range';
  public static FILTER_ENTITIES = 'cc.consumer.studio';
  public static FILTER_DUNNING = 'cc.consumers.action.type.dunning';
  public static FILTER_TYPE = 'cc.jobs.edit.type';

  private _fldActions: McHtmlActionSet | null = null;
  private _fldActionsConsumerTransaction: McHtmlActionSet | null = null;


  public static override createNewInstance() {
    return new PmTransaction();
  }

  public static override getStatusCdVll() {  // FE dont need statuses like overwritten etc...
    const list = new McValueLabelList();
    list.add(PmTransaction.STATUS_CD_ACCEPTED, McGod.t('cc.eb-factoring.accepted'));
    list.add(PmTransaction.STATUS_CD_REJECTED, McGod.t('cc.eb-factoring.rejected'));
    list.add(PmTransaction.STATUS_CD_NEW, McGod.t('cc.common.view.new'));
    list.add(PmTransaction.STATUS_CD_EXPORTED, McGod.t('cc.eb-sepa-export.exported'));
    list.add(PmTransaction.STATUS_CD_PAID, McGod.t('cc.factoring.transactions.paid'));
    list.add(PmTransaction.STATUS_CD_RETURNED, McGod.t('cc.factoring.transactions.returned'));
    list.add(PmTransaction.STATUS_CD_SETTLED, McGod.t('Settled'));
    list.add(PmTransaction.STATUS_CD_FOR_DUNNING, McGod.t('cc.factoring.transactions.for-dunning'));
    list.add(PmTransaction.STATUS_CD_SENDING_TO_INKASSO, McGod.t('cc.transactions.sending-to-inkasso'));
    list.add(PmTransaction.STATUS_CD_SENT_TO_INKASSO, McGod.t('cc.transactions.sent-to-inkasso'));
    list.add(PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO, McGod.t('cc.transactions.should-go-to-inkasso'));
    list.add(PmTransaction.STATUS_CD_CANCELLED, McGod.t('Cancelled'));
    list.add(PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO, McGod.t('cc.transactions.returned-from-inkasso'));

    return list;
  }

  public static getPotentialMatchesStatusCdVll() {
    const list = new McValueLabelList();
    list.add(PmTransaction.STATUS_CD_PAID, McGod.t('cc.factoring.transactions.paid'));
    list.add(PmTransaction.STATUS_CD_ACCEPTED, McGod.t('cc.eb-factoring.accepted'));
    list.add(PmTransaction.STATUS_CD_RETURNED, McGod.t('cc.factoring.transactions.returned'));
    list.add(PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO, McGod.t('cc.transactions.returned-from-inkasso'));
    list.add(PmTransaction.STATUS_CD_SENDING_TO_INKASSO, McGod.t('cc.transactions.sending-to-inkasso'));
    list.add(PmTransaction.STATUS_CD_SENT_TO_INKASSO, McGod.t('cc.transactions.sent-to-inkasso'));
    list.add(PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO, McGod.t('cc.transactions.should-go-to-inkasso'));
    return list;
  }

  public static getStornoReasonCdVll() {
    const list = new McValueLabelList();
    /*list.add('minor-the-juvenile-made-an-agreement', McGod.t('cc.storno.reason.minor-the-juvenile-made-an-agreement'));
    list.add('owner-request', McGod.t('cc.storno.reason.on-entity-owner-request'));
    list.add('membership-administration-request', McGod.t('cc.storno.reason.on-membership-administration-request'));*/
    // list.add('covid', McGod.t('cc.storno.reason.covid-19'));
    // list.add('storno-at-admin-request', McGod.t('cc.storno.reason.storno-at-admin-request'));
    list.add('correction', McGod.t('cc.storno.reason.correction'));
    /*list.add('cancellation-in-14-days-notice-period', McGod.t('cc.storno.reason.cancellation-in-14-days-notice-period'));
    list.add('duplicate', McGod.t('cc.storno.reason.duplicate'));
*/
    return list;
  }

  static getFactoredServiceTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add('FACTORING_NONDELAYED', 'factored');
    list.add('FACTORING_DELAYED', 'non-factored');
    list.add('SERVICE_NONDELAYED', 'serviced');
    list.add('SERVICE_DELAYED', 'serviced over limit');

    return list;
  }

  static getPauseUnpausedCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add('PAUSED', McGod.t('cc.common.edit.yes'));
    list.add('UNPAUSED', McGod.t('cc.common.edit.no'));
    return list;
  }

  static getDunningCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add('IN_DUNNING', McGod.t('cc.factoring.transactions.in-dunning'));
    list.add('DUNNING_COMPLETED', McGod.t('cc.factoring.transactions.completed-dunning'));

    return list;
  }

  // ---------------------------------------------------------------------
  public fldActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(`<i class="fas fa-link"></i> ${this.t('cc.my-factoring.view-details')}`, PmTransaction.ACTION_VIEW_DETAILS, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(`<i class="fas fa-user"></i> ${McGod.t('cc.common.view-consumer-profile')}`, PmTransaction.ACTION_VIEW_CONSUMER_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);

      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  fldPotentialMatchesActions() {
    if (!this._fldActions) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(`<i class="fas fa-link"></i> ${this.t('cc.my-factoring.view-details')}`, PmTransaction.ACTION_VIEW_DETAILS, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(`<i class="fas fa-user"></i> ${McGod.t('cc.common.view-consumer-profile')}`, PmTransaction.ACTION_VIEW_CONSUMER_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(`<i class="fas fa-bullseye"></i> ${McGod.t('cc.incoming-payments.match')}`, PmTransaction.ACTION_MATCH, this, [McGod.PERM_EB_FACTORING_OUT_TRANSACTION_EDIT], '', ActionOptions.OPTION_EDIT);
      this._fldActions = actionSet;
    }
    return this._fldActions;
  }

  fldActionsConsumerTransaction() {
    if (!this._fldActionsConsumerTransaction) {
      const actionSet = new McHtmlActionSet();
      actionSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.my-factoring.view-details')}`, PmTransaction.ACTION_VIEW_DETAILS, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_VIEW], '', ActionOptions.OPTION_VIEW);
      actionSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.common.view-consumer-profile')}`, PmTransaction.ACTION_VIEW_CONSUMER_PROFILE, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      if (this.canPauseUnpauseTransactionFlg()) {
        if (this.flgPaused) {
          actionSet.addActionB(`<i class="fas mc-submit-color fa-play"></i> Unpause transaction`, PmTransaction.ACTION_UNPAUSE_TRANSACTIONS, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION], '', ActionOptions.OPTION_EDIT);
        } else {
          actionSet.addActionB(`<i class="fas mc-submit-color fa-pause"></i> Pause transaction`, PmTransaction.ACTION_PAUSE_TRANSACTIONS, this, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION], '', ActionOptions.OPTION_EDIT);
        }
      }
      this._fldActionsConsumerTransaction = actionSet;
    }
    return this._fldActionsConsumerTransaction;
  }

  canPauseUnpauseTransactionFlg(): boolean {
    return !this.isStatusCdREJECTED() && !this.isStatusCdSENT_TO_INKASSO() && !this.isStatusCdSETTLED() && !this.isStatusCdCANCELLED();
  }

  getStudioName() {
    return this.mcEntity.name;
  }

  getConsumerName() {
    // return `<div class="link-cell"><i class="fas fa-user tr-icon"></i> ${this.csrConsumer.displayName}</div>`;
    return `${this.csrConsumer.displayName}`;
  }

  getAmount() {
    return CurrencyUtil.format(this.amount, this.mcFactoring.mcCurrency.code);
  }

  getAmountDue() {
    return CurrencyUtil.format(this.amountDue, this.mcFactoring.mcCurrency.code);
  }

  public getAmountStr(): string {
    return CurrencyUtil.format(this.amount, this.mcFactoring.mcCurrency.code);
  }

  override getBalanceStr() {
    return CurrencyUtil.format(this.balance, this.mcFactoring.mcCurrency.code);
  }

  getExportedDateFormat(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateString(this.sysCreatedDatetime);
    }
    return '';
  }

  getCreatedDatetimeStr(): string {
    if (this.sysCreatedDatetime) {
      return McDateUtils.newFormatDateString(this.sysCreatedDatetime);
    }
    return '';
  }

  getDueDateStr(): string {
    if (this.dueDate) {
      return McDateUtils.newFormatDateString(this.dueDate);
    }
    return '';
  }

  getSentToInkassoDateTime() {
    if (this.sentToInkassoDatetime) {
      return McDateUtils.newFormatDateTimeString(this.sentToInkassoDatetime);
    }
    return '';
  }

  getPauseStartDateStr(): string {
    return McDateUtils.formatDateTimeToString(this.pauseStartDate);
  }

  getPauseEndDateStrD(): string {
    return McDateUtils.formatDateToString(this.pauseEndDate);
  }

  // Get icon for each status
  getStatusIcon(icon: string) {
    return `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/${icon}.svg">
                    <span class="icon-tooltip-text">${this.getStatusCdLabel()}</span></div>`;
  }

  // get icon for factoring or service
  getFactoringNonFactoring(reason: string, info: boolean) {
    return `${this.mcFactoring.isTypeCdSERVICE() ? `<img src="assets/mc-components-assets/${this.flgDelay ? 's-gray' : 's-blue'}.svg">`
      : `<img src="assets/mc-components-assets/${this.flgDelay ? 'f-gray' : 'f-blue'}.svg">`}` + `<div class="icon-tooltip">
     <img src="assets/mc-components-assets/${info ? 'info-blue3' : 'i4'}.svg">
     <span class="${info ? 'icon-tooltip-text' : 'icon-tooltip-hidden'}">${reason.includes('.') ? this.t(reason) : reason}</span></div>`;
  }

  // same for all statuses, can be implemented
  getInDunningIcon() {
    return ` ${this.isDunningProcess() ? `<div class="icon-tooltip">
                   <img src="assets/mc-components-assets/in-dunning-bell-orange-18.svg">
                   <span class="icon-tooltip-text">This transaction is in dunning.</span></div>` : ''}`;
  }

  // same for all statuses, can be implemented
  getDunningCompletedIcon() {
    return ` ${!this.isDunningProcess() ? `<div class="icon-tooltip">
                    <img src="assets/mc-components-assets/dunning-completed.svg">
                    <span class="icon-tooltip-text">Dunning completed.</span></div>` : ''}`;
  }

  checkIfPauseEndDateHasPassed() {
    return McDateUtils.compareCurrentAndChosenMomentDates(this.pauseEndDate)
  }

  getPausedIcon() {
    return `${this.pauseStartDate && !this.checkIfPauseEndDateHasPassed() ? `<div class="icon-tooltip">
                    <i class="far fa-pause-circle text-orange-color"></i><span class="icon-tooltip-text">
                    PAUSE ENDS: ${this.getPauseEndDateStrD()}<br/> PAUSED REASON: ${this.pauseUnpauseReason}</span></div>` : ''}`;
  }

  getIconLabel(): string {
    if (this.isStatusCdACCEPTED()) {
      if (this.flgDelay) {
        return `${this.getStatusIcon('accepted-delayed')}
                    ${this.getFactoringNonFactoring(this.reason, true)}
                   ${this.getPausedIcon()} `;
      } else {
        return `${this.getStatusIcon('accepted')}
                ${this.getFactoringNonFactoring(this.t('cc.transactions.fully-accepted'), true)}
                 ${this.getPausedIcon()}`;
      }
    }

    if (this.isStatusCdNEW()) {
      return `${this.getStatusIcon('new')} ${this.getFactoringNonFactoring('', false)} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdREJECTED()) {

      return `${this.getStatusIcon('rejected')} ${this.getFactoringNonFactoring(this.reason, true)} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdEXPORTED()) {
      const reason = `${this.t('cc.transactions.exported-on')} ${this.getExportedDateFormat()}`;
      return `${this.getStatusIcon('exported')} ${this.getFactoringNonFactoring(reason, true)} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdPAID()) {
      return `${this.getStatusIcon('paid')} ${this.getFactoringNonFactoring('', false)}
                   ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSETTLED()) {
      return `${this.getStatusIcon('settled')} ${this.getFactoringNonFactoring('', false)}
             ${this.getPausedIcon()}`;
    }


    // Todo - waithing for backend to add returnedTypeCd,returnedIsoCode,returnedIsoCodeLabel
    if (this.isStatusCdRETURNED()) {
      // const message = `${this.returnedTypeCd}: ${this.returnedIsoCode} - ${this.returnedIsoCodeLabel}`;
      return `${this.getStatusIcon('returned')} ${this.getFactoringNonFactoring('', true)} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSHOULD_GO_TO_INKASSO()) {
      return `${this.getStatusIcon('should-go-to-inkasso')} ${this.getFactoringNonFactoring('', false)}
        ${this.getPausedIcon()}`;

    }

    if (this.isStatusCdSENDING_TO_INKASSO()) {
      return `${this.getStatusIcon('sending-to-inkasso')} ${this.getFactoringNonFactoring('', false)}
         ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdSENT_TO_INKASSO()) {
      const message = `${this.t('cc.transactions.sent-on')} ${this.getSentToInkassoDateTime()}`;
      return `${this.getStatusIcon('sent-to-inkasso')} ${this.getFactoringNonFactoring(message, true)} ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdRETURNED_FROM_INKASSO()) {
      return `${this.getStatusIcon('returned-from-inkasso')} ${this.getFactoringNonFactoring('', false)} ${this.getInDunningIcon()}
       ${this.getPausedIcon()}`;
    }

    if (this.isStatusCdFOR_DUNNING()) {
      return `${this.getStatusIcon('for-dunning')} ${this.getFactoringNonFactoring('', false)}
              ${this.getInDunningIcon()} ${this.getPausedIcon()}`;
    }
    if (this.isStatusCdCANCELLED()) {
      return `${this.getStatusIcon('cancelled')} ${this.getFactoringNonFactoring('', false)}
               ${this.getPausedIcon()}`;
    }
    return '';
  }

  isDunningProcess(): boolean {
    return !!this.dunningStatusCd;
  }

  getIban(): string {
    return this.csrConsumer.iban;
  }

  showReturnFromInkasso(): boolean {
    return this.isStatusCdSENDING_TO_INKASSO() || this.isStatusCdSENT_TO_INKASSO();
  }
}

