<div class="row p-0 m-0 d-flex flex-row align-items-center">
  <h3 class="pr-2 pt-1">{{'cc.factoring.contracts' | translate}}</h3>
</div>

<div>
    <lib-mc-loader [showLoader]="ebFactoringList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebFactoringList.apiErrorFlg">
        Error: {{ebFactoringList.apiErrorMessage}}
    </div>

  <div class="mc-filter fancy-label ">

    <i class="fas fa-info-circle mc-tooltip-top-margin" ngbTooltip="{{'cc.my-factoring.search-by-contract-name' | translate}}" placement="bottom-left"></i>

      <mcc-fi-textbox [isClearEnabled]="true" label="{{'cc.common.search' | translate}}" name="txbSearchTerm" [mcForm]="mcForm" (eventEnterClicked)="actLoad()"></mcc-fi-textbox>

    <mcc-fi-select [mcForm]="mcForm" name="selTypeCd" placeholderOption="{{'cc.my-factoring.all' | translate}}" label="Contract type"
                   [optionsVll]="ebFactoringTypeCdsVll" [isSelectInFilter]="true"></mcc-fi-select>
      <mcc-multiselect-filter [optionVll]="ebFactoringStatusCdVll" label="{{'cc.common.view.status' | translate}}" name="selStatus" (eventSelectedItems)="onSelectedStatusCds($event)"
                              [clearedAllFilters]="clearedAllMultipleFilters">
      </mcc-multiselect-filter>



    <button class="mc-link mc-filter-clear-button filter-clear-top-right" (click)="actClearFilter()">{{'cc.my-factoring.clear-all' | translate}}</button>
    <button class="mc-button mc-filter-button" (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>


  </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>

  <mcc-modal1-wrapper [showMcb]="showRejectMcb" *ngIf="showRejectMcb.value" (eventCanceled)="onRejectModalCanceled()" modalSizeCd="small">
    <app-shared-factoring-reject-contract-part [ebFactoring]="ebFactoring" (eventRejected)="actRejected()"
    (eventCancel)="onRejectModalCanceled()"></app-shared-factoring-reject-contract-part>
  </mcc-modal1-wrapper>
  <mcc-modal1-wrapper *ngIf="ebFactoring && showProfileMcb.value" [showMcb]="showProfileMcb">
    <app-shared-factoring-profile-part [statusMyPendingMcb]="statusPendingMcb"
                                       [statusNotMyPendingMcb]="statusNotPendingMcb"
                                       (eventAccepted)="onAcceptContract()"
                                       (eventRejected)="onRejectContract()"
                                       (eventCanceled)="onCancelContract()"
                                       [ebFactoring]="ebFactoring"
                                       [ebFactoringId]="ebFactoring.id"></app-shared-factoring-profile-part>
  </mcc-modal1-wrapper>
  <mcc-dialog-box *ngIf="showAcceptContractDialogMcb.value" [enableCloseButtonFlg]="false"
                  title="{{'cc.my-factoring.accept-contract' | translate}}" name="accept-contract"
                  description="{{'cc.my-factoring.are-you-sure-you-want-to-accept-this-contract' | translate}}?"
                  [buttonsKvm]="acceptDialogButtonsKvm"
                  popupType="question"
                  (eventActionButtonClicked)="onMcDialogBoxAcceptContractActionButtonClicked($event)"></mcc-dialog-box>

    <!--  <a class="col-md-1 offset-md-11" routerLink="edit/0">Add New</a>-->

    <div *ngIf="ebFactoringList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="ebFactoringList"
                        [table3Config]="ebFactoringTable3Config"
                        (eventPaginationOrSortChanged)="actLoad()" (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
    </div>

</div>

<router-outlet></router-outlet>

