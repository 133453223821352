import {Component, OnInit} from '@angular/core';
import {
  McBillingPayoutFileFeCtdList,
  McBillingPayoutFileFeCtdTable3Config,
  McForm,
  McGod,
  McBoolean,
  McHtmlButton,
  McValueLabelList,
  McBillingPayoutFileFeCtdFilter,
  McUser,
  WebFile,
  GlobalActionEnums, McBillingPayoutFileFeCtd,
  McBillingPayoutFileFeCtdService, EbSepaExport, McBankAccountService, BaseDirective,
} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import * as fileSaver from 'file-saver';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'mc-out-billing-payout-file-list-page',
  templateUrl: './mc-out-billing-payout-file-list-page.component.html',
  styleUrls: ['./mc-out-billing-payout-file-list-page.component.scss']
})
export class McOutBillingPayoutFileListPageComponent extends BaseDirective implements OnInit {

  mcBillingPayoutFileFeCtdList = new McBillingPayoutFileFeCtdList();
  mcBillingPayoutFileFeCtdTable3Config = new  McBillingPayoutFileFeCtdTable3Config();
  mcBillingPayoutFileFilter = new McBillingPayoutFileFeCtdFilter();
  payoutFile = new McBillingPayoutFileFeCtd();
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  showAddEditPayoutFileMcb = new McBoolean();
  typeCdVll: McValueLabelList = EbSepaExport.getTypeCdVll();
  showDeleteMcb = new McBoolean();
  dialogDescription!: string;
  payoutType: McValueLabelList = EbSepaExport.getTypeCdVll();
  errorMsg = '';
  successMsg = '';
  clearDateRangeSubject = new Subject<boolean>();
  clearedAllMultipleFilters = new Subject<boolean>();
  createdByVll = new McValueLabelList();
  statusVll = new McValueLabelList();
  isFilterButtonDisabled!: boolean | undefined;
  paymentDateFrom: any;
  paymentDateTo: any;
  createdDateFrom: any;
  createdDateTo: any;
  createdBy: any[] = [];
  user = new McUser();
  webFile = new WebFile();
  globalActions = GlobalActionEnums;
  mcBillingPayoutFileFeCtd = new McBillingPayoutFileFeCtd();
  interval: any;
  typeCds: any[] = [];
  bankVll: McValueLabelList = new McValueLabelList();
  bankIds = [];
  showUploadFileMcb = new McBoolean();
  payoutFileId = 0;

  // ---------------------------------------------------------------------
  constructor(private router: Router,
              private mcBillingPayoutFileFeCtdService: McBillingPayoutFileFeCtdService,
              private mcBankAccountService: McBankAccountService,
              private toastr: ToastrService) {
    super();
    if (history.state.payoutFileDeletedMessage) {
      this.successMsg = history.state.payoutFileDeletedMessage;
    }
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcBankAccountService.bankAccounts.map(item => {
      this.bankVll.add(item.id, item.bankName);
    });

    this.isFilterButtonDisabled = false;

    this.mcBillingPayoutFileFeCtdList = new McBillingPayoutFileFeCtdList();
    this.mcBillingPayoutFileFeCtdList.setPager(0, 100);

    this.actLoad();
  }

  // ---------------------------------------------------------------------

  doFilterPropertiesHaveValues(): boolean | undefined {
    const searchTerm = this.mcForm.getValue('txbSearchTerm');
    const payoutDateFrom = this.paymentDateFrom;
    const payoutDateTo = this.paymentDateTo;
    const createdDateFrom = this.createdDateFrom;
    const createdDateTo = this.createdDateTo;
    const createdBy = this.createdBy;
    const payoutType = this.payoutType;
    if (searchTerm === '' && !payoutDateFrom && !payoutDateTo
      && !createdDateFrom && !createdDateTo && !payoutType && createdBy.length === 0) {
      return true;
    }
    return undefined;
  }

  // TODO Find better way to get unique list of items
  getUniqueValueLabelList(list: McValueLabelList) {
    const uniqueListItems = list.items
      .map(e => e.value)
      .map((e, i, final) => final.indexOf(e) === i && i)
      // @ts-ignore
      .filter(obj => list.items[obj])
      // @ts-ignore
      .map(e => list.items[e]);
    return uniqueListItems;
  }

  actClearFilter() {
    if (this.mcForm.getControl('txbSearchTerm')) {
      this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    }
    this.clearDateRangeSubject.next(true);
    this.clearedAllMultipleFilters.next(true);
    this.actLoad();
  }

  onCreatePayoutFile() {
    this.showAddEditPayoutFileMcb.setTrue();
  }

  onAddEditPayoutFileCancelEmit() {
    this.showAddEditPayoutFileMcb.setFalse();
  }

  onTableItemAction(mcHtmlButton: McHtmlButton) {
    this.payoutFile = mcHtmlButton.item;
    if (mcHtmlButton.actionCdForEvent === McBillingPayoutFileFeCtd.ACTION_VIEW) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/payout/${mcHtmlButton.item.id}`);
    } else if (mcHtmlButton.actionCdForEvent === McBillingPayoutFileFeCtd.ACTION_SEND_VIA_EBICS) {
      this.mcBillingPayoutFileFeCtdService.sendViaEbics(mcHtmlButton.item.id).pipe(take(1)).subscribe(res => {
        this.actLoad();
      });
    } else if (mcHtmlButton.actionCdForEvent === McBillingPayoutFileFeCtd.ACTION_DOWNLOAD_FILE) {
      if (this.payoutFile.statusCd !== McBillingPayoutFileFeCtd.STATUS_CD_FAILED) {
        this.webFile.getFileByIdAndType(this.payoutFile.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT, () => {
          this.webFile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, this.webFile.filename);
          });
        }, () => {
          this.errorMsg = this.webFile.apiErrorMessage;
        });
      }
    } else if (mcHtmlButton.actionCdForEvent === McBillingPayoutFileFeCtd.ACTION_DELETE) {
      this.dialogDescription = `Are you sure you want to delete <span class="font-weight-bold">${this.payoutFile.fileName} ?</span> <br> This action cannot be undone.`;
      this.showDeleteMcb.setTrue();
    } else if (mcHtmlButton.actionCdForEvent === McBillingPayoutFileFeCtd.ACTION_UPLOAD_FILE) {
      this.showUploadFileMcb.setTrue();
      this.payoutFileId = mcHtmlButton.item.id;
    }
  }

  onDeletePayoutFileActionButtonClicked(value: string) {
    if (value === this.globalActions.Delete) {
      this.payoutFile.delete(() => {
        this.successMsg = `${this.payoutFile.fileName} ${this.mcGod.t('cc.common.deleted-successfully')}.`;
        this.showDeleteMcb.setFalse();
        this.actLoad();
      }, () => {
        this.errorMsg = this.payoutFile.apiErrorMessage;
      });
    } else {
      this.showDeleteMcb.setFalse();
    }
  }

  onCreatePayoutFileSaveEmit() {
    this.successMsg = McGod.t('cc.payout.payout-files-are-scheduled-for-creation');
    this.showAddEditPayoutFileMcb.setFalse();
    this.actLoad();
  }

  onPayoutDateFrom(value: any) {
    if (value === null) {
      this.paymentDateFrom = null;
    } else {
      this.paymentDateFrom = value;
    }
  }

  onPayoutDateTo(value: any) {
    if (value === null) {
      this.paymentDateTo = null;
    } else {
      this.paymentDateTo = value;
    }
  }

  onCreatedDateFrom(value: any) {
    if (value === null) {
      this.createdDateFrom = value;
    } else {
      this.createdDateFrom = value;
    }
  }

  onCreatedDateTo(value: any) {
    if (value === null) {
      this.createdDateTo = null;
    } else {
      this.createdDateTo = value;
    }
  }

  onSelectCreatedBy(value: any[]) {
    this.createdBy = value;
  }
  onPayoutType(value: any[]) {
    this.typeCds = value;
  }

  onSelectedBank(bankVll: []) {
    this.bankIds = bankVll;
  }

  onUploadFile($event: any) {
    this.mcBillingPayoutFileFeCtdService.uploadFile(this.payoutFileId, $event.file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.showUploadFileMcb.setFalse();
        this.actLoad();
        this.toastr.success('Success');
      }, (err) => {
        this.toastr.error(err.error.message);
      });
  }

  public actLoad(): void {
    this.loadItemsInternal();
    this.interval = setInterval(() => this.loadItemsInternal(), 3000);
  }

  private loadItemsInternal() {
    this.mcBillingPayoutFileFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.mcBillingPayoutFileFilter.payoutDateFrom = this.paymentDateFrom;
    this.mcBillingPayoutFileFilter.payoutDateTo = this.paymentDateTo;
    this.mcBillingPayoutFileFilter.createdDateFrom = this.createdDateFrom;
    this.mcBillingPayoutFileFilter.createdDateTo = this.createdDateTo;
    this.mcBillingPayoutFileFilter.createdBy = this.createdBy;
    this.mcBillingPayoutFileFilter.typeCds = this.typeCds;
    this.mcBillingPayoutFileFilter.bankAccountIds = this.bankIds;

    this.mcBillingPayoutFileFeCtdList.loadByFilter(this.mcBillingPayoutFileFilter, () => {
      this.createdByVll = new McValueLabelList();
      this.isFilterButtonDisabled = this.mcBillingPayoutFileFeCtdList.getCount() === 0 && this.doFilterPropertiesHaveValues();
      this.mcBillingPayoutFileFeCtdList.items.map(item => this.createdByVll.add(item.sysCreatedUserId, item.createdBy));
      this.createdByVll.items = this.getUniqueValueLabelList(this.createdByVll);
      this.createdByVll.items.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      const isTherePendingOrInProgressPayoutFile1 = this.mcBillingPayoutFileFeCtdList.items.some(item => item.isStatusCdPENDING() || item.isStatusCdIN_PROGRESS());
      if (!isTherePendingOrInProgressPayoutFile1 && this.interval !== undefined) {
          this.successMsg = '';
          clearInterval(this.interval);
      }
    });
  }
}
