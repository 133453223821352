import {Component, Inject, OnInit} from '@angular/core';
import {
  EbInkassoExportList,
  EbInkassoExport,
  EbInkassoExportTable3Config,
  McForm,
  EbInkassoExportFilter, WebFile, McHtmlButton, McValueLabelList
} from '@miticon-ui/mc-core';
import * as fileSaver from "file-saver";
import {Subject} from 'rxjs';


@Component({
  selector: 'mc-out-factoring-inkasso-export-list-page',
  templateUrl: './mc-out-factoring-inkasso-export-list-page.component.html',
})
export class McOutFactoringInkassoExportListPageComponent implements OnInit {

  ebInkassoExportList!: EbInkassoExportList;
  ebInkassoExportTable3Config = new  EbInkassoExportTable3Config();
  mcForm = new McForm();
  ebInkassoExportFilter = new EbInkassoExportFilter();
  webFile = new WebFile();
  errorMsg!: string;
  successMsg!: string;
  clearDateRangeSubject = new Subject<boolean>();
  exportedDateFrom: any;
  exportedDateTo: any;
  exportedByVll = new McValueLabelList();

  ngOnInit() {
    this.ebInkassoExportList = new EbInkassoExportList();
    this.ebInkassoExportList.setPager(0, 100);
    this.ebInkassoExportList.loadByFilter(this.ebInkassoExportFilter, () => {
      this.ebInkassoExportList.items.forEach(item => this.exportedByVll.add(item.sysCreatedUserId, item.exportedByUserName));
      this.getUniqueExportedVll();
    });
    this.actLoad();
  }

  // ---------------------------------------------------------------------

  public actLoad(): void {
    this.ebInkassoExportFilter = new EbInkassoExportFilter();
    this.ebInkassoExportFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');
    this.ebInkassoExportFilter.exportedDateFrom = this.exportedDateFrom ? this.exportedDateFrom : null;
    this.ebInkassoExportFilter.exportedDateTo = this.exportedDateTo ? this.exportedDateTo : null;
    this.ebInkassoExportFilter.exportedBy = this.mcForm.getValue('selExportedBy');

    this.ebInkassoExportList.loadByFilter(this.ebInkassoExportFilter, () => {
      this.ebInkassoExportList.items.forEach(item => this.exportedByVll.add(item.sysCreatedUserId, item.exportedByUserName));
      this.getUniqueExportedVll();
    });
  }

  getUniqueExportedVll() {
    this.exportedByVll.items.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
    });
    this.exportedByVll.items = this.getUniqueValueLabelList(this.exportedByVll);
  }

  getUniqueValueLabelList(list: McValueLabelList) {
    const uniqueListItems = list.items
      .map(e => e.value)
      .map((e, i, final) => final.indexOf(e) === i && i)
      // @ts-ignore
      .filter(obj => list.items[obj])
      // @ts-ignore
      .map(e => list.items[e]);
    return uniqueListItems;
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.mcForm.getControl('selExportedBy')?.get('value')?.setValue('');
    this.clearDateRangeSubject.next(true);
    this.actLoad();
  }

  onTableItemAction(mcHtmlButton: McHtmlButton) {
    if (mcHtmlButton.actionCdForEvent === EbInkassoExport.ACTION_DOWNLOAD) {
      this.webFile.getFileByIdAndType(mcHtmlButton.item.idWebFile, WebFile.TYPE_CD_IN_INKASSO_EXPORT, () => {
        this.downloadFile();
      });
    }
  }

  downloadFile() {
    this.webFile.downloadFile().subscribe(next => {
      fileSaver.saveAs(next, `${this.webFile.originalFilename}`);
    }, err => {
      this.errorMsg = `Error downloading file ${this.webFile.originalFilename}`;
    });
  }

  onExportedDateFrom(value: any) {
    if (value !== null) {
      this.exportedDateFrom = value;
    } else {
      this.exportedDateFrom = null;
    }
  }

  onExportedDateTo(value: any) {
    if (value !== null) {
      this.exportedDateTo = value;
    } else {
      this.exportedDateTo = null;
    }
  }
}
