import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {McGod, WebFile} from "@miticon-ui/mc-core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'lib-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  loaderFlg = false;
  webFile = new WebFile();
  rawFile!: File;
  @Output() eventUploaded = new EventEmitter<any>();

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  onFileSelected(rawFile: File) {
    this.rawFile = rawFile;
  }

  actCancel() {
  }

  actUpload() {
    const relativePath = 'upload-file';
    if (this.rawFile != null) {
      const dataBody = {
        file: this.rawFile,
        aRelativePath: `E${McGod.getLoggedEntityIdFromToken()}/${relativePath}`
      };
      this.eventUploaded.emit(dataBody);
    } else {
      this.toastr.error('Error');
    }
  }

}
