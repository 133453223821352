<span class="back-option" (click)="goBack()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.data-export.back" | translate }}
</span>
<form [formGroup]="form">
  <mat-progress-bar class="mt-medium" style="margin-bottom: -20px" mode="indeterminate" *ngIf="isLoadingExport"></mat-progress-bar>
  <div class="card mt-large">
    <h2 class="title-medium-dark mb-large">
      {{ "cc.data-export.export-report" | translate }}
    </h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{ "cc.data-export.export-report" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="idDataExportTemplate"
                    disableOptionCentering>
          <mat-option *ngFor="let report of exportReports"
                      (click)="selectExportReport(report.id)"
                      [value]="report.id">
            {{ report.reportName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "cc.psd2.date-from" | translate }}*</mat-label>
        <input matInput
               [matDatepicker]="dateFrom"
               formControlName='dateFrom'
               (click)='dateFrom.open()'
               readonly>
        <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
        <mat-datepicker #dateFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "cc.psd2.date-to" | translate }}</mat-label>
        <input matInput
               [matDatepicker]="dateTo"
               formControlName='dateTo'
               (click)='dateTo.open()'
               readonly>
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "cc.consumers.imports.file-type" | translate }}*</mat-label>
        <mat-select panelClass="dialog-select"
                    formControlName="fileType"
                    disableOptionCentering>
          <mat-option *ngFor="let type of fileTypes"
                      [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="preview mt-large" *ngIf="form.valid">
      <h2 class="title-medium-dark mb-small mt-medium">
        {{ "cc.data-export.export-report-preview" | translate }}
      </h2>
      <span class="body-text-small mb-medium">
        {{ "cc.data-export.preview-message" | translate }}
      </span>
      <ng-container *ngIf="previewData && previewData.length > 0">
        <table class="custom-table">
          <thead>
          <tr>
            <th *ngFor="let preview of previewData" class="">
              {{ preview }}
            </th>
          </tr>
          </thead>
        </table>
      </ng-container>
      <ng-container *ngIf="previewData && previewData.length === 0">
        <div class="text-center w-100 my-3">
          {{ "cc.no-data-to-display" | translate }}
        </div>
      </ng-container>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    </div>
    <div class="content-footer mt-large">
      <button mat-button
              class="primary-btn"
              (click)="export()"
              [disabled]="!form.valid || (previewData && previewData.length === 0)"
              type="submit">
        {{ "cc.common.export" | translate }}
      </button>
    </div>
  </div>
</form>
