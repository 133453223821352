import {Component, OnInit} from '@angular/core';
import {
  McCountryList,
  McCountry,
  McCountryTable3Config,
  McForm,
  McBoolean,
  McHtmlButton,
  McGod,
  McCountryFilter, McEntity2Service
} from '@miticon-ui/mc-core';
import {Router} from "@angular/router";

@Component({
  selector: 'mc-country-country-item-list',
  templateUrl: './mc-country-country-item-list.component.html',
})
export class McCountryCountryItemListComponent implements OnInit {

  mcCountryList!: McCountryList;
  mcCountryFilter = new McCountryFilter();
  mcCountry = new McCountry();
  mcCountryTable3Config = new  McCountryTable3Config();
  mcForm = new McForm();
  mcShowAddEditCountryMcb = new McBoolean();
  successMsg = '';
  mcGod = McGod.getInstance();
  countryEntities: any;

  // ---------------------------------------------------------------------
  constructor(private router: Router,
              private mcEntityService: McEntity2Service) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.mcCountryList = new McCountryList();
    this.mcCountryList.setPager(0, 100);
    this.mcCountryList.addSortB('name', 'ASC');
    this.actLoad();
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.mcEntityService.getNumberOfEntitiesByCountryIds().subscribe(res => {
      this.countryEntities = res;
    });
    this.mcCountryFilter.searchTerm = this.mcForm.getValue('txbSearchTerm');

    this.mcCountryList.loadByFilter(this.mcCountryFilter, () => {
      this.mcCountryList.items.forEach(country => {
        this.countryEntities.some((item: any) => item.countryId === country.id ? country.numberOfEntities = item.numberOfEntities : country.numberOfEntities = 0);
      });
    });
  }

  actClearFilter() {
    this.mcForm.getControl('txbSearchTerm')?.get('value')?.setValue('');
    this.actLoad();
  }


  onAddEditCountryCancelEmit() {
    this.mcShowAddEditCountryMcb.setFalse();
    this.actLoad();
  }

  actShowAddEditCountryMcb() {
    this.mcCountry = new McCountry();
    this.mcShowAddEditCountryMcb.setTrue();
  }

  onAddEditCountrySaveEmit(country: string) {
    this.mcShowAddEditCountryMcb.setFalse();
    this.successMsg = `${this.mcGod.t('cc.users.edit.country')} "${country}" ${this.mcGod.t('cc.common.saved-successfully')}.`;
    this.actLoad();
  }

  onCountryTableItemAction(mcHtmlButton: McHtmlButton) {
    if (mcHtmlButton.actionCdForEvent === McCountry.ACTION_EDIT_COUNTRY) {
      this.mcCountry = mcHtmlButton.item;
      this.mcShowAddEditCountryMcb.setTrue();
    } else if (mcHtmlButton.actionCdForEvent === McCountry.ACTION_VIEW_DETAILS) {
      this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/countries/profile/${mcHtmlButton.item.id}`);
    }
  }
}
