import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortCriteriaList } from '../_core/sort-criteria-list.model';
import { McUserServiceGENERATED } from '../_generated/mc-user-generated.service';

@Injectable({
  providedIn: 'root',
})
export class McUserService extends McUserServiceGENERATED {
  sendInvitationMail(userId: number, entityId: number, email: string): Observable<any> {
    const body = email !== '' ? { "differentEmail": email } : {};
    // @ts-ignore
    return this.httpClient.post(`${this.apiUrl}/core/user/${userId}/entity/${entityId}/send-invitation-mail`, body, { headers: this.httpOptions });
  }

  /*getAllThatImportSepaStatementReportFile(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/mcuser/v3/mcuser/getallthatimportedsepastatementreportfile`);
  }*/

  public getAllThatCreatedMappingOnEntity(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/mcuser/v3/mcuser/getallthatcreatedmappingonentity`);
  }

  public getAllThatImportedConsumers(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/mcuser/v3/mcuser/getallthatimportedconsumers`);
  }
}
